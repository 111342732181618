import React from "react"
import styled from "styled-components"
import CrossIcon from "../../assets/icons/tarif-cross.svg"
import {useTranslation} from "react-i18next"

const RedCircle = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: red;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  color: white;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  
    svg {
      width: 100%;
      height: 100%
  }
`
const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const ErrorIcon = ({props}) => {
    const {t} = useTranslation()
    return <Outer>
        <RedCircle>
            <CrossIcon/>
        </RedCircle>
        <div style={{color: "red"}}>
            {t("error.occurred")}
        </div>
    </Outer>
}
