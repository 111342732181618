import React from "react"
import styled, {createGlobalStyle} from "styled-components"
import display from "./display.svg?url"

const Global = createGlobalStyle`
  body {
    background-color: rgb(238, 238, 238) !important;
  }

  .bgimage {
    background: url(${display});
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: bottom;
    background-position-x: 80%;
  }
`


const Headline = styled.h1`
  font-weight: bold;
  font-size: 4rem;
`

const BigButton = styled.button`
  font-size: 1.5rem;
  margin-top: 2rem;
  width: 250px;
  height: 52px;
  background: #7EC14B;
  border-radius: 3px;
  color: white;
  border: none;
  box-shadow: 0px 3px 6px #7EC14B29;
`

export function PageNotFound() {
    return <div className="container-fluid bgimage" style={{minHeight: "100vh"}}>
        <Global/>
        <div className="row justify-content-between align-items-center">
            <div className="col-lg-8">
                <div>
                    <header>
                        <Headline>Sorry! The thing you're looking for isn't here.</Headline>
                    </header>
                    <main style={{fontSize: "2rem"}}>
                        While you are here: Have you heard of Vulnerability Management as a Service? Your all-round,
                        worry-free
                        package of protection against cyber threats.
                    </main>


                    <a href="https://www.greenbone.net/">
                        <BigButton className="button">Learn more</BigButton>
                    </a>
                </div>
            </div>
        </div>
    </div>
}
