export class PasswordUrlProvider {

    static init() {
        return '/init';
    }

    static initSuccess() {
        return '/init/success';
    }

    static reset() {
        return '/reset';
    }

    static resetSuccess() {
        return '/reset/success';
    }


}
