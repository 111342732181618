import * as HttpStatuscode from "http-status-codes"
import {ExternHostValidationRemoteUrlProvider} from "./ExternHostValidationRemoteUrlProvider"
import {
    FetchClient,
    InvalidStatusCodeError,
    NotFoundError,
    RestApiClient,
    ServiceNotAvailableError,
    Snackbar,
    UnprocessableEntityError
} from "@greenbone/cloud-component-library"
import {BASEURL} from "../../../Constants"


function HandleError(e) {
    Snackbar.Error(e.toString())
}

export class ExternHostValidationRestApiClient {

    constructor() {
        this.httpJsonClient = new RestApiClient(new FetchClient(null, BASEURL).getUnauthenticatedInstance(), HandleError)
        this.httpJsonClient.setHandleErrors(false);
        this.urlProvider = new ExternHostValidationRemoteUrlProvider();
    }

    handleError(status) {

        if (status === HttpStatuscode.UNPROCESSABLE_ENTITY) {
            throw new UnprocessableEntityError();
        } else if (status === HttpStatuscode.SERVICE_UNAVAILABLE) {
            throw new ServiceNotAvailableError();
        } else if (status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError();
        }

        throw new InvalidStatusCodeError(status);
    }


    async approveToken(token) {
        const url = this.urlProvider.tokenApprove(token);
        const response = await this.httpJsonClient.put(url);

        if (response) {
            if (response.status === HttpStatuscode.NO_CONTENT || response.status === HttpStatuscode.OK) {
                return;
            }

            this.handleError(response.status);
        }
        // this.handleError(); // TODO: pnaumann 11.02.2020 @jwerremeyer please check this line
    }

    async rejectToken(token) {
        const response = await this.httpJsonClient.put(this.urlProvider.tokenReject(token));
        if (response) {
            if (response.status === HttpStatuscode.NO_CONTENT || response.status === HttpStatuscode.OK) {
                return;
            }
        }
        // this.handleError(response.status); // TODO: pnaumann 11.02.2020 @jwerremeyer please check this line
    }
}


