export const SLIDES = {
    SIGN_UP: 0,
    BILLING: 1,
    LEGAL: 2,
    SUCCESS: 3,
}

export const SLIDE_FORM_MAP = [
    {
        slideIndex: SLIDES.SIGN_UP,
        fields: ["email", "password"]
    },
    {
        slideIndex: SLIDES.BILLING,
        fields: [
            "companyName",
            "title",
            "firstName",
            "lastName",
            "streetAddress",
            "houseNumber",
            "postalCode",
            "city",
            "countryId"
        ]
    },
    {
        slideIndex: SLIDES.LEGAL,
        fields: [
            "greenboneTermsOfUseAccepted",
            "vmspTermsOfUseAccepted",
            "greenbonePrivacyPolicyAccepted",
            "vmspPrivacyPolicyAccepted"
        ]
    }
]