import * as HttpStatuscode from "http-status-codes"


export class PaymentRequiredError extends Error {
    type = PaymentRequiredError
    status = HttpStatuscode.PAYMENT_REQUIRED

    constructor() {
        super(`Received status from server '402'`);
    }
}
