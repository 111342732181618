import React from "react"
import {useTranslation} from "react-i18next"
import {SuccessPaperWithMessageAndButton} from "../../Components/SuccessPaperWithMessageAndButton"
import {Col, Row} from "reactstrap"
import {FlexColumn} from "@greenbone/cloud-component-library"
import {ThumbUpAnimationTemplate} from "../../Components/Illustrations/ThumbsUpAnimationTemplate"

export function CreateMSPSuccess() {
    const {t} = useTranslation()
    const translatedHeadline = t("jointeam.success.headline")
    const translatedSuccessText = t("createMSP.success.text")
    const translatedRedirectButton = t("registerSuccess.toLogin")

    return (
        <>
            <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                <Col xl={6} xs={12} md={8} lg={8}>
                    <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                        <SuccessPaperWithMessageAndButton headline={translatedHeadline}
                                                          text={translatedSuccessText}
                                                          redirect={"/ui/mssp/admin/"}
                                                          redirectButton={translatedRedirectButton}
                        />
                    </FlexColumn>
                </Col>
                <ThumbUpAnimationTemplate/>
            </Row>
        </>
    )
}

