import { FlexColumn, Headline, Paragraph } from '@greenbone/cloud-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Success = (_props) => {
    const { t } = useTranslation()

    return (
        <FlexColumn fullWidth={'100%'}>
            <Headline>{t("registerSuccess.headline")}</Headline>
            <Paragraph>{t("extern.registration.success.eMailComesSoon")}</Paragraph>
        </FlexColumn>
    )
}