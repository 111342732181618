import React from "react"
import {Parser} from "./Parser"
import {
    Button,
    FlexColumn,
    FlexRow,
    ForbiddenError,
    GhostButton,
    Input,
    Snackbar,
    Spinner
} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {isPasswordStrongEnough, PasswordInput} from "./PasswordInput"
import HttpStatuscode from "http-status-codes"
import {ExternUserRestApiClient} from "../register/service/ExternUserRestApiClient"
import {withTranslation} from "react-i18next"
import {ErrorIcon} from "../../Components/ErrorIcon"

import {PasswordEnterAnimationTemplate} from "../../Components/Illustrations/PasswordEnterAnimationTemplate"
import KeyIcon from "../../../assets/icons/key.svg"
import RepeatIcon from "../../../assets/icons/repeat.svg"
import {Adornment} from "../../Components/Adornment"
import {FormPaper} from "../../Components/FormPaper"
import {withNavigate} from "../../../hocs/withNavigate";

export const PasswordReset = withNavigate(withTranslation()(
    class extends React.Component {
        httpRestApiClient

        state = {
            token: null,
            password: "",
            secondPassword: "",
            passwordScore: 0,
            _sending: false
        }

        constructor(props) {
            super(props)
            this.httpRestApiClient = new ExternUserRestApiClient()
        }


        componentDidMount() {
            const token = Parser.parseURLParameter("token")
            if (token) {
                this.setState({token})
            }
        }

        handleChange = event => {
            this.setState({
                [event.target.name]: event.target.value
            })
        }

        handlePasswordChange = (event, score) => {
            this.setState({
                [event.target.name]: event.target.value,
                passwordScore: score
            })
        }

        validate = () => {
            const {t} = this.props
            if (!this.state.token) {
                return false
            }

            if ((!!this.state.password) === false) {
                Snackbar.Error(t("common.messages.error.noPasswordEntered"))
                return false
            }

            if (!isPasswordStrongEnough(this.state.password)) {
                Snackbar.Error(t("common.messages.error.weakPassword"))
                return false
            }

            if (this.state.password !== this.state.secondPassword) {
                Snackbar.Error(t("common.messages.error.not_matchingPasswords"))
                return false
            }

            return true
        }

        handleFormSubmit = event => {
            event.preventDefault()
            const {t} = this.props


            if (this.validate() === false) {
                return
            }

            this.setState({_sending: true})

            this.httpRestApiClient.resetPassword(this.state.token, this.state.password)
                .then(() => {
                    this.props.navigate("success")
                })
                .catch(error => {
                    if (error.type === ForbiddenError) {
                        Snackbar.Error(error.message)
                    } else if (error.status === HttpStatuscode.NOT_FOUND) {
                        Snackbar.Error(t("mailValidate.error.notFound"))
                    } else {
                        Snackbar.Error(t("common.messages.error.canNotReachService"))
                    }

                })
                .finally(() => {
                    this.setState({_sending: false})
                })
        }

        render() {

            const {t} = this.props

            if (!this.state.token) {
                return (
                    <>
                        <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                            <Col xl={6} xs={12} md={8} lg={8}>
                                <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                                    <FormPaper
                                        title={t("error")}
                                        text={t("PasswordReset.errorText")}
                                        actions={<Row>
                                            <Col>
                                                <a href="/ui">
                                                    <GhostButton noMargin>
                                                        {t("back.to.login")}
                                                    </GhostButton>
                                                </a>
                                            </Col>
                                        </Row>}
                                    >
                                        <ErrorIcon/>

                                    </FormPaper>
                                </FlexColumn>
                            </Col>
                            <Col>
                                <PasswordEnterAnimationTemplate/>
                            </Col>
                        </Row>
                    </>
                )
            }

            return (
                <>
                    <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                        <Col xl={6} xs={12} md={8} lg={8}>
                            <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                                <form onSubmit={this.handleFormSubmit}
                                      style={{display: "flex", justifyContent: "flex-end", "width": "100%"}}>
                                    <FormPaper
                                        title={t("PasswordReset.headline")}
                                        text={t("PasswordReset.text")}
                                        actions={<Row>
                                            <Col>
                                                <a href="/ui">
                                                    <GhostButton>
                                                        {t("back.to.login")}
                                                    </GhostButton>
                                                </a>
                                            </Col>
                                            <Col>
                                                <FlexRow justifyContent={"flex-end"}>
                                                    <Button
                                                        disabled={this.state._sending}>
                                                        {t("PasswordReset.resetPassword")}
                                                        {this.state._sending && <Spinner/>}
                                                    </Button>
                                                </FlexRow>
                                            </Col>
                                        </Row>}
                                    >


                                        <Row>
                                            <Col>
                                                <PasswordInput
                                                    adornment={<Adornment>
                                                        <KeyIcon/>
                                                    </Adornment>}
                                                    style={{height: "8rem"}}
                                                    fullWidth
                                                    margin={"normal"}
                                                    type={"password"}
                                                    name={"password"}
                                                    onChange={this.handlePasswordChange}
                                                    label={"New password"}
                                                    value={this.state.password}

                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Input
                                                    adornment={<Adornment>
                                                        <RepeatIcon/>
                                                    </Adornment>}
                                                    type={"password"}
                                                    name={"secondPassword"}
                                                    onChange={this.handleChange}
                                                    label={"Repeat new password"}
                                                    value={this.state.secondPassword}
                                                    margin={"normal"}
                                                />
                                            </Col>
                                        </Row>


                                    </FormPaper>
                                </form>

                            </FlexColumn>
                        </Col>
                        <Col>
                            <PasswordEnterAnimationTemplate/>
                        </Col>
                    </Row>
                </>
            )
        }
    }
))
