import React from 'react'
import { FlexColumn } from "@greenbone/cloud-component-library"
import { useTranslation } from "react-i18next"
import { InfoList } from "./StyledComponents"

export const FreeInfo = () => {
    const { t } = useTranslation()
    return  <FlexColumn>
            <h1>
                {t("extern.registration.RegisterContainer.infoText.header")}
            </h1>
            <InfoList>
                <li>{t("extern.registration.RegisterContainer.infoText.info1")}</li>
                <li>{t("extern.registration.RegisterContainer.infoText.info2")}</li>
                <li>{t("extern.registration.RegisterContainer.infoText.info3")}</li>
                <li>{t("extern.registration.RegisterContainer.infoText.info4")}</li>
            </InfoList>
        </FlexColumn>
    
}
