class InvalidValue extends Error {
}

export class Parser {
    static parseInt(value, defaultValue = undefined) {
        const result = parseInt(value, 10);
        if (isNaN(result)) {
            if (defaultValue !== undefined) {
                return defaultValue;
            }
            throw new InvalidValue("Not a number");
        }
        return result;
    }

    /**
     * Extracts the url-parameter with the given name
     *
     * @param {string} name - url-parameter-name to be extracted
     * @returns {string | null} - extracted parameter-value
     */
    static parseURLParameter(name) {
        return decodeURIComponent(
            (new RegExp("[?|&]" + name + "=([^&;]+?)(&|#|;|$)").exec(window.location.search) || [null, ""])[1].replace(
                /\+/g, "%20")) || null;
    }

    static parseBase64(file) {
        let reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);

            reader.onload = () => {
                let result = reader.result;
                const base64Prefix = new RegExp("data:.*;base64,");
                // $FlowFixMe
                resolve(result.replace(base64Prefix, ""));
            };
            reader.onerror = function (error) {
                reject("Could not parse input file: " + file + " because of: " + error.toString());
            };
        });
    }

    static parseUrlWithoutTrailingSlash(url) {
        if (url.endsWith("/")) {
            return url.substring(0, url.length - 1);
        }
        return url;
    }
}
