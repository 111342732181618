import React from "react"
import {createGlobalStyle, useTheme} from "styled-components"
import {ShadeBlendColor} from "@greenbone/cloud-component-library"
import {Col} from "reactstrap"


const Style = createGlobalStyle`
.hover-rectangle-checkhover-rectangle-check {
    animation-duration: 2s;
    animation-name: hover-rectangle-check-keyframes;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes hover-rectangle-check-keyframes {
  0% {
    transform: translate(52px, -28.471px);
  }

  50% {
        transform: translate(52px, -8.471px);
  }

  100% {
        transform: translate(52px, -28.471px);
  }
}

`

function SvgTemplate(color) {


    // const color2 = "#02447b"
    // const color3 = "#025b93"
    // const color1 = "#0476ac"
    // const color4 = "#36a6cd"
    // const color5 = "#5fcce6"
    // const color6 = "#036aa1"

    const color2 = ShadeBlendColor(color, 1)
    const color3 = ShadeBlendColor(color, 2)
    const color1 = ShadeBlendColor(color, 3)
    const color4 = ShadeBlendColor(color, 4)
    const color5 = ShadeBlendColor(color, 5)
    const color6 = ShadeBlendColor(color, 6)

    const {animation} = useTheme()

    let background = "#FFFFFF1A"
    if (animation) {
        background = `${animation.background}1A`
    }

    return {__html: BaseSvg(color1, color2, color3, color4, color5, color6, background)}
}

const BaseSvg = (color1, color2, color3, color4, color5, color6, background) => `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 855.636 735.423">
  <defs>
    <linearGradient id="linear-gradient" x1="1" y1="0.5" x2="-0.324" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="${color1}"/>
      <stop offset="1" stop-color="${color2}"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.5" y1="0.131" x2="0.5" y2="1.412" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fff" stop-opacity="0"/>
      <stop offset="1" stop-color="#fff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" y1="0.023" x2="0.638" y2="0.662" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="${color5}"/>
      <stop offset="1" stop-color="${color4}"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="-0.14" y1="-0.116" x2="0.906" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fff"/>
      <stop offset="1" stop-color="${color1}" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="linear-gradient-5" x1="0.243" y1="-0.072" x2="0.94" y2="1.244" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fff"/>
      <stop offset="1" stop-color="#fff" stop-opacity="0"/>
    </linearGradient>
  </defs>
  <g id="Group_253" data-name="Group 253" transform="translate(-1007.195 -242.719)">
    <g id="Group_234" data-name="Group 234">
      <g id="Group_55" data-name="Group 55" transform="translate(1173.299 481.703)">
        <path id="Path_1401" data-name="Path 1401" d="M1208.642-562.012,941.394-405.84,671.652-560.708,938.9-716.88Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_58" data-name="Group 58" transform="translate(1453.563 735.152)">
        <path id="Path_1401-2" data-name="Path 1401" d="M928.378-642.84,800.611-568.177l-128.959-74.04L799.419-716.88Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_108" data-name="Group 108" transform="translate(1060.612 648.718)">
        <path id="Path_1401-3" data-name="Path 1401" d="M855.087-663.977,763.8-610.63l-92.143-52.9,91.291-53.348Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_110" data-name="Group 110" transform="translate(1144.443 477.75)">
        <path id="Path_1401-4" data-name="Path 1401" d="M790.078-682.726,731.14-648.285l-59.488-34.154L730.59-716.88Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_111" data-name="Group 111" transform="translate(1037.975 924.916)">
        <path id="Path_1401-5" data-name="Path 1401" d="M763.542-690.379l-45.732,26.724-46.158-26.5,45.732-26.724Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_113" data-name="Group 113" transform="translate(1770.941 788.312)">
        <path id="Path_1401-6" data-name="Path 1401" d="M763.542-690.379l-45.732,26.724-46.158-26.5,45.732-26.724Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_121" data-name="Group 121" transform="translate(1143.944 375)">
        <path id="Path_1401-7" data-name="Path 1401" d="M763.542-690.379l-45.732,26.724-46.158-26.5,45.732-26.724Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_112" data-name="Group 112" transform="translate(1007.195 819.385)">
        <path id="Path_1401-8" data-name="Path 1401" d="M816.812-675.016,744.569-632.8l-72.917-41.864L743.9-716.88Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_109" data-name="Group 109" transform="translate(1633.251 520.696)">
        <path id="Path_1401-9" data-name="Path 1401" d="M855.087-663.977,763.8-610.63l-92.143-52.9,91.291-53.348Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_59" data-name="Group 59" transform="translate(1095.512 712.301)">
        <path id="Path_1401-10" data-name="Path 1401" d="M1006.165-620.406l-166.48,97.286L671.652-619.595l166.48-97.286Z" transform="translate(-671.652 716.88)" fill="${background}"/>
      </g>
      <g id="Group_57" data-name="Group 57" transform="translate(1271.777 243.297)">
        <g id="Group_56" data-name="Group 56" transform="translate(0 278.016)">
          <g id="Group_53" data-name="Group 53" transform="translate(169.602 97.918)">
            <path id="Path_1399" data-name="Path 1399" d="M1040.188-601.846l-.1,35.017L871.587-468.9l.1-34.555Z" transform="translate(-871.587 601.383)" fill="${color3}" stroke="rgba(0,0,0,0)" stroke-width="1"/>
          </g>
          <g id="Group_54" data-name="Group 54" transform="translate(0 97.923)">
            <path id="Path_1400" data-name="Path 1400" d="M841.236-503.457l-.1,34.555-169.6-97.92.1-34.555Z" transform="translate(-671.536 601.377)" fill="${color2}" stroke="rgba(0,0,0,0)" stroke-width="1"/>
          </g>
          <g id="Group_55-2" data-name="Group 55" transform="translate(0.098 0)">
            <path id="Path_1401-11" data-name="Path 1401" d="M1009.759-619.37,841.491-521.039l-169.838-97.51L839.921-716.88Z" transform="translate(-671.652 716.88)" stroke="rgba(0,0,0,0)" stroke-width="1" fill="url(#linear-gradient)"/>
          </g>
        </g>
        <g id="Group_48" data-name="Group 48" transform="translate(56.315 0)" opacity="0.72">
          <g id="Group_46" data-name="Group 46">
            <path id="Path_1398" data-name="Path 1398" d="M1091.3-1966.8l-.065,315.574-108.746,63.2-109.457-63.2.06-315.574L981.838-2030Z" transform="translate(-873.026 2030)" stroke="rgba(0,0,0,0)" stroke-width="1" fill="url(#linear-gradient-2)"/>
          </g>
        </g>
      </g>
    </g>
    <g id="Group_243" class="hover-rectangle-check" data-name="Group 243" transform="translate(52 -28.117)">
      <g id="Group_134" data-name="Group 134" transform="translate(1311.503 299.297)">
        <g id="Group_133" data-name="Group 133" transform="translate(0 0)">
          <path id="Path_1474" data-name="Path 1474" d="M-1386.061,334.791l16.059-9.333-.421,167-16.059,9.333Z" transform="translate(1515.369 -250.802)" fill="${color1}"/>
          <path id="Path_1475" data-name="Path 1475" d="M-1499.612,254.261l16.059-9.333,129.309,74.656-16.059,9.333Z" transform="translate(1499.612 -244.929)" fill="${color2}"/>
          <path id="Path_1476" data-name="Path 1476" d="M-1370.17,342.777V509.312l-129.309-74.654V268.121Z" transform="translate(1499.479 -258.788)" fill="url(#linear-gradient-3)"/>
          <path id="Path_1477" data-name="Path 1477" d="M-1399.6,330.888l-.326,123.342-100.056-57.767.326-123.342Z" transform="translate(1514.235 -234.352)" fill="url(#linear-gradient-4)"/>
        </g>
      </g>
      <path id="Path_1478" data-name="Path 1478" d="M-1028.511,526.808a20.478,20.478,0,0,1,6.407,6.2c4.569,6.664,5.482,14.7,2.055,18.064l-41.17,39.208c-2.087,1.982-5.268,1.826-8.483-.03a20.411,20.411,0,0,1-6.332-6.093c-.4-.571-.776-1.168-1.125-1.777l-20.865-35.636c-4.011-7.185-3.825-14.78.414-16.965,2.049-1.054,4.677-.656,7.3.859a21.327,21.327,0,0,1,7.277,7.552l12.271,20.755,33.825-32.217C-1034.846,524.8-1031.695,524.97-1028.511,526.808Z" transform="translate(2433.759 -130.338)" fill="url(#linear-gradient-5)"/>
    </g>
  </g>
</svg>
`


export function CheckmarkAnimationTemplate(props) {

    const {base} = useTheme()


    return <><Style/><Col dangerouslySetInnerHTML={SvgTemplate(base)}>

    </Col></>
}
