

export const Colors = {
    white: {
        normal: "#f1ece1"
    },
    black: {
        normal: "#0e0e10"
    },
    grey: {
        _15: "#f8f8f8",
        lighter: "#e6e6e6",
        light: "#c2c2c2",
        lightNormal: "#a1a1a1",
        normal: "#7F7F7F",
        dark: "#4C4C4C",
        darker: "#191919"
    },
    blue: {
        light: "#86B2DE",
        normal: "#4588CD",
        _150: "#236FB5",
        dark: "#265C96",
        _250: "#1E4579",
        darker: "#1B3059"
    },
    green: {
        light: "#66C430",
        normal: "#42801F",
        dark: "#0A5405",
        darker: "#21400F"
    },
    yellow: {
        light: "#FFE478",
        normal: "#FFD630",
        dark: "#FFBF05"
    },
    orange: {
        light: "#FFB56C",
        normal: "#FF8E1D",
        dark: "#FF710B"
    },
    red: {
        light: "#FF441F",
        normal: "#BF3317",
        dark: "#80220F",
        darker: "#401108"
    }
};


export const ColorTheme = {
    alerts: {
        success: {background: Colors.green.normal, foreground: Colors.white.normal},
        error: {background: Colors.red.dark, foreground: Colors.white.normal},
        warning: {background: Colors.yellow.normal, foreground: Colors.black.normal},
        info: {background: Colors.blue.dark, foreground: Colors.white.normal}
    },
    input: {
        borderBottomColor: Colors.green.dark

    },
    background: {
        light: Colors.green.light,
        normal: Colors.green.normal,
        dark: Colors.green.dark,
        darker: Colors.green.darker
    },
    navbar: {
        background: Colors.green.light,
        font: Colors.black.normal
    },
    sidebar: {
        hoverBackground: "#d5d5d5",
        hoverFont: Colors.black.normal,
        background: "#fafafa",
        activeMenu: "#f0f0f0",
        activeMenuitem: "#d5d5d5",
        staticItem: "#f0f0f0",
        staticItemFont: Colors.black.normal,
        font: Colors.black.normal,
        border: "#e5e5e5",
        insetShadow: "#707070"
    },
    font: {
        headline: Colors.green.normal,
        subheadline: Colors.green.normal,
        caption: Colors.green.normal,
        textlink: Colors.green.darker,
        tiny: Colors.grey.light
    },
    button: {
        normal: {
            text: Colors.black.normal,
            background: Colors.green.light,
            hover: Colors.green.normal,
            disabled: Colors.grey.light
        },
        abort: {
            text: Colors.black.normal,
            background: Colors.grey.light,
            hover: Colors.grey.normal,
            disabled: Colors.grey.light
        },
        icon: {
            background: "transparent",
            hover: Colors.grey.lighter,
            active: Colors.grey.light
        }
    },
    severity: {
        info: Colors.blue.normal,
        warning: Colors.orange.normal,
        high: Colors.red.normal,
        critical: Colors.red.dark
    },
    error: {
        color: "#F65656",
        background: Colors.white.normal
    },
    bordered: false
};


export const Theme = {

    default: {
        color: Colors.grey.dark,
        font: "DroidSans, sans-serif",
        background: "#fafafa"
    },
    info: {
        backgroundColor: Colors.blue.light,
        foregroundColor: "#FFF"
    },
    success: {
        backgroundColor: Colors.green.normal,
        foregroundColor: "#FFF"
    },
    warning: {
        backgroundColor: Colors.orange.normal,
        foregroundColor: "#FFF"
    },
    error: {
        backgroundColor: Colors.red.normal,
        foregroundColor: "#FFF"
    },
    action: {
        backgroundColor: ColorTheme.background.dark,
        foregroundColor: "#FFF"
    },
    severity: {
        info: ColorTheme.severity.info,
        warning: ColorTheme.severity.warning,
        high: ColorTheme.severity.high,
        critical: ColorTheme.severity.critical
    }
};
