import { FlexColumn } from "@greenbone/cloud-component-library"
import { Row, Input as RSInput } from "reactstrap"
import styled, { keyframes } from "styled-components"

const RegisterStyleContainerBase = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100vh;
`

export const InfoContainer = styled(RegisterStyleContainerBase)`
    background-color: #4b4b4b;
    color: white;
    min-width: 300px;
    flex: 2;
`

const fadeInRight = (inverted) => keyframes`
0% {
        opacity: 0;
        transform: translateX(${inverted === undefined ? "0px" : (inverted ? "-10px" : "10px")});
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`

export const FadeInWrapper = styled.div`
    animation: ${({invertedTransition}) => fadeInRight(invertedTransition)} 0.4s ease-out;
`

export const ContentContainer = styled(RegisterStyleContainerBase)`
    overflow-y: auto;
    background-color: white;
    position: relative;
    justify-content: space-between;
    flex: 3;
    >div>div>div>div {
        margin-bottom: 15px;
    };
    >*{
        max-width: 2000px
    }
`

export const RegisterPageContainer = styled(Row)`
display: flex;
margin: 0;
position: relative;
height: 100%;
>.col {
    padding: 0;
};
`

export const InfoList = styled.ul`
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    >li {
        font-size: 14pt;
        padding-left: 0;
        ::before {
            content: "✔";
            padding-right: 0.3rem;
        }
    }
`

export const StyledCheckbox = styled(RSInput)`
    border: 1px solid blue;
`

export const FlexColumnFlexible = styled(FlexColumn)`
    width: ${({width}) => width};
`

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 500px;
    >a {
        padding: 10px;
        color: #4F4F4F;
        cursor: pointer;
    }
`

export const GreenboneLogo = styled.img`
    margin-top: 10px;
    height: 80px;
`
export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`
