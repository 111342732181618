import React from "react"
import {Button, CustomError, FlexColumn, FlexRow, GhostButton, Input} from "@greenbone/cloud-component-library"
import {ExternUserRestApiClient} from "../register/service/ExternUserRestApiClient"
import * as HttpStatuscode from "http-status-codes"
import {Validator} from "../../Validator"
import {ButtonSpinner} from "../../Components/ButtonSpinner"
import {compose} from "redux"
import {withTranslation} from "react-i18next"
import EmailIcon from "../../../assets/icons/email.svg"
import {Adornment} from "../../Components/Adornment"
import {Col, Row} from "reactstrap"
import {FormPaper} from "../../Components/FormPaper"
import {PasswordResetInitAnimationTemplate} from "../../Components/Illustrations/PasswordResetInitAnimationTemplate"
import {withNavigate} from "../../../hocs/withNavigate";


export const PasswordResetInitPage = compose(
    withTranslation(),
    withNavigate
)(
    class _PasswordResetInitPage extends React.Component {
        httpRestApiClient
        state = {
            email: "",
            isValid: null,
            _sending: false,
            error: null
        }

        constructor(props) {
            super(props)
            this.httpRestApiClient = new ExternUserRestApiClient()
        }

        handleChange = event => {
            this.setState({
                [event.target.name]: event.target.value, valid: null
            })
        }

        handleFormSubmit = event => {
            const {t} = this.props
            event.preventDefault()

            if (!Validator.isEMail(this.state.email)) {
                this.setState({isValid: false})
                return
            }

            this.setState({_sending: true})
            this.httpRestApiClient.requestPasswordReset(this.state.email)
                .then(response => {
                    this.props.navigate('success')
                })
                .catch(exception => {
                    if (exception.status === HttpStatuscode.NOT_FOUND) {
                        this.setState({isValid: false})
                        this.setState({error: new CustomError(t("extern.user.resetPassword.error.eMailNotFound"))})
                    } else {
                        this.setState({error: exception})
                    }

                })
                .finally(() => {
                    this.setState({_sending: false})
                })
        }

        render() {

            if (this.state.error) {
                throw this.state.error
            }
            const {t} = this.props


            return <>
                <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                    <Col xl={6} xs={12} md={8} lg={8}>
                        <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                            <FormPaper
                                title={t("passwordResetInitPage.headline")}
                                text={t("passwordResetInitPage.text")}
                                actions={<Row>
                                    <Col>
                                        <a href="/ui">
                                            <GhostButton>
                                                {t("back.to.login")}
                                            </GhostButton>
                                        </a>
                                    </Col>
                                    <Col>
                                        <FlexRow justifyContent={"flex-end"}>
                                            <Button onClick={this.handleFormSubmit}>
                                                {t("passwordResetInitPage.request")}
                                                <ButtonSpinner hidden={!this.state._sending}/>
                                            </Button>
                                        </FlexRow>

                                    </Col>
                                </Row>}
                            >
                                <Input adornment={<Adornment><EmailIcon/></Adornment>} label={"E-Mail"}
                                       name={"email"}
                                       margin={"normal"} type={"email"} onChange={this.handleChange}
                                       isValid={this.state.isValid}/>
                            </FormPaper>
                        </FlexColumn>
                    </Col>
                    <PasswordResetInitAnimationTemplate/>
                </Row>
            </>
        }
    }
)



