import React from "react"
import {AlertBox, AlertBoxType, Headline, Paragraph, Spinner, useApiRequest} from "@greenbone/cloud-component-library"
import {RegisterContainer} from "./RegisterContainer"
import {ExternUserRestApiClient} from "./service/ExternUserRestApiClient"
import {Col, Container, Row} from "reactstrap"
import {RegisterDisabled} from "./RegisterDisabled"

export function RegisterPage() {
    const apiClient = new ExternUserRestApiClient()
    const [data, , error] = useApiRequest(apiClient.registerStatus.bind(apiClient))
    const [countries, loading, countriesError] = useApiRequest(apiClient.getCountryCodes.bind(apiClient))

    if ((!data && !error) || loading) {
        return <Container>
            <Row>
                <Col>
                    <Spinner/>
                </Col>
            </Row>
        </Container>
    }

    if (!data && (error || countriesError)) {
        return <Container>
            <Row>
                <Col>
                    <AlertBox type={AlertBoxType.Error}>
                        <Headline>Error</Headline>
                        <Paragraph>
                            {(error || countriesError)?.toString()}
                        </Paragraph>
                    </AlertBox>
                </Col>
            </Row>
        </Container>
    }

    if (data.isUserRegistrationEnabled) {
        return <RegisterContainer countries={countries}/>
    } else {
        return <>
            <RegisterDisabled/>
        </>
    }
}
