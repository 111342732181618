import {CircularProgress} from "@material-ui/core";
import React from "react";


class _ButtonSpinner extends React.Component {

    render() {

        if (this.props.hidden === true) {
            return null;
        }

        return (
            <CircularProgress style={{
                width: "0.8em",
                height: "0.8em",
                marginRight: "0.5em",
                marginLeft: "0.5em",
                color: "white"
            }}/>
        );
    }
}

export const ButtonSpinner = _ButtonSpinner;
