import { FlexColumn, Text } from '@greenbone/cloud-component-library'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import {Trans, useTranslation} from 'react-i18next'
import { Checkbox } from '../../Checkbox'

const Legal = (props, ref) => {
    const [initiallyShowErrors, setInitiallyShowErrors] = useState(props.initiallyShowErrors)

    useImperativeHandle(ref, () => ({
        validate: () => {
            setInitiallyShowErrors(true)
            return props.validateTerms()
        },
    }))

    const { t } = useTranslation()

    const translationTermsOfUse = t("extern.registration.RegisterContainer.termsOfUseTerm")
    const translationPrivacyPolicyTerm = t('extern.registration.RegisterContainer.privacyPolicyTerm')

    return (
        <FlexColumn fullWidth={'100%'}>
            <h3>{translationTermsOfUse}</h3>
            <Checkbox
                isValid={!initiallyShowErrors &&  !props.legalValid.greenboneTermsOfUseAccepted}
                LabelComponent={
                    <Text style={{width: "fit-content"}}>
                        {<Trans
                            values={{linkText: translationTermsOfUse}}
                            defaults='Ich habe die <Link>{{linkText}}</Link> gelesen und erkläre mich einverstanden.'
                            i18nKey="extern.registration.RegisterContainer.agreement"
                            components={{Link: <a target="_blank" href="../tos">Nutzungsbestimmung</a>}}
                        />}
                    </Text>
                }
                name="greenboneTermsOfUseAccepted"
                onChange={props.onChange}
                checked={props.legal.greenboneTermsOfUseAccepted || false}
            />
            <Checkbox
                isValid={!initiallyShowErrors &&  !props.legalValid.vmspTermsOfUseAccepted}
                LabelComponent={
                    <Text>
                        {<Trans
                            values={{linkText: translationTermsOfUse}}
                            i18nKey="extern.registration.RegisterContainer.partnerAgreement"
                            defaults='Ich habe die <Link>{{linkText}}</Link> des Partners gelesen und erkläre mich einverstanden.'
                            components={{Link: <a target="_blank" href="../tos/vmsp">Nutzungsbestimmung</a>}}
                        />}
                    </Text>
                }
                name="vmspTermsOfUseAccepted"
                onChange={props.onChange}
                checked={props.legal.vmspTermsOfUseAccepted || false}

            />
            <h3 style={{marginTop: "20px"}}>{translationPrivacyPolicyTerm}</h3>
            <Checkbox
                isValid={!initiallyShowErrors &&  !props.legalValid.greenbonePrivacyPolicyAccepted}
                LabelComponent={
                    <Text>
                        {<Trans
                            values={{linkText: translationPrivacyPolicyTerm}}
                            i18nKey="extern.registration.RegisterContainer.agreement"
                            defaults='Ich habe die <Link>{{linkText}}</Link> gelesen und erkläre mich einverstanden.'
                            components={{Link: <a target="_blank" href="../privacy">Datenschutzbestimmung</a>}}
                        />}
                    </Text>
                }
                name="greenbonePrivacyPolicyAccepted"
                onChange={props.onChange}
                checked={props.legal.greenbonePrivacyPolicyAccepted || false}
            />
            <Checkbox
                isValid={!initiallyShowErrors &&  !props.legalValid.vmspPrivacyPolicyAccepted}
                LabelComponent={
                    <Text>
                        {<Trans
                            values={{linkText: translationPrivacyPolicyTerm}}
                            i18nKey="extern.registration.RegisterContainer.partnerAgreement"
                            defaults='Ich habe die <Link>{{linkText}}</Link> des Partners gelesen und erkläre mich einverstanden.'
                            components={{Link: <a target="_blank" href="../privacy/vmsp">Datenschutzbestimmung</a>}}
                        >
                        </Trans>}
                    </Text>
                }
                name="vmspPrivacyPolicyAccepted"
                onChange={props.onChange}
                checked={props.legal.vmspPrivacyPolicyAccepted || false}
            />
        </FlexColumn>
    )
}

export default forwardRef(Legal)
