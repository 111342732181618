import React from "react"
import {withTranslation} from "react-i18next"
import {ExternHostValidationRestApiClient} from "./service/ExternHostvalidationRestApiClient"
import {Snackbar} from "@greenbone/cloud-component-library"
import {ErrorBoundary} from "../../Components/ErrorBoundary"
import {Parser} from "../password/Parser"
import {ExternHostValidation} from "./components/ExternHostValidation"


class _ValidateHost extends React.Component {
    restApiClient

    state = {
        submitting: false,
        token: "",
        exception: null,
        posting: false,
        approved: false,
        rejected: false
    };

    constructor(props) {
        super(props);

        this.restApiClient = new ExternHostValidationRestApiClient();
    }

    componentDidMount() {
        const token = Parser.parseURLParameter("token");
        if (token) {
            this.setState({token});
        }
    }

    onReject = (event) => {
        const {t} = this.props;
        event.preventDefault();

        if (this.state.token) {
            this.setState({posting: true});

            this.restApiClient.rejectToken(this.state.token)
                .then(result => {
                    this.setState({rejected: true});
                })
                .catch(exception => {
                    if (exception?.status === 422 || exception?.status === 404) {
                        Snackbar.Error(t("extern.gsp.hostValidation.error.wrongToken"));
                    } else {
                        this.setState({exception});
                    }
                })
                .finally(() => {
                    this.setState({posting: false});
                });

        }
    };

    onApprove = (event) => {
        const {t} = this.props;
        event.preventDefault();

        if (this.state.token) {
            this.setState({posting: true});

            this.restApiClient.approveToken(this.state.token)
                .then(result => {
                    this.setState({approved: true});
                })
                .catch(exception => {
                    if (exception?.status === 422 || exception?.status === 404) {
                        Snackbar.Error(t("extern.gsp.hostValidation.error.wrongToken"));
                    } else {
                        this.setState({exception});
                    }
                })
                .finally(() => {
                    this.setState({posting: false});
                });
        }
    };

    render() {

        if (this.state.exception) {
            throw this.state.exception;
        }

        return (
            <ErrorBoundary>
                    <ExternHostValidation
                        onReject={this.onReject}
                        onApprove={this.onApprove}
                        token={this.state.token}
                        posting={this.state.posting}
                        approved={this.state.approved}
                        rejected={this.state.rejected}
                    />
            </ErrorBoundary>
        );
    }

}


export const ValidateHost = withTranslation()(_ValidateHost);

