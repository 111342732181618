import React from "react"
import {withTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import {compose} from "redux"
import {Button, FlexColumn, FlexRow, GhostButton, Paragraph, Subheadline} from "@greenbone/cloud-component-library"
import {Paper} from "../../../Components/Layout"
import {ErrorIcon} from "../../../Components/ErrorIcon"
import {CheckmarkAnimationTemplate} from "../../../Components/Illustrations/CheckmarkAnimationTemplate"
import {Logo} from "../../../Components/Logo"

export const ExternHostValidation = compose(
    withTranslation()
)(
    class extends React.Component {

        render() {
            const {t} = this.props

            if (this.props.error) {
                throw this.props.error
            }

            if (!this.props.token) {
                return (
                    <Row style={{height: "100%"}}>
                        <Col xl={6} xs={12} md={8} lg={6}>
                            <FlexColumn full justifyContent={"center"}>
                                <Paper>
                                    <Logo/>
                                    <Subheadline>{t("common.messages.error")}</Subheadline>
                                    <Paragraph>
                                        {t("extern.hostValidation.error.noToken")}
                                    </Paragraph>

                                    <ErrorIcon/>

                                    <Row>
                                        <Col>
                                            <a href="/">
                                                <GhostButton>
                                                    To Login
                                                </GhostButton>
                                            </a>
                                        </Col>
                                    </Row>
                                </Paper>
                            </FlexColumn>
                        </Col>

                        <CheckmarkAnimationTemplate/>

                    </Row>
                )
            }

            if (this.props.approved) {
                return (
                    <Row style={{height: "100%"}}>
                        <Col xl={6} xs={12} md={8} lg={6}>
                            <FlexColumn full justifyContent={"center"}>
                                <Paper>
                                    <Logo/>

                                    <Subheadline>
                                        {t("extern.gsp.hostValidation.success.validate")}
                                    </Subheadline>
                                    <Paragraph>
                                        {t("extern.gsp.hostValidation.success.validationText")}
                                    </Paragraph>
                                    <Paragraph>
                                        {t("extern.gsp.hostValidation.youCanGoNow")}
                                    </Paragraph>

                                    <Row>
                                        <Col>
                                            <a href="/">
                                                <GhostButton>
                                                    Back To Login
                                                </GhostButton>
                                            </a>
                                        </Col>
                                    </Row>
                                </Paper>
                            </FlexColumn>
                        </Col>
                        <CheckmarkAnimationTemplate/>

                    </Row>


                )
            }

            if (this.props.rejected) {
                return (

                    <Row style={{height: "100%"}}>
                        <Col xl={6} xs={12} md={8} lg={6}>
                            <FlexColumn full justifyContent={"center"}>
                                <Paper>
                                    <Logo/>
                                    <Subheadline>
                                        {t("extern.gsp.hostValidation.failed.denied")}
                                    </Subheadline>
                                    <Paragraph>
                                        {t("extern.hostValidation.failed.deniedText")}
                                    </Paragraph>
                                    <Paragraph>
                                        {t("extern.gsp.hostValidation.youCanGoNow")}
                                    </Paragraph>

                                    <Row>
                                        <Col>
                                            <a href="/">
                                                <GhostButton>
                                                    Back To Login
                                                </GhostButton>
                                            </a>
                                        </Col>
                                    </Row>
                                </Paper>
                            </FlexColumn>
                        </Col>
                        <CheckmarkAnimationTemplate/>
                    </Row>
                )
            }

            return (
                <>
                    <Row style={{height: "100%"}}>
                        <Col xl={6} xs={12} md={8} lg={6}>
                            <FlexColumn full justifyContent={"center"}>
                                <Paper>
                                    <Logo/>

                                    <Subheadline>
                                        {t("extern.gsp.hostValidation.validateIP")}
                                    </Subheadline>

                                    <Paragraph>
                                        {t("extern.gsp.hostValidation.validationMessage")}
                                    </Paragraph>

                                    <Paragraph>
                                        <a href={"/ui/"}>
                                            {t("extern.hostValidation.informationAboutGSP")}</a>
                                    </Paragraph>

                                    <Row>
                                        <Col>
                                            <GhostButton style={{marginRight: "1rem"}} onClick={this.props.onReject}
                                                         type={"submit"}
                                                         variant="contained"
                                                         color={"primary"}>
                                                {t("common.action.dine")}
                                            </GhostButton>
                                        </Col>
                                        <Col>
                                            <FlexRow justifyContent={"flex-end"}>
                                                <Button onClick={this.props.onApprove} variant="contained"
                                                        color={"primary"}>
                                                    {t("common.action.accept")}
                                                </Button>
                                            </FlexRow>
                                        </Col>
                                    </Row>
                                </Paper>
                            </FlexColumn>
                        </Col>
                        <CheckmarkAnimationTemplate/>
                    </Row>


                </>
            )
        }

    }
)
