export const REACT_APP_WEB_ROOT_PATH = process.env.REACT_APP_WEB_ROOT_PATH || "";

export const VERSION = "2024.9.7"

export const IS_LOCAL = process.env.REACT_APP_IS_LOCAL === "true";
export const IS_DEVELOP = process.env.REACT_APP_IS_DEVELOP === "true"
export const IS_API_MOCKED = process.env.REACT_APP_ENABLE_MSW === "true"

const baseUrl = window.location.protocol + "//" + window.location.host;
export let BASEURL = baseUrl;
if (IS_LOCAL) {
    // BASEURL = "https://frontend-dev.greenbone.io"
    //BASEURL = "https://gcs-dev-mgmt8.bdd.greenbone.io"
    //BASEURL = "https://gcs-dev-mgmt.greenbone.io"
    BASEURL = "https://frontend-dev.greenbone.io"
    // BASEURL = "https://gcs-dev-mgmt6.bdd.greenbone.io"
    // BASEURL = "https://gcs-dev-mgmt6.bdd.greenbone.io"
    //BASEURL = "https://gcs-dev-mgmt.greenbone.io"
    // BASEURL = "https://gcs-dev-mgmt.greenbone.io"
    // BASEURL = "https://gcs-dev.greenbone.io"
    // BASEURL = "https://stolksdorf-11.bdd.greenbone.io"
    //  BASEURL = "https://bdd1.greenbone.io"
    // BASEURL = "https://teams1.greenbone.io"
}

export const PLAN_BASIC_ID = process.env.REACT_APP_PLANS_BASIC || "";
export const PLAN_ADVANCED_ID = process.env.REACT_APP_PLANS_ADVANCED || "";
export const PLAN_PREMIUM_ID = process.env.REACT_APP_PLANS_PREMIUM || "";
export const PLAN_FREE_ID = process.env.REACT_APP_PLANS_FREE_ID || "";
export const GSP_DEFAULT_PLAN = process.env.REACT_APP_DEFAULT_PLAN || "";
export const LANGUAGE_FALLBACK = process.env.REACT_APP_LANGUAGE_FALLBACK || "";
export const VAT_RATE = process.env.REACT_APP_VAT_RATE || ""
export const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY
