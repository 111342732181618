export class MSPUrlProvider {

    static create() {
        return '/join'
    }

    static success() {
        return '/success'
    }
}
