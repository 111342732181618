import React from "react"
import {Switch} from "@greenbone/cloud-component-library"
import {Route, Routes} from "react-router-dom"
import {MailUrlProvider} from "./MailUrlProvider"
import {MailValidate} from "./MailValidate"
import {MailValidateSuccess} from "./MailValidateSuccess"
import {ErrorBoundary} from "../../Components/ErrorBoundary"
import {MailValidateRequest} from "./MailValidateRequest"
import {MailValidateRequestSuccess} from "./MailValidateRequestSuccess"

const NotFound = () => <div>Not Found</div>

export function MailLayout(props) {

    return <>
        <ErrorBoundary>
            <Routes>
                <Route path="/validate/success" Component={MailValidateSuccess}/>
                <Route path="/validate" Component={MailValidate}/>
                <Route path="/request_confirm/success" Component={MailValidateRequestSuccess}/>
                <Route path="/request_confirm" Component={MailValidateRequest}/>
                <Route path="*" Component={NotFound}/>
            </Routes>
        </ErrorBoundary>
    </>
}
