import {FetchClient, RestApiClient} from "@greenbone/cloud-component-library"
import {BASEURL} from "../../Constants"

function ErrorHandler(e) {
    throw e
}

export class MSPRestApiClient {
    constructor(
        apiClient = new RestApiClient(
            new FetchClient(null, BASEURL).getUnauthenticatedInstance(),
            ErrorHandler
        )) {
        this.apiClient = apiClient

    }

    details = async (token) => {
        return await this.apiClient.get(`/mssp-admin/msp/join/${token}/details`)
    }

    join = async (password, termsAccepted, termsOfUseAccepted, token, language) => {
        return await this.apiClient.post(`/mssp-admin/msp/join/${token}`, {
            password,
            termsAccepted,
            termsOfUseAccepted,
            language
        })
    }


}
