import React from "react"
import {Switch} from "@greenbone/cloud-component-library"
import {Route, Routes} from "react-router-dom"
import {ErrorBoundary} from "../../Components/ErrorBoundary"
import {TeamUrlProvider} from "./TeamUrlProvider"
import {JoinTeam} from "./JoinTeam"
import {JoinTeamSuccess} from "./JoinTeamSuccess"

export function TeamLayout(props) {

    return <>
        <ErrorBoundary>
            <Routes>
                <Route path="/success" Component={JoinTeamSuccess}/>
                <Route path="/join" Component={JoinTeam}/>
                <Route path="*" element={<div>Not Found</div>}/>
            </Routes>
        </ErrorBoundary>
    </>
}
