import React from "react"
import {Button, FlexColumn, GhostButton, NotFoundError, Snackbar} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {Parser} from "../password/Parser"
import {ButtonSpinner} from "../../Components/ButtonSpinner"
import {ExternUserRestApiClient} from "../register/service/ExternUserRestApiClient"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {MailUrlProvider} from "./MailUrlProvider"
import {ErrorIcon} from "../../Components/ErrorIcon"
import {MailAnimationTemplate} from "../../Components/Illustrations/MailAnimationTemplate"
import {FormPaper} from "../../Components/FormPaper"
import {withNavigate} from "../../../hocs/withNavigate";

export const MailValidate = compose(withTranslation(), withNavigate)(
    class _MailValidate extends React.Component {

        state = {
            token: null,
            password: "",
            secondPassword: "",
            _sending: false,
            error: null
        }

        constructor(props) {
            super(props)

            this.httpRestApiClient = new ExternUserRestApiClient()
        }

        componentDidMount() {
            const token = Parser.parseURLParameter("token")
            if (token) {
                this.setState({token})
            }
        }

        handleChange = event => {
            this.setState({
                [event.target.name]: event.target.value
            })
        }

        handleFormSubmit = event => {
            event.preventDefault()
            const {t} = this.props

            if (!this.state.token) {
                return
            }

            this.setState({_sending: true})

            this.httpRestApiClient.confirmMail(this.state.token)
                .then(response => {
                    this.setState({_sending: false})
                    this.props.navigate('success')
                })
                .catch(error => {
                    this.setState({_sending: false})
                    if (error.type === NotFoundError) {
                        Snackbar.Error(t("mailValidate.error.notFound"))
                    } else {
                        this.setState({error: error})
                    }
                })
        }

        render() {
            if (this.state.error) {
                throw this.state.error
            }

            const {t} = this.props

            if (!this.state.token) {
                return (
                    <>
                        <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                            <Col xl={6} xs={12} md={8} lg={8}>
                                <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                                    <FormPaper
                                        title={t("error")}
                                        text={t("mailValidate.error")}
                                        actions={<Row>
                                            <Col>
                                                <a href="/ui">
                                                    <GhostButton>
                                                        {t("back.to.login")}
                                                    </GhostButton>
                                                </a>
                                            </Col>
                                        </Row>}
                                    >
                                        <ErrorIcon/>
                                    </FormPaper>
                                </FlexColumn>
                            </Col>
                            <Col>
                                <MailAnimationTemplate/>
                            </Col>
                        </Row>
                    </>
                )
            }

            return (
                <>
                    <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                        <Col xl={6} xs={12} md={8} lg={8}>
                            <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                                <FormPaper
                                    title={t("mailValidate.subheadline")}
                                    text={t("mailValidate.text")}
                                    actions={<Row>
                                        <Col>
                                            <a href="/ui">
                                                <GhostButton>
                                                    {t("back.to.login")}
                                                </GhostButton>
                                            </a>
                                        </Col>
                                        <Col>
                                            <form style={{display: "flex", justifyContent: "flex-end"}}
                                                  onSubmit={this.handleFormSubmit}>
                                                <Button disabled={this.state._sending}>
                                                    {t("confirm")}
                                                    <ButtonSpinner hidden={!this.state._sending}/>
                                                </Button>
                                            </form>
                                        </Col>
                                    </Row>}
                                >

                                </FormPaper>

                            </FlexColumn>
                        </Col>
                        <Col>
                            <MailAnimationTemplate/>
                        </Col>
                    </Row>
                </>
            )
        }
    }
)

