import {rest} from "msw"
import {buildUrl} from "../buildUrl"


export const msspAdminServiceHandlers = [
    rest.get(buildUrl("/mssp-admin/msp/join/:token/details"), (request, response, context) => {

        return response(
            context.status(200),
            context.json({
                "email": "main@mail.com"
            })
        )
    }),

    rest.post(buildUrl("/mssp-admin/msp/join/:token"), ((req, res, context) => {
        // return res(
        //     context.status(204)
        // )

        // return res(
        //     context.status(201)
        // )

        return res(
            context.status(200),
            context.json({
                "email": "string",
                "id": 1,
                "realmId": "string",
                "validUntil": "2021-03-23T15:29:12.387Z"
            })
        )
    }))
]
