export class TeamUrlProvider {

    static join() {
        return '/join'
    }

    static success() {
        return '/success'
    }
}
