import MaterialCheckbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import React from "react"
import styled from "styled-components"
import {Text} from "@greenbone/cloud-component-library"
import {Colors} from "../../../Theme"

const CheckboxText = styled(Text)``

const StyledUncheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  font-size: 1.875rem;
  color: ${props => props.theme.button.normal.background};
`

const StyledUncheckedErrorIcon = styled(CheckBoxOutlineBlankIcon)`
  font-size: 1.875rem;
  color: ${Colors.red.normal};
`;

const StyledCheckedIcon = styled(CheckBoxIcon)`
  font-size: 1.875rem;
  color: ${props => props.theme.button.normal.background};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: fit-content
`;

export function Checkbox(props) {
    const {isValid, label, LabelComponent, ...others} = props


    return (
        <StyledFormControlLabel
            control={
                <MaterialCheckbox
                    {...others}
                    icon={isValid === false ? <StyledUncheckedErrorIcon/> : <StyledUncheckedIcon/>}
                    checkedIcon={<StyledCheckedIcon/>}
                />
            }
            label={(!!LabelComponent && LabelComponent) || (<CheckboxText noMargin color={"black"}>{label}</CheckboxText>)}
        />
    );
}
