import styled from "styled-components"
import {Button} from "@greenbone/cloud-component-library"

export const SuperHeadline = styled.h1`
  margin-top: 6rem;
  font-weight: bold;
  font-size: 3.5rem;
  margin-bottom: 3rem;
`

export const SuperParagraph = styled.p`
  font-size: 1.8rem;
`


export const BigButton = styled(Button)`
  font-size: 1.2rem;
  margin-top: 2rem;
  width: 12rem;
  height: 3rem;
`
