import React from "react"
import {Route, Routes} from "react-router-dom"
import {PasswordUrlProvider} from "./PasswordUrlProvider"
import {PasswordResetInitPage} from "./PasswordResetInitPage"
import {PasswordResetInitSuccess} from "./PasswordResetInitSuccess"
import {PasswordReset} from "./PasswordReset"
import {PasswordResetSuccess} from "./PasswordResetSuccess"
import {ErrorBoundary} from "../../Components/ErrorBoundary"


export function PasswordLayout(props) {

    return <>


        <ErrorBoundary>
                                <Routes>
                                    <Route path="/init/success"
                                           Component={PasswordResetInitSuccess}/>
                                    <Route path="/init" Component={PasswordResetInitPage}/>
                                    <Route path="/reset/success" Component={PasswordResetSuccess}/>
                                    <Route path="/reset" Component={PasswordReset}/>
                                    <Route path="*" element={<div>Not Found</div>}/>
                                </Routes>
                            </ErrorBoundary>

    </>;
}
