import {Col, Row} from "reactstrap"
import {Paper} from "../../Components/Layout"
import {FlexRow, GhostButton} from "@greenbone/cloud-component-library"
import React, {useContext} from "react"
import {useTranslation} from "react-i18next"
import {Link, Navigate, Routes, Route, useLocation} from "react-router-dom"
import {GreenboneTermsOfUse} from "./GreenboneTermsOfUse"
import {VmspTermsOfUse} from "./VmspTermsOfUse"
import {LegalContext} from "../../Context/LegalContext"
import {ApplicationUrlProvider} from "../../Layout/ApplicationUrlProvider"
import {ActiveGhostButton} from "../../Components/ActiveGhostButton"


export function TermsOfUsePage() {

    const {t} = useTranslation()

    const {pathname} = useLocation()


    const {termsOfUseEnabled} = useContext(LegalContext)

    return <Row className={"justify-content-center"} style={{marginTop: "4rem", marginBottom: "4rem"}}>
        <Col xs={8}>
            <Paper>
                <FlexRow justifyContent={"space-between"}>
                    <a href="/ui">
                        <GhostButton style={{marginTop: 0}}>{t("back.to.login")}</GhostButton>
                    </a>

                    <FlexRow>
                        <Link to={ApplicationUrlProvider.termsOfUseProviders.greenbone}>
                            <ActiveGhostButton active={pathname.endsWith("greenbone")}
                                               style={{marginTop: 0, marginRight: "1rem"}}>Greenbone</ActiveGhostButton>
                        </Link>
                        {termsOfUseEnabled && <Link to={ApplicationUrlProvider.termsOfUseProviders.vmsp}>
                            <ActiveGhostButton active={pathname.endsWith("vmsp")}
                                               style={{marginTop: 0}}>Partner</ActiveGhostButton>
                        </Link>}

                    </FlexRow>
                </FlexRow>

                <Routes>
                    <Route path="/greenbone" Component={GreenboneTermsOfUse}/>
                    {termsOfUseEnabled &&
                    <Route path="/vmsp" Component={VmspTermsOfUse}/>}

                    <Route
                        exact
                        path="/"
                        element={<Navigate to="greenbone"/>}
                    />
                </Routes>
            </Paper>
        </Col>
    </Row>
}
