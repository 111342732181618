import React from "react"
import {Snackbar, Spinner, useRequest} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"
import {LegalRestApiClient} from "../../LegalRestApiClient"
import {RichTextCss} from "../../Components/RichTextCss"


export function VmspTermsOfUse(props) {
    const apiClient = new LegalRestApiClient()
    const {i18n} = useTranslation()
    const [data, loading, error] = useRequest(apiClient.termsOfService(i18n.language).bind(this))

    if (error) {
        Snackbar.Error(error.toString())
    }

    const createMarkup = () => {
        return {__html: data?.value}
    }
    return <>

        <RichTextCss/>
        {loading ? <div><Spinner/></div> :
            <div className={"ql-editor"} dangerouslySetInnerHTML={createMarkup()}/>}


    </>
}
