import React from "react"
import styled from "styled-components"

const Img = styled.img`
    max-width: 13.75rem;
    max-height: 3.75rem;
    margin-bottom: 3.875rem;
`


export function Logo(props) {
    return <Img {...props} className="logo"
                src="/vmssp-admin/public/assets/logoReport" alt="Logo"/>
}
