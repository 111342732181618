import React from "react"
import {Button, FlexColumn, FlexRow, GhostButton} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {SuccessIcon} from "../../Components/SuccessIcon"
import {ThumbUpAnimationTemplate} from "../../Components/Illustrations/ThumbsUpAnimationTemplate"
import {useTranslation} from "react-i18next"
import {FormPaper} from "../../Components/FormPaper"


export function PasswordResetInitSuccess(props) {

    const {t} = useTranslation()

    return (
        <>
            <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                <Col xl={6} xs={12} md={8} lg={8}>
                    <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                        <FormPaper
                            title={t("passwordResetInitSuccess.headline")}
                            text={t("passwordResetInitSuccess.text")}
                            actions={<Row>
                                <Col>
                                    <a href="/ui">
                                        <GhostButton noMargin>
                                            {t("back.to.login")}
                                        </GhostButton>

                                    </a>
                                </Col>
                                <Col>
                                    <FlexRow justifyContent={"flex-end"}>
                                        <a href="/ui">
                                            <Button noMargin>
                                                {t("to.login")}
                                            </Button>
                                        </a>
                                    </FlexRow>
                                </Col>
                            </Row>}
                        >
                            <SuccessIcon/>
                        </FormPaper>
                    </FlexColumn>
                </Col>
                <ThumbUpAnimationTemplate/>
            </Row>
        </>
    )

}


