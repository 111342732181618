import React from "react"
import {NavLink, Route, Routes} from "react-router-dom"
import styled, {createGlobalStyle, css} from "styled-components"
import {ApplicationUrlProvider} from "./ApplicationUrlProvider"
import {Col, Container, Row} from "reactstrap"
import {useTranslation} from "react-i18next"

const Footer = styled.div`
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    ${props => {
      if (props.theme.login) {
        return css`color: ${props.theme.login.footerTextColor} !important;
        `
      }
    }}
  }

  ${props => {
    if (props.theme.login) {
      return css`color: ${props.theme.login.footerTextColor} !important;
      `
    }
  }}
`

const FooterRow = styled(Row)`
  ${props => {
    if (props.theme.login) {
      return `background-color: ${props.theme.login.footerBackgroundColor};`
    }
  }}
`


const Global = createGlobalStyle`
  body {
    width: 100%;
    min-height: 100vh;
    background-color: ${props => {
      if (props.theme.login) {
        return props.theme.login.screenBackgroundColor
      }

    }};

  }

  #root {
    min-height: 100vh;
  }


`

const Column = styled(Col)`

  max-width: 1600px;

  @media (max-width: 500px) {
    padding: 0;
  }
`


function FooterContent() {

    const {t} = useTranslation()

    return <>
        <NavLink
            to={ApplicationUrlProvider.imprint()}>{t("footer.imprint")}</NavLink>
        <NavLink style={{marginLeft: "1rem"}}
                 to={ApplicationUrlProvider.privacyPolicy()}>{t("footer.pp")}</NavLink>
        <NavLink style={{marginLeft: "1rem"}}
                 to={ApplicationUrlProvider.termsOfUse()}>
            {t("footer.termsOfService")}
        </NavLink>
    </>
}

export function ApplicationLayout({children}) {

    return <>
        <Global/>
        <Container className={"background-image"} fluid={true} style={{minHeight: "100vh"}}>
            <Row
                style={{minHeight: "calc(100vh - 3rem)", justifyContent: "center"}}>
                <Column>
                    {children}
                </Column>
            </Row>
            <FooterRow>
                <Col>
                    <Footer>
                        <Routes>
                            <Route path="/msp/*" Component={() => null}/>
                            <Route path="/*" Component={FooterContent}/>
                        </Routes>
                    </Footer>
                </Col>
            </FooterRow>

        </Container>


    </>
}
