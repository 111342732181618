//@flow

class ExternUserRemoteUrlProvider {
    USERMANAGEMENT = "/usermanagement";

    register() {
        return `${this.USERMANAGEMENT}/register/creditcard`;
    }

    registerFree() {
        return `${this.USERMANAGEMENT}/register`;
    }

    registerInvoice() {
        return `${this.USERMANAGEMENT}/register/invoice`;
    }

    confirmPasswordReset() {
        return `${this.USERMANAGEMENT}/confirm_password_reset`
    }

    initializePasswordReset() {
        return `${this.USERMANAGEMENT}/initialize_password_reset`
    }

    initializeConfirmEmail() {
        return `${this.USERMANAGEMENT}/initialize_confirm_email`
    }

    confirmEmail() {
        return `${this.USERMANAGEMENT}/confirm_email`
    }
}

export {ExternUserRemoteUrlProvider};
