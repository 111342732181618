import React, {useEffect, useState} from "react"
import {
    ActionButton,
    FlexRow,
    GhostButton,
    Headline,
    Input,
    Paragraph,
    Snackbar
} from "@greenbone/cloud-component-library"
import {Col, Container, Row} from "reactstrap"
import {useTranslation} from "react-i18next"
import {Paper} from "../../Components/Layout"
import {isPasswordStrongEnough, PasswordInput} from "../password/PasswordInput"
import {Adornment} from "../../Components/Adornment"
import KeyIcon from "../../../assets/icons/key.svg"
import {MSPRestApiClient} from "./MSPRestApiClient"
import {useNavigate} from "react-router"
import {ButtonSpinner} from "../../Components/ButtonSpinner"
import {ErrorIcon} from "../../Components/ErrorIcon"
import {useSearchParams} from "react-router-dom";


export function CreateMSP(props) {

    const {t} = useTranslation()
    const {i18n} = useTranslation()

    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")
    const [repeatValid, setRepeatValid] = useState(null)
    const [passwordValid, setPasswordValid] = useState(null)
    const [privacyPolicyAccepted] = useState(true)
    const [termsAccepted] = useState(true)
    const [token, setToken] = useState(null)
    const [tokenValid, setTokenValid] = useState(true)
    const [loading, setLoading] = useState(true)
    const [tokenRead, setTokenRead] = useState(false)
    const [sending, setSending] = useState(false)
    const [fieldErrors, setFieldErrors] = useState({
        password: null,
        termsAccepted: null,
        termsOfUseAccepted: null
    })
    const [details, setDetails] = useState(null)
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setToken(searchParams.get('token'))
        setTokenRead(true)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])


    useEffect(() => {
        const f = async () => {
            const apiClient = new MSPRestApiClient()
            apiClient.details(token)
                .then(response => {
                    setDetails(response)
                    setTokenValid(true)
                })
                .catch(e => {
                    setTokenValid(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        if (tokenRead) {
            if (token) {
                f()
            } else {
                setTokenValid(false)
                setLoading(false)
            }
        }
    }, [token, tokenRead])

    const handleFormSubmit = event => {
        event.preventDefault()
        const apiClient = new MSPRestApiClient()
        setRepeatValid(null)
        setPasswordValid(null)

        if (!isPasswordStrongEnough(password)) {
            Snackbar.Error(t("jointeam.passwordTooWeak"))
            setPasswordValid(t("jointeam.fieldNotValid"))
            return
        }

        if (passwordRepeat !== password) {
            setRepeatValid(t("jointeam.fieldNotValid"))
            Snackbar.Error(t("jointeam.passwordTooWeak"))
            return
        }

        setSending(true)

        apiClient.join(password, termsAccepted, privacyPolicyAccepted, token, i18n.language)
            .then(response => {
                if (response) {
                    if (response.status === "BAD_REQUEST") {
                        setFieldErrors(response.fieldErrors)
                        setSending(false)
                    }

                    if (response?.id) {
                        setSending(false)
                        navigate("/msp/success")
                        return
                    }

                    if (response?.message) {
                        Snackbar.Error(response.message)
                    } else {
                        Snackbar.Error(t("error.occurred"))
                    }
                    setSending(false)
                } else {
                    setSending(false)
                    navigate("/msp/success")
                }
            })
            .catch(e => {
                if (e.status === 201) {
                    setSending(false)
                    navigate("/msp/success")
                    return
                }

                if (e?.message) {
                    Snackbar.Error(e.message)
                    setSending(false)
                    return
                }

                if (e.status === 404) {
                    Snackbar.Error(t("joinTeam.userNotFound"))
                } else if (e.status === 422) {
                    Snackbar.Error(t("joinTeam.tokenExpired"))
                } else {
                    Snackbar.Error(e)
                }
                setSending(false)

            })

    }

    if (loading) {
        return <div></div>
    }

    if (!tokenValid) {
        return <FlexRow alignItems={"center"} justifyContent={"center"} full>
            <Container>
                <Row className={"justify-content-center"}>
                    <Col xl={7}>
                        <Paper style={{padding: "4rem", marginTop: "2rem", marginBottom: "2rem"}}>
                            <Headline>Create MSP</Headline>
                            <Paragraph>
                                {t("mailValidate.error")}
                            </Paragraph>
                            <ErrorIcon/>
                            <FlexRow justifyContent={"flex-end"}>
                                <a href="/ui/mssp/admin/">
                                    <GhostButton style={{marginTop: "4rem"}}>
                                        {t("back.to.login")}
                                    </GhostButton>
                                </a>
                            </FlexRow>
                        </Paper>
                    </Col>
                </Row>
            </Container>
        </FlexRow>
    }

    return <FlexRow alignItems={"center"} justifyContent={"center"} full>
        <Container>
            <Row className={"justify-content-center"}>
                <Col xl={7}>

                    <Paper style={{padding: "4rem", marginTop: "2rem", marginBottom: "2rem"}}>
                        <Headline>{t("createMsp.headline")}</Headline>

                        <Paragraph>
                            {details?.companyName ? t("createMsp.textWithDetails", {companyName: details?.companyName}) : t("createMsp.textWithoutDetails")}
                        </Paragraph>

                        <Paragraph>
                            {t("createMsp.mail", {mail: details?.email})}
                        </Paragraph>

                        <form onSubmit={handleFormSubmit}>
                            <div style={{marginBottom: "2rem"}}>
                                <Row>
                                    <Col>
                                        <PasswordInput
                                            adornment={<Adornment>
                                                <KeyIcon/>
                                            </Adornment>}
                                            fullWidth
                                            margin={"normal"}
                                            type={"password"}
                                            name={"password"}
                                            onChange={(event) => {
                                                setPassword(event.target.value)
                                                setPasswordValid(null)
                                            }}
                                            label={t("common.profile.changePassword.fields.newPassword")}
                                            value={password}
                                            isValid={passwordValid || fieldErrors?.password}
                                        />
                                    </Col>
                                    <Col>
                                        <Input label={t("common.profile.changePassword.fields.newPasswordSecond")}
                                               type={"password"}
                                               value={passwordRepeat}
                                               margin={"normal"}
                                               isValid={repeatValid}
                                               onChange={(event) => {
                                                   setPasswordRepeat(event.target.value)
                                                   setRepeatValid(null)
                                               }}
                                               adornment={<Adornment>
                                                   <KeyIcon/>
                                               </Adornment>}/>
                                    </Col>
                                </Row>
                            </div>

                            <FlexRow justifyContent={"flex-end"}>
                                <ActionButton disabled={sending}>{t("createMsp.join")} <ButtonSpinner
                                    hidden={!sending}/> </ActionButton>
                            </FlexRow>

                        </form>
                    </Paper>
                </Col>
            </Row>
        </Container>
    </FlexRow>
}
