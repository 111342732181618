export class ExternHostValidationRemoteUrlProvider {

    EXTERNHOSTVALIDATION = "/host-validations/host-validation-resolutions";

    tokenApprove(tokenId) {
        return `${this.EXTERNHOSTVALIDATION}/token/${tokenId}/approve`;
    }

    tokenReject(tokenId) {
        return `${this.EXTERNHOSTVALIDATION}/token/${tokenId}/reject`;
    }
}


