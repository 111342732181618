import * as HttpStatuscode from "http-status-codes"
import {
    ForbiddenError,
    InvalidStatusCodeError,
    NotFoundError,
    ServiceNotAvailableError
} from "@greenbone/cloud-component-library"
import {PaymentRequiredError} from "../PaymentRequiredError"
import {ExternUserRemoteUrlProvider} from "./ExternUserRemoteUrlProvider"
import {FetchClient} from "../../../../service/FetchClient"
import {BASEURL} from "../../../Constants"

const t = (p) => (p)

class ExternUserRestApiClient {
    httpJsonClient
    urlProvider

    constructor() {
        this.httpJsonClient = new FetchClient(null, BASEURL).getUnauthenticatedInstance()
        this.urlProvider = new ExternUserRemoteUrlProvider()
    }

    async getCountryCodes() {
        return await this.httpJsonClient.get(`/usermanagement/public/countries`)
            .then(async response => {
                return await response.json()
            })
    }

    async registerStatus() {
        return await this.httpJsonClient.get(`/usermanagement/register/status`)
            .then(async response => {
                return await response.json()
            })
    }

    async registerUser(user) {

        const response = await this.httpJsonClient.post(this.urlProvider.register(), user)
        if (response) {
            if (response.status === HttpStatuscode.NO_CONTENT) {
                return response
            } else if (response.status === HttpStatuscode.PAYMENT_REQUIRED) {
                throw new PaymentRequiredError()
            } else if (response.status === HttpStatuscode.CONFLICT) {
                throw new InvalidStatusCodeError(HttpStatuscode.CONFLICT)
            } else if (response.status === HttpStatuscode.FORBIDDEN) {
                throw new ForbiddenError(t("components.error.boundary.noPermission"))
            }
        }
        throw new ServiceNotAvailableError()
    }

    async registerFreeUser(user) {
        const response = await this.httpJsonClient.post(this.urlProvider.registerFree(), user)
        if (response) {
            if (response.status === HttpStatuscode.NO_CONTENT || response.status === HttpStatuscode.OK) {
                return response
            } else if (response.status === HttpStatuscode.PAYMENT_REQUIRED) {
                throw new PaymentRequiredError()
            } else if (response.status === HttpStatuscode.CONFLICT) {
                throw new InvalidStatusCodeError(HttpStatuscode.CONFLICT)
            } else if (response.status === HttpStatuscode.FORBIDDEN) {
                throw new ForbiddenError(t("components.error.boundary.noPermission"))
            } else if (response.status === HttpStatuscode.BAD_REQUEST) {
                return response
            }
        }
        throw new ServiceNotAvailableError()
    }

    async registerInvoiceUser(user) {
        const response = await this.httpJsonClient.post(this.urlProvider.registerInvoice(), user)
        if (response) {
            if (response.status === HttpStatuscode.NO_CONTENT) {
                return response
            } else if (response.status === HttpStatuscode.PAYMENT_REQUIRED) {
                throw new PaymentRequiredError()
            } else if (response.status === HttpStatuscode.CONFLICT) {
                throw new InvalidStatusCodeError(HttpStatuscode.CONFLICT)
            } else if (response.status === HttpStatuscode.FORBIDDEN) {
                throw new ForbiddenError(t("components.error.boundary.noPermission"))
            }
        }
        throw new ServiceNotAvailableError()
    }

    async resetPassword(token, password) {
        const response = await this.httpJsonClient.post(this.urlProvider.confirmPasswordReset(), {
            token,
            newPassword: password
        })
        if (!response) {
            throw new ServiceNotAvailableError()
        }
        if (response.status === HttpStatuscode.NO_CONTENT) {
            return response
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        }
        throw  new InvalidStatusCodeError(response.status)
    }

    async requestPasswordReset(email) {
        const response = await this.httpJsonClient.post(this.urlProvider.initializePasswordReset(), {email})
        if (response && response.status === HttpStatuscode.ACCEPTED) {
            return response
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        }
        throw  new InvalidStatusCodeError(response.status)
    }

    async confirmMail(token) {
        const response = await this.httpJsonClient.put(this.urlProvider.confirmEmail(), {token})
        if (!response) {
            throw new ServiceNotAvailableError()
        }
        if (response.status === HttpStatuscode.NO_CONTENT) {
            return response
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError("Not found")
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async requestConfirmMail(email) {
        const response = await this.httpJsonClient.post(this.urlProvider.initializeConfirmEmail(), {email})
        if (!response) {
            throw new ServiceNotAvailableError()
        }
        if (response.status === HttpStatuscode.ACCEPTED || response.status === HttpStatuscode.NO_CONTENT) {
            return response
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError("Not found")
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            //TODO - jwerremeyer clean this up. A lot of api stuff needs to be rewritten :(
            throw  await (async () => {
                const responseBody = await response.json()

                return {status: response.status, fieldErrors: responseBody.fieldErrors}
            })()
        }
        throw new InvalidStatusCodeError(response.status)
    }
}

export {ExternUserRestApiClient}
