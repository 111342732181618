import * as is_UUID from "is-uuid";


export class Validator {
    static DATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i;
    static TIME_HOUR_MINUTE_REGEX = /^[0-9]{2}:[0-9]{2}$/i;

    static isEMail(email) {
        const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return regex.test(String(email).toLowerCase());
    }

    static isDateString(dateString) {
        return Validator.DATE_REGEX.test(dateString);
    }

    static isNotEmptyString(name) {
        return name !== null && name !== undefined && name.length >= 1;
    }

    static isTimeHourMinuteString(timeString) {
        return Validator.TIME_HOUR_MINUTE_REGEX.test(timeString);
    }

    static isUUID(uuid) {
        return is_UUID.anyNonNil(uuid);
    }

    static allNestedTrue(fields) {
        for (let field in fields) {
            let value = true;
            if (fields[field] === null) {
                return false;
            } else if (typeof (fields[field]) === "boolean") {
                value = fields[field];
            } else if (typeof (fields[field]) === "object") {
                value = Validator.allNestedTrue(fields[field]);
            } else {
                throw new Error(`Invalid value:'${fields[field]}' type:'${typeof (fields[field])}' for '${field}'`);
            }

            if (value !== true) {
                return false;
            }
        }
        return true;
    }
}

class ValueCompare {
    _value;

    constructor(value) {
        this._value = value;
    }

    isInList(values) {
        return values.includes(this._value);
    }
}

export function Value(value) {
    return new ValueCompare(value);
}
