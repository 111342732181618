export function setLocalStorageData (key, value) {
    let toStore = value
    if (typeof value === "object" && !Array.isArray(value)) {
        const currentData = window.localStorage.getItem(key) || "{}"
        const currentDataParsed = JSON.parse(currentData)
        const stringified = JSON.stringify({...currentDataParsed, ...value})
        toStore = stringified
    } else if (Array.isArray(value)) {
        const currentData = window.localStorage.getItem(key) || "[]"
        const currentDataParsed = JSON.parse(currentData)
        const stringified = JSON.stringify([...currentDataParsed, ...value])
        toStore = stringified
    }
    return window.localStorage.setItem(key, toStore)
    
}

export function getLocalStorageData (key) {
    if (!key) return console.error("Specify the key of the localStorage value")
    const valueRaw = window.localStorage.getItem(key)
    if (!valueRaw) return
    return JSON.parse(valueRaw)
}

export function removeLocalStorageData (key) {
    if (!key) return console.error("Specify the key of the localStorage value")
    return window.localStorage.removeItem(key)
}

export function convertObjectStringValuesIntoBool (object = {}) {
    return Object.entries(object).reduce((acc, [key, value]) => {
        if (value === "true") {
            acc[key] = true
        } else if (value === "false") {
            acc[key] = false
        } else {
            acc[key] = value
        }
        return acc
    }, {})
}