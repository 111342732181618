import React, {useContext, useEffect, useState} from "react"
import {
    ActionButton,
    FlexRow,
    GhostButton,
    Headline,
    Input,
    Paragraph,
    Snackbar,
    Subheadline
} from "@greenbone/cloud-component-library"
import {Col, Container, Row} from "reactstrap"
import {Trans, useTranslation} from "react-i18next"
import {Paper} from "../../Components/Layout"
import {isPasswordStrongEnough, PasswordInput} from "../password/PasswordInput"
import {Adornment} from "../../Components/Adornment"
import KeyIcon from "../../../assets/icons/key.svg"
import {TeamRestApiClient} from "./TeamRestApiClient"
import queryString from "query-string"
import {useNavigate} from "react-router"
import {ErrorIcon} from "../../Components/ErrorIcon"
import {ButtonSpinner} from "../../Components/ButtonSpinner"
import {Checkbox} from "../register/Checkbox"
import {Link, useSearchParams} from "react-router-dom"
import {ApplicationUrlProvider} from "../../Layout/ApplicationUrlProvider"
import {LegalContext} from "../../Context/LegalContext"

export function JoinTeam(props) {

    const {t} = useTranslation()
    const {i18n} = useTranslation()
    const {privacyPolicyEnabled, termsOfUseEnabled} = useContext(LegalContext)

    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")
    const [repeatValid, setRepeatValid] = useState(null)
    const [passwordValid, setPasswordValid] = useState(null)
    const [legalFields, setLegalFields] = useState({
        greenboneTermsOfUseAccepted: false,
        vmspTermsOfUseAccepted: false,
        greenbonePrivacyPolicyAccepted: false,
        vmspPrivacyPolicyAccepted: false
    })
    const [token, setToken] = useState(null)
    const [tokenValid, setTokenValid] = useState(true)
    const [loading, setLoading] = useState(true)
    const [tokenRead, setTokenRead] = useState(false)
    const [isSending, setSending] = useState(false)
    const [, setFieldErrors] = useState({
        password: null,
        termsAccepted: null,
        termsOfUseAccepted: null
    })
    const [details, setDetails] = useState(null)

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setToken(searchParams.get('token'))
        setTokenRead(true)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])


    useEffect(() => {
        const f = async () => {
            const apiClient = new TeamRestApiClient()
            apiClient.details(token)
                .then(response => {
                    setDetails(response)
                })
                .catch(e => {
                    setTokenValid(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        if (tokenRead) {
            if (token) {
                f()
            } else {
                setTokenValid(false)
                setLoading(false)
            }
        }
    }, [token, tokenRead])


    const handleFormSubmit = event => {
        event.preventDefault()
        const apiClient = new TeamRestApiClient()
        setRepeatValid(null)
        setPasswordValid(null)


        if (!isPasswordStrongEnough(password)) {
            Snackbar.Error(t("jointeam.passwordTooWeak"))
            setPasswordValid(t("jointeam.fieldNotValid"))
            return
        }

        if (passwordRepeat !== password) {
            setRepeatValid(t("jointeam.fieldNotValid"))
            Snackbar.Error(t("jointeam.passwordTooWeak"))
            return
        }

        const legalBuild = {
            "termsOfUseAccepted": legalFields.greenboneTermsOfUseAccepted,
            "vmspTermsOfUseAccepted": legalFields.vmspTermsOfUseAccepted,
            "privacyPolicyAccepted": legalFields.greenbonePrivacyPolicyAccepted,
            "vmspPrivacyPolicyAccepted": legalFields.vmspPrivacyPolicyAccepted
        }

        setSending(true)
        apiClient.join({password, ...legalBuild}, token, i18n.language)
            .then(response => {
                setSending(false)
                if (response) {
                    if (response?.groupId) {
                        navigate("/team/success")
                        return
                    }

                    if (response.status === "BAD_REQUEST") {
                        setFieldErrors(response.fieldErrors)
                        if (response.fieldErrors) {
                            Snackbar.Error(t("jointeam.acceptLegal"))
                        }
                    } else {
                        if (response.message) {
                            Snackbar.Error(response.message)
                        } else {
                            Snackbar.Error("An Error occurred")
                        }
                    }

                } else {
                    navigate("/team/success")
                }
            })
            .catch(e => {
                setSending(false)
                if (e.status === 404) {
                    Snackbar.Error(t("joinTeam.userNotFound"))
                } else if (e.status === 422) {
                    Snackbar.Error(t("joinTeam.tokenExpired"))
                } else {
                    Snackbar.Error(e)
                }

            })

    }

    if (loading) {
        return <div></div>
    }

    const {
        greenboneTermsOfUseAccepted,
        vmspTermsOfUseAccepted,
        greenbonePrivacyPolicyAccepted,
        vmspPrivacyPolicyAccepted
    } = legalFields


    const handleLegalChange = (event) => {
        const fieldName = event.target.name

        setLegalFields({...legalFields, [fieldName]: !legalFields[fieldName]})
    }

    if (!tokenValid) {
        return <FlexRow alignItems={"center"} justifyContent={"center"} full>
            <Container>
                <Row className={"justify-content-center"}>
                    <Col xl={7}>
                        <Paper style={{padding: "4rem", marginTop: "2rem", marginBottom: "2rem"}}>
                            <Headline>{t("jointeam.headline")}</Headline>
                            <Paragraph>
                                {t("mailValidate.error")}
                            </Paragraph>
                            <ErrorIcon/>
                            <FlexRow justifyContent={"flex-end"}>
                                <a href="/ui">
                                    <GhostButton style={{marginTop: "4rem"}}>
                                        {t("back.to.login")}
                                    </GhostButton>
                                </a>
                            </FlexRow>

                        </Paper>
                    </Col>
                </Row>
            </Container>
        </FlexRow>
    }

    return <FlexRow alignItems={"center"} justifyContent={"center"} full>
        <Container>
            <Row className={"justify-content-center"}>
                <Col xl={7}>

                    <Paper style={{padding: "4rem", marginTop: "2rem", marginBottom: "2rem"}}>
                        <Headline>{t("jointeam.headline")}</Headline>

                        <Paragraph>
                            {details?.companyName ? t("jointeam.textWithDetails", {companyName: details?.companyName}) : t("jointeam.textWithoutDetails")}
                        </Paragraph>

                        <Paragraph>
                            {t("jointeam.mail", {mail: details?.email})}
                        </Paragraph>

                        <form onSubmit={handleFormSubmit}>
                            <div style={{marginBottom: "2rem"}}>
                                <Row>
                                    <Col>
                                        <PasswordInput
                                            adornment={<Adornment>
                                                <KeyIcon/>
                                            </Adornment>}
                                            fullWidth
                                            margin={"normal"}
                                            type={"password"}
                                            name={"password"}
                                            onChange={(event) => setPassword(event.target.value)}
                                            label={t("common.profile.changePassword.fields.newPassword")}
                                            value={password}
                                            isValid={passwordValid}
                                        />
                                    </Col>
                                    <Col>
                                        <Input label={t("common.profile.changePassword.fields.newPasswordSecond")}
                                               type={"password"}
                                               value={passwordRepeat}
                                               margin={"normal"}
                                               isValid={repeatValid}
                                               onChange={(event) => setPasswordRepeat(event.target.value)}
                                               adornment={<Adornment>
                                                   <KeyIcon/>
                                               </Adornment>}/>
                                    </Col>
                                </Row>
                            </div>

                            <hr style={{marginTop: "3rem", marginBottom: "3rem"}}/>

                            <Subheadline>{t("common.termsofUse")}</Subheadline>

                            <Checkbox checked={greenboneTermsOfUseAccepted}
                                      isValid={greenboneTermsOfUseAccepted}
                                      onChange={handleLegalChange}
                                      label={<Trans i18nKey={"register.greenboneTermsOfUse"}>
                                          Ich habe die <Link target={"_blank"}
                                                             to={ApplicationUrlProvider.termsOfUseProviders.greenbone}>Nutzungsbedingungen </Link>
                                          gelesen und stimme diesen zu.
                                      </Trans>}
                                      name={"greenboneTermsOfUseAccepted"}/>

                            {termsOfUseEnabled &&
                            <Checkbox checked={vmspTermsOfUseAccepted}
                                      isValid={vmspTermsOfUseAccepted}
                                      onChange={handleLegalChange}
                                      label={<Trans i18nKey={"register.vmspTermsOfUse"}>
                                          Ich habe die <Link target={"_blank"}
                                                             to={ApplicationUrlProvider.termsOfUseProviders.vmsp}>Nutzungsbedingungen </Link>
                                          des Partners gelesen und stimme diesen zu.
                                      </Trans>}
                                      name={"vmspTermsOfUseAccepted"}/>
                            }

                            <hr style={{marginTop: "3rem", marginBottom: "3rem"}}/>

                            <Subheadline>{t("common.privacyPolicy")}</Subheadline>


                            <Checkbox checked={greenbonePrivacyPolicyAccepted}
                                      isValid={greenbonePrivacyPolicyAccepted}
                                      onChange={handleLegalChange}
                                      label={<Trans i18nKey={"register.greenbonePrivacyPolicy"}>
                                          Ich habe die <Link target={"_blank"}
                                                             to={ApplicationUrlProvider.privacyPolicyProviders.greenbone}>Datenschutzbestimmungen </Link>
                                          zur Kenntnis genommen und stimme diesen zu.
                                      </Trans>}
                                      name={"greenbonePrivacyPolicyAccepted"}/>

                            {privacyPolicyEnabled &&
                            <Checkbox checked={vmspPrivacyPolicyAccepted}
                                      isValid={vmspPrivacyPolicyAccepted}
                                      onChange={handleLegalChange}
                                      label={<Trans i18nKey={"register.vmspPrivacyPolicy"}>
                                          Ich habe die <Link target={"_blank"}
                                                             to={ApplicationUrlProvider.privacyPolicyProviders.vmsp}>Datenschutzbestimmungen </Link>
                                          des Partners zur Kenntnis genommen und stimme diesen zu.
                                      </Trans>}
                                      name={"vmspPrivacyPolicyAccepted"}/>
                            }

                            <hr style={{marginTop: "3rem", marginBottom: "3rem"}}/>

                            <FlexRow justifyContent={"flex-end"}>
                                <ActionButton disabled={isSending}>{t("jointeam.join")}
                                    <ButtonSpinner hidden={!isSending}/>
                                </ActionButton>
                            </FlexRow>

                        </form>
                    </Paper>
                </Col>
            </Row>
        </Container>
    </FlexRow>
}
