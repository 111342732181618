import React from "react"
import {Button, FlexColumn, FlexRow} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {SuccessIcon} from "../../Components/SuccessIcon"
import {MailAnimationTemplate} from "../../Components/Illustrations/MailAnimationTemplate"
import {useTranslation} from "react-i18next"
import {FormPaper} from "../../Components/FormPaper"


export function MailValidateRequestSuccess(props) {

    const {t} = useTranslation()

    return (
        <>
            <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                <Col xl={6} xs={12} md={8} lg={8}>
                    <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                        <FormPaper
                            title={t("mailValidateRequestSuccess.headline")}
                            text={t("mailValidateRequestSuccess.text")}
                            actions={<Row>
                                <Col>

                                </Col>
                                <Col>
                                    <FlexRow justifyContent={"flex-end"}>
                                        <a href="/">
                                            <Button>
                                                {t("to.login")}
                                            </Button>
                                        </a>
                                    </FlexRow>
                                </Col>
                            </Row>}
                        >
                            <SuccessIcon/>
                        </FormPaper>
                    </FlexColumn>
                </Col>
                <MailAnimationTemplate/>
            </Row>
        </>
    )

}

