import {withStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import {Input} from "@greenbone/cloud-component-library"
import {compose} from "redux"
import {withTranslation} from "react-i18next"


const styles = theme => ({
    bar: {
        position: "relative;",
        display: "block;",
        height: "0.25rem;",
        width: "0;",
        border: "1px;",
        backgroundColor: "#9b59b6;",
        transition: "all .4s;"
    },
    weak: {
        width: "25% !important",
        backgroundColor: "#BF3317"
    },
    medium: {
        width: "50% !important",
        backgroundColor: "#FFD630"
    },
    well: {
        width: "75% !important",
        backgroundColor: "#FFD630"
    },
    strong: {
        width: "100% !important;",
        backgroundColor: "#42801F"
    },
    full: {
        width: "100%"
    }
});


const UppercaseLetters = /.*?[A-Z].*?/;
const Numbers = /.*?[0-9]+.*?/
const LowercaseLetters = /.*?[a-z].*?/

export function calculatePasswordStrength(password) {
    let score = 0;
    //$FlowFixMe

    if (password.length >= 6) {
        score = 1;
    }
    if (password.length >= 8) {
        score = 2
    }
    if ((_PasswordInput.UppercaseLetters.test(password)) && password.length >= 5) {
        score = 2
    }
    if ((
            (UppercaseLetters.test(password) || _PasswordInput.Numbers.test(password))) &&
        password.length >= 6) {
        score = 3
    }
    if (
        UppercaseLetters.test(password) &&
        Numbers.test(password) &&
        password.length >= 8) {
        score = 4
    }

    if (
        UppercaseLetters.test(password) &&
        Numbers.test(password) &&
        LowercaseLetters.test(password) &&
        password.length >= 8) {
        score = 5
    }


    return score
}

export function isPasswordStrongEnough(password) {
    return calculatePasswordStrength(password) >= 5
}

class _PasswordInput extends React.Component {
    static UppercaseLetters = /.*?[A-Z].*?/;
    static LowercaseLetters = /.*?[a-z].*?/;
    static Numbers = /.*?[0-9]+.*?/;
    requiredPasswordLength = 8;
    state = {
        score: 0,
        additionalClass: null,
        missingChars: []
    };





    /**
     * Updating the state for the progress bar based on the event score
     *
     * @param password password
     */
    missingInPassword = (password) => {
        const {t} = this.props;

        let missingExpressedInWords = [];
        if (_PasswordInput.UppercaseLetters.test(password) === false) {
            missingExpressedInWords.push(t("passwordInput.uppercase")); // "Großbuchstabe"
        }

        if (_PasswordInput.LowercaseLetters.test(password) === false) {
            missingExpressedInWords.push(t("passwordInput.lowercase"));
        }
        if (_PasswordInput.Numbers.test(password) === false) {
            missingExpressedInWords.push(t("passwordInput.number"));
        }

        if (password.length < this.requiredPasswordLength) {
            missingExpressedInWords.push(`${this.requiredPasswordLength - password.length} ${t("passwordInput.char")}`);
        }

        return missingExpressedInWords;
    };

    updatePasswordStrength = (event) => {
        const {classes} = this.props;

        const password = event.target.value;
        let score = calculatePasswordStrength(password);

        let newState;
        switch (score) {
            case 1:
            case 2: {
                newState = classes.medium;
                break;
            }
            case 3: {
                newState = classes.well
                break
            }
            case 4: {
                newState = classes.well
                score = 4
                break
            }
            case 5: {
                newState = classes.strong
                score = 4
                break
            }
            default: {
                newState = classes.weak
            }
        }


        this.setState({
            score: score,
            additionalClass: newState
        });

        const missingGroups = this.missingInPassword(password);
        this.setState({missingChars: missingGroups});
        this.props.onChange(event, score, missingGroups);
    };

    /**
     * Generates a JSX component for the password field
     *
     * @returns {*} - password-field component
     */
    render() {
        const {classes, t} = this.props;

        return (
            <div className={classes.full} style={this.props.style}>
                <Input
                    margin={this.props.margin}
                    adornment={this.props.adornment}
                    disabled={this.props.disabled}
                    isValid={this.props.isValid}
                    className={classNames(this.props.className, classes.full)}
                    required={this.props.required}
                    type={"password"}
                    label={this.props.label}
                    onChange={this.updatePasswordStrength}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    name={this.props.name}/>

                <div className={classNames(classes.bar, this.state.additionalClass)}/>
                <div>
                    {
                        (this.state.missingChars.length > 0) &&
                        <div>
                            <span className={classes.fontSize}>
                                {t("passwordInput.missing")}
                            </span>
                            {this.state.missingChars.map((missingChar, index) => {
                                if (index + 1 === this.state.missingChars.length) {
                                    return <span key={index}>{missingChar}</span>;

                                }
                                return <span key={index}>{missingChar}, </span>;
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export const PasswordInput = compose(withStyles(styles), withTranslation())(_PasswordInput);
