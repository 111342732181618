import * as React from "react"
import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {Headline, Spinner} from "@greenbone/cloud-component-library"
import {sendingState} from "../Enum"
import {LegalContext} from "../../../Context/LegalContext"
import { LoadingOverlay, RegisterPageContainer } from "./StyledComponents"
import RegisterSlides from "./RegisterSlides"
import Loader from "../../../Components/Loader"

function LoadingScreen(props) {
    const {sendingGuiState} = props
    const {t} = useTranslation()

    if (sendingGuiState !== sendingState.NOTSENDING) {
        return (
            <LoadingOverlay>
                <div>
                    <Loader/>
                    <br/>
                    <Headline>
                        {
                            (sendingGuiState === sendingState.SENDING) && t("common.information.sendingData")
                        }
                    </Headline>
                </div>
            </LoadingOverlay>
        )
    }
    return null
}

export function RegisterFreeComponent(props) {
    const {isLegalLoading} = useContext(LegalContext)

    if (isLegalLoading) {
        return <Spinner/>
    }

    return <>
        <RegisterPageContainer>
                <LoadingScreen sendingGuiState={props.sendingGuiState}/>
                <RegisterSlides {...props} />
        </RegisterPageContainer>
    </>
}
