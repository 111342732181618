import React from "react"
import Promise from "bluebird"
import {getValidationResult} from "../../FieldValidator"
import {IsEmail, IsNotEmptyString, IsPasswordStrong} from "../../FormValidators"
import {LANGUAGE_FALLBACK} from "../../Constants"
import {ExternalSubscriptionRestApiClient} from "./service/ExternalSubscriptionRestApiClient"
import {ExternUserRestApiClient} from "./service/ExternUserRestApiClient"
import {PaymentMethods, sendingState} from "./Enum"
import {RegisterFreeComponent} from "./RegisterFreeComponent"
import {withTranslation} from "react-i18next"
import {Snackbar, Spinner} from "@greenbone/cloud-component-library"
import {compose} from "redux"
import {LegalRestApiClient} from "../../LegalRestApiClient"
import { convertObjectStringValuesIntoBool, getLocalStorageData, removeLocalStorageData, setLocalStorageData } from "./RegisterFreeComponent/utils"

class _RegisterContainer extends React.Component {
    externUserRestApiClient
    subscriptionClient
    payFormRef
    setStateAsync

    BillingAddressFieldValidators = {
        title: new IsNotEmptyString(),
        firstName: new IsNotEmptyString(),
        lastName: new IsNotEmptyString(),
        companyName: new IsNotEmptyString(),
        streetAddress: new IsNotEmptyString(),
        houseNumber: new IsNotEmptyString(),
        postalCode: new IsNotEmptyString(),
        city: new IsNotEmptyString(),
        countryId: new IsNotEmptyString()
    }

    CardFieldValidators = {
        cardOwner: new IsNotEmptyString()
    }

    state = {
        plan: null,
        fields: {
            billingAddress: {
                companyName: "",
                title: "",
                firstName: "",
                lastName: "",
                streetAddress: "",
                houseNumber: "",
                postalCode: "",
                city: "",
                countryId: 0
            },
            alternativeBillingAddress: {
                companyName: "",
                title: "",
                firstName: "",
                lastName: "",
                streetAddress: "",
                houseNumber: "",
                postalCode: "",
                city: ""
            },
            loginData: {
                email: "",
                password: "",
                passwordRepeat: "",
                securityNewsActive: false,
                language: LANGUAGE_FALLBACK
            },
            card: {
                cardOwner: ""
            }
        },
        validFields: {
            billingAddress: {
                title: null,
                firstName: null,
                lastName: null,
                companyName: null,
                streetAddress: null,
                houseNumber: null,
                postalCode: null,
                city: null
            },
            alternativeBillingAddress: {
                title: null,
                firstName: null,
                lastName: null,
                companyName: null,
                streetAddress: null,
                houseNumber: null,
                postalCode: null,
                city: null
            },
            loginData: {
                email: null,
                password: null,
                passwordRepeat: null
            },
            card: {
                cardOwner: null
            },
            legal: {
                greenboneTermsOfUseAccepted: null,
                vmspTermsOfUseAccepted: null,
                greenbonePrivacyPolicyAccepted: null,
                vmspPrivacyPolicyAccepted: null
            }
        },
        legal: {
            greenboneTermsOfUseAccepted: false,
            vmspTermsOfUseAccepted: false,
            greenbonePrivacyPolicyAccepted: false,
            vmspPrivacyPolicyAccepted: false
        },
        legalSettings: {},
        useAlternativeBillingAddress: false,
        paymentMethod: PaymentMethods.INVOICE,
        fieldErrors: null,
        _sending: false,
        isValid: false,
        sendingGuiState: sendingState.NOTSENDING,
        _loading: true
    }

    LoginDataFieldValidators = {
        email: new IsEmail(),
        password: new IsPasswordStrong()
    }

    constructor(props) {
        super(props)
        this.setStateAsync = Promise.promisify(this.setState)
        this.externUserRestApiClient = new ExternUserRestApiClient()
        this.subscriptionClient = new ExternalSubscriptionRestApiClient()
        this.payFormRef = React.createRef()
    }

    componentDidMount() {
        this.loadLegalSettings()
        this.loadPlans()
        this.loadLocalStorageData()
    }

    loadLocalStorageData () {
        const loginDataParsed = getLocalStorageData("loginData")
        const billingDataParsed = getLocalStorageData("billingData")
        const legalDataParsed = getLocalStorageData("legalData")
        this.setState(previousState => {
            return {
                fields: {
                    ...previousState.fields,
                    loginData: {
                        ...previousState.fields.loginData,
                        ...convertObjectStringValuesIntoBool(loginDataParsed)
                    },
                    billingAddress: {
                        ...previousState.fields.billingAddress,
                        ...convertObjectStringValuesIntoBool(billingDataParsed)
                    },
                },
                legal: {
                    ...previousState.legal,
                    ...convertObjectStringValuesIntoBool(legalDataParsed)
                }
            }
        })
        return
    }

    loadLegalSettings = () => {
        this.setState({_loading: true})
        const apiClient = new LegalRestApiClient()
        apiClient.settings()
            .then(response => {
                this.setState({legalSettings: response})
            })
            .catch(e => console.log(e))
            .finally(() => this.setState({_loading: false}))
    }

    loadPlans = () => {
        this.subscriptionClient.getAvailableSubscriptionPlan()
            .then(plans => {
                this.setState({plan: plans.FREE, paymentMethod: PaymentMethods.NONE})
            })
            .catch(error => {
                this.setState({_exception: error})
            })
    }

    fieldChangeHandler = (fieldsetName) => (event) => {
        const {name, value} = event.target
        this.setState(prevState => {
            let fields = prevState.fields
            fields[fieldsetName][name] = value
            return {fields}
        })
    }

    handleBillingAddressChange = (event) => {
        this.fieldChangeHandler("billingAddress")(event)
        this.setState(previous => {
            let newValidItems = previous.validFields.billingAddress
            newValidItems[event.target.name] = this.BillingAddressFieldValidators[event.target.name].validate(event.target.value)
            return {
                validFields: {...previous.validFields, ...newValidItems}
            }
        }, () => {
            setLocalStorageData("billingData", {[event.target.name]: event.target.value})
        })
    }

    handleAlternativeBillingAddressChange = this.fieldChangeHandler("alternativeBillingAddress")
    handleCardChange = this.fieldChangeHandler("card")

    handleLoginDataChange = (event, ...args) => {
        if (event.target.name === "password" || event.target.name === "passwordRepeat") {
            this.handlePasswordChange(event, ...args)
        } else if (event.target.name === "email"){
            this.fieldChangeHandler("loginData")(event)
            this.setState(previous => {
                let loginDataValid = previous.validFields.loginData

                loginDataValid["email"] = this.LoginDataFieldValidators["email"].validate(event.target.value)
                return {
                    validFields: {...previous.validFields, ...loginDataValid}
                }
            }, () => {
                setLocalStorageData("loginData", {[event.target.name]: event.target.value})
            })
        } else if (event.target.name === "securityNewsActive") {
            const eventClone = {...event}
            eventClone.target.value = event.target.checked
            this.fieldChangeHandler("loginData")({ target: { name: event.target.name, value: event.target.checked}})
            setLocalStorageData("loginData", {[event.target.name]: eventClone.target.value})
        }
    }


    handleLegalChange = (event) => {
        const fieldName = event.target.name

        this.setState(prevState => {
            return {legal: {...prevState.legal, [fieldName]: event.target.checked}}
        }, () => {
            setLocalStorageData("legalData", {[event.target.name]: event.target.checked})
        })
        
    }

    handlePasswordChange = (event, ...args) => {
        const {name, value} = event.target
        this.setState(prevState => {
            let loginData = prevState.fields.loginData
            let loginDataValid = prevState.validFields.loginData

            loginDataValid[name] = this.LoginDataFieldValidators[name].validate(value)
            loginData[name] = value

            if (name === "password") {
                loginDataValid["passwordRepeat"] = this.LoginDataFieldValidators["password"].validate(this.state.fields.loginData.password)
            }

            return {
                fields: {...prevState.fields, ...loginData},
                validFields: {...prevState.validFields, ...loginDataValid}
            }
        })
    }

    handlePaymentMethodToggle = event => {
        this.setState(prevState => {
            if (prevState.paymentMethod === PaymentMethods.CARD) {
                return {paymentMethod: PaymentMethods.INVOICE}
            } else {
                return {paymentMethod: PaymentMethods.CARD}
            }
        })
    }

    validateBillingAddress = async () => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields.billingAddress,
            this.BillingAddressFieldValidators
        )

        await this.setStateAsync({validFields: {...this.state.validFields, billingAddress: fieldValidity}})

        return isValid
    }


    validateAlternativeBillingAddress = async () => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields.alternativeBillingAddress,
            this.BillingAddressFieldValidators
        )

        await this.setStateAsync({
            validFields: {
                ...this.state.validFields,
                alternativeBillingAddress: fieldValidity
            }
        })

        return isValid
    }

    validateLoginData = async () => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields.loginData,
            this.LoginDataFieldValidators
        )

        await this.setStateAsync({validFields: {...this.state.validFields, loginData: fieldValidity}})

        return isValid
    }

    validateCard = async () => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields.card,
            this.CardFieldValidators
        )

        await this.setStateAsync({validFields: {...this.state.validFields, card: fieldValidity}})

        return isValid
    }

    validateTerms = async () => {

        const setFieldsValidation = (fieldName, value) => {
            this.setState(prevState => {
                const termsValid = prevState.validFields.legal
                return {
                    validFields: {...prevState.validFields, legal: {...termsValid, [fieldName]: !!value}}
                }
            })
        }

        const {t} = this.props
        const legal = this.state.legal

        const legalFields = [
            {
                translation: "termsAccepted",
                name: "greenboneTermsOfUseAccepted"
            },
            {
                translation: "termsOfUseAccepted",
                name: "greenbonePrivacyPolicyAccepted"
            },
            {
                translation: "termsAccepted",
                name: "vmspTermsOfUseAccepted"
            },
            {
                translation: "termsOfUseAccepted",
                name: "vmspPrivacyPolicyAccepted"
            },
        ]


        const invalidFields = legalFields.filter(field => {
            const isValid = !!legal[field.name]
            setFieldsValidation(field.name, isValid)
            return !isValid
        })

        invalidFields.forEach(field => {
            switch (field.translation) {
                case 'termsAccepted':
                    Snackbar.Error(t("extern.registration.RegisterContainer.termsAccepted"))
                    break;
                case 'termsOfUseAccepted':
                    Snackbar.Error(t("extern.registration.RegisterContainer.termsOfUseAccepted"))
                    break;
                default:
                    throw new Error('Translation not found')
            }
        })
        return invalidFields.length === 0
    }

    isFormValid = async () => {
        const {t} = this.props
        const {paymentMethod} = this.state
        let formIsValid = true

        let allFormAreasValid = [
            await this.validateBillingAddress(),
            await this.validateLoginData(),

            paymentMethod === PaymentMethods.NONE || paymentMethod === PaymentMethods.INVOICE || await this.validateCard()
        ]

        if (this.state.useAlternativeBillingAddress) {
            allFormAreasValid.push(await this.validateAlternativeBillingAddress())
        }

        if (!allFormAreasValid.every(formArea => {
            return formArea === true
        })) {
            Snackbar.Error(t("common.messages.error.wrongInput"))
            formIsValid = false
        }

        if (!this.validateTerms()) {
            formIsValid = false
        }

        if (!formIsValid) {
            const invalidFields = [
                ...Object.entries(this.state.validFields.billingAddress),
                ...Object.entries(this.state.validFields.loginData),
                ...Object.entries(this.state.validFields.legal),
            ].filter(([key, value]) => {
                return value !== true
            }).reduce((acc, [key, value]) => {
                
                acc[key] = null
                return acc
            }, {})
            this.setState({ fieldErrors: invalidFields })
        }

        return formIsValid
    }

    handleFormSubmit = async () => {

        if (this.state._sending) {
            return;
        }

        const {t, disabled} = this.props;

        [
            "slideIndex",
            "loginData",
            "billingData",
            "legalData"
        ].forEach(key => removeLocalStorageData(key))

        if (disabled) {
            return
        }

        const formIsValid = await this.isFormValid()
        if (formIsValid !== true) {
            return
        }

        if (this.state.paymentMethod === PaymentMethods.NONE
            || this.state.paymentMethod === PaymentMethods.INVOICE) {
            this.setState({_sending: true, sendingGuiState: sendingState.SENDING})
            return await this.createUser(undefined)
            
        }

        this.setState({_sending: true, sendingGuiState: sendingState.PAYMENTPENDING})
        const payFormRefSubmitResponse = await this.payFormRef.handleSubmit(this.state.fields.card.cardOwner)
        try {
            if (!payFormRefSubmitResponse || !payFormRefSubmitResponse.token) {
                Snackbar.Error(t("common.messages.error.correctInput"))
                this.setState({_sending: false, sendingGuiState: sendingState.NOTSENDING})
                return false
            }
            return await this.createUser(payFormRefSubmitResponse.token.id)

        } catch(error) {
            Snackbar.Error(t("common.messages.error.correctInput"))
            this.setState({_sending: false, sendingGuiState: sendingState.NOTSENDING})
        }

    }

    buildLegal = () => {
        const legal = this.state.legal
        const legalSettings = this.state.legalSettings

        if (legalSettings.termsOfUseEnabled && legalSettings.privacyPolicyEnabled) {
            return {
                termsOfUseAccepted: legal.greenboneTermsOfUseAccepted,
                vmspTermsOfUseAccepted: legal.vmspTermsOfUseAccepted,
                privacyPolicyAccepted: legal.greenbonePrivacyPolicyAccepted,
                vmspPrivacyPolicyAccepted: legal.vmspPrivacyPolicyAccepted
            }
        }

        if (legalSettings.termsOfUseEnabled === true && legalSettings.privacyPolicyEnabled === false) {
            return {
                termsOfUseAccepted: legal.greenboneTermsOfUseAccepted,
                vmspTermsOfUseAccepted: legal.vmspTermsOfUseAccepted,
                privacyPolicyAccepted: legal.greenbonePrivacyPolicyAccepted
            }
        }

        if (legalSettings.termsOfUseEnabled === false && legalSettings.privacyPolicyEnabled === true) {
            return {
                termsOfUseAccepted: legal.greenboneTermsOfUseAccepted,
                privacyPolicyAccepted: legal.greenbonePrivacyPolicyAccepted,
                vmspPrivacyPolicyAccepted: legal.vmspPrivacyPolicyAccepted
            }
        }


        return {
            termsOfUseAccepted: legal.greenboneTermsOfUseAccepted,
            privacyPolicyAccepted: legal.greenbonePrivacyPolicyAccepted
        }
    }

    createUser = async (stripeToken) => {
        const {t} = this.props
        try {
            const {plan, paymentMethod} = this.state
            if (!plan) {
                this.setState({sendingGuiState: sendingState.NOTSENDING})
                return
            }

            const {billingAddress, alternativeBillingAddress, loginData} = this.state.fields
            loginData.language = "en"

            const legalBuild = this.buildLegal()

            const userRegistrationDTO = {
                ...billingAddress,
                ...loginData,
                ...legalBuild,
                ...this.state.legal,
                passwordRepeat: undefined
            }

            if (this.state.useAlternativeBillingAddress) {
                userRegistrationDTO["billingInformation"] = {
                    billingAddress: {
                        ...alternativeBillingAddress
                    }
                }
            }

            this.setState({sendingGuiState: sendingState.SENDING})

            let response = null

            switch (paymentMethod) {
                case PaymentMethods.NONE:
                    const freeUserRegistrationDTO = {
                        ...loginData,
                        ...legalBuild,
                        ...billingAddress
                    }

                    response = await this.externUserRestApiClient.registerFreeUser(freeUserRegistrationDTO)
                    break

                case PaymentMethods.INVOICE:
                    const invoiceRegistrationDTO = {
                        ...userRegistrationDTO,
                        billingInformation: {
                            ...userRegistrationDTO.billingInformation,
                            planId: plan.id
                        }
                    }

                    response = await this.externUserRestApiClient.registerInvoiceUser(invoiceRegistrationDTO)
                    break

                case PaymentMethods.CARD:
                    const creditCardRegistrationDTO = {
                        ...userRegistrationDTO,
                        billingInformation: {
                            ...userRegistrationDTO.billingInformation,
                            cardToken: stripeToken,
                            planId: plan.id
                        }
                    }

                    response = await this.externUserRestApiClient.registerUser(creditCardRegistrationDTO)
                    break

                default:
                    this.setState({_sending: false})
                    Snackbar.Error(t("extern.registration.RegisterContainer.error.invalidPlanPaymentCombination"))
                    return
            }

            if (response.status === 400) {
                this.setState({_sending: false})
                this.setState({sendingGuiState: sendingState.NOTSENDING})
                const json = await response.json()
                
                this.setState(prevState => {
                    let validFields = prevState.validFields
                    validFields.loginData = {...validFields.loginData, ...json.fieldErrors}
                    validFields.billingAddress = {...validFields.billingAddress, ...json.fieldErrors}
                    
                    return {validFields, fieldErrors: json.fieldErrors}
                })
                
                return
            }
            
            this.setState({sendingGuiState: sendingState.NOTSENDING})
            return true
            
        } catch(exception) {
            this.setState({sendingGuiState: sendingState.NOTSENDING})

            Snackbar.Error(t("extern.registration.RegisterContainer.error.canNotReachService"))

            this.setState({_sending: false})
        }
    }

    toggleUsageOfAlternativeBillingAddress = (event) => {
        this.setState(prevState => {
            return {useAlternativeBillingAddress: !prevState.useAlternativeBillingAddress}
        })
    }

    render() {
        const {paymentMethod} = this.state
        if (!this.state.plan) {
            return null
        }

        if (this.state._loading) {
            return <Spinner/>
        }

        if (paymentMethod === PaymentMethods.NONE) {
            return (
                <div style={{height: "100%", position: "relative"}}>
                    <RegisterFreeComponent
                        countries={this.props.countries}
                        legalSettings={this.state.legalSettings}
                        disabled={this.props.disabled}
                        handleSubmit={this.handleFormSubmit}
                        plan={this.state.plan}
                        billingAddress={this.state.fields.billingAddress}
                        billingAddressValid={this.state.validFields.billingAddress}
                        handleBillingAddressChange={this.handleBillingAddressChange}
                        loginData={this.state.fields.loginData}
                        handleLoginDataChange={this.handleLoginDataChange}
                        loginDataValid={this.state.validFields.loginData}
                        legal={this.state.legal}
                        legalValid={this.state.validFields.legal}
                        termsValid={this.state.validFields.terms}
                        handleLegalChange={this.handleLegalChange}
                        validateLoginData={this.validateLoginData}
                        validateTerms={this.validateTerms}
                        validateBillingAddress={this.validateBillingAddress}
                        sendingGuiState={this.state.sendingGuiState}
                        _sending={this.state._sending}
                        fieldErrors={this.state.fieldErrors}
                    />
                </div>
            )
        }
    }
}


export const RegisterContainer =
    compose(withTranslation())(_RegisterContainer)
