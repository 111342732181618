import React from "react"
import {Route, Routes} from "react-router-dom"
import {ErrorBoundary} from "../../Components/ErrorBoundary"
import {ValidateHost} from "./ValidateHost"


export function HostValidationLayout(props) {

    return <ErrorBoundary>
        <Routes>
            <Route path="/validate" Component={ValidateHost}/>
            <Route path="*" element={<div>Not Found</div>}/>
        </Routes>
    </ErrorBoundary>

}
