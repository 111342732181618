import React from "react"
import {Button, FlexRow, Subheadline} from "@greenbone/cloud-component-library"
import {StyledPaper} from "./Layout"
import {SuccessIcon} from "./SuccessIcon"

export function SuccessPaperWithMessageAndButton({headline, text, redirect, redirectButton, children, ...props}) {

    return (
        <>
            <StyledPaper {...props}>
                <Subheadline style={{marginBottom: "2rem"}}>
                    {headline}
                </Subheadline>

                <div style={{marginBottom: "5rem"}}>
                    {children}
                </div>

                <SuccessIcon successMessage={text}/>

                <FlexRow justifyContent={"flex-end"}>
                        <a href={redirect}>
                            <Button>
                                {redirectButton}
                            </Button>
                        </a>
                </FlexRow>
            </StyledPaper>
        </>
    )
}
