import {useTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import {Paper} from "../../Components/Layout"
import {FlexRow, GhostButton} from "@greenbone/cloud-component-library"
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom"
import React, {useContext} from "react"
import {VmspPrivacyPolicy} from "./VmspPrivacyPolicy"
import {GreenbonePrivacyPolicy} from "./GreenbonePrivacyPolicy"
import {LegalContext} from "../../Context/LegalContext"
import {ApplicationUrlProvider} from "../../Layout/ApplicationUrlProvider"
import {ActiveGhostButton} from "../../Components/ActiveGhostButton"

export function PrivacyPolicyPage() {
    const {t} = useTranslation()

    const {privacyPolicyEnabled} = useContext(LegalContext)
    const {pathname} = useLocation()

    return <Row className={"justify-content-center"} style={{marginTop: "4rem", marginBottom: "4rem"}}>
        <Col xs={8}>
            <Paper>
                <FlexRow justifyContent={"space-between"}>
                    <a href="/ui">
                        <GhostButton style={{marginTop: 0}}>{t("back.to.login")}</GhostButton>
                    </a>


                    <FlexRow>
                        <Link to={ApplicationUrlProvider.privacyPolicyProviders.greenbone}>
                            <ActiveGhostButton active={pathname.endsWith("greenbone")}
                                               style={{marginTop: 0, marginRight: "1rem"}}>Greenbone</ActiveGhostButton>
                        </Link>
                        {privacyPolicyEnabled && <Link to={ApplicationUrlProvider.privacyPolicyProviders.vmsp}>
                            <ActiveGhostButton active={pathname.endsWith("vmsp")}
                                               style={{marginTop: 0}}>Partner</ActiveGhostButton>
                        </Link>}

                    </FlexRow>
                </FlexRow>

                <Routes>
                    <Route path="/greenbone"
                           Component={GreenbonePrivacyPolicy}/>
                    {privacyPolicyEnabled &&
                    <Route path="/vmsp" Component={VmspPrivacyPolicy}/>}

                    <Route
                        path="/"
                        element={<Navigate to="greenbone"/>}
                    />
                </Routes>
            </Paper>
        </Col>
    </Row>
}
