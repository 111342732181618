import React from "react"
import {Route, Routes} from "react-router-dom"
import {ApplicationUrlProvider} from "./ApplicationUrlProvider"
import {ApplicationLayout} from "./ApplicationLayout"
import {PasswordLayout} from "../areas/password/PasswordLayout"
import {MailLayout} from "../areas/mail/MailLayout"
import {ImprintPage} from "../areas/imprint/ImprintPage"
import {PrivacyPolicyPage} from "../areas/privacy/PrivacyPolicyPage"
import {HostValidationLayout} from "../areas/hostvalidation/HostValidationLayout"
import {TeamLayout} from "../areas/team/TeamLayout"
import {MSPLayout} from "../areas/msp/MSPLayout"
import {RegisterPage} from "../areas/register/RegisterPage"
import {PageNotFound} from "../PageNotFound"
import {TermsOfUsePage} from "../areas/tos/TermsOfUsePage"

const withLayout = (Component) => (routeProps) => <ApplicationLayout><Component {...routeProps}/></ApplicationLayout>

export const ApplicationRouter = () => (
    <Routes>
        <Route path="/msp/*" Component={withLayout(MSPLayout)}/>
        <Route path="/team/*" Component={withLayout(TeamLayout)}/>
        <Route path="/mail/*" Component={withLayout(MailLayout)}/>
        <Route path="/password/*" Component={withLayout(PasswordLayout)}/>
        <Route path="/imprint" Component={withLayout(ImprintPage)}/>
        <Route path="/privacy/*" Component={withLayout(PrivacyPolicyPage)}/>
        <Route path="/tos/*" Component={withLayout(TermsOfUsePage)}/>
        <Route path="/hostvalidation/*" Component={withLayout(HostValidationLayout)}/>
        <Route path={ApplicationUrlProvider.register()} Component={RegisterPage}/>
        <Route path="*" Component={PageNotFound}/>
    </Routes>
);
