import * as React from "react"
import {useTranslation} from "react-i18next"
import {isPasswordStrongEnough} from "./areas/password/PasswordInput"
import {Validator} from "./Validator"


const MessageComponent = (props) => {
    const {t} = useTranslation()
    return <>{t("validation.inputInvalid")}</>
}

class FormFieldValidator {


    message = <MessageComponent/>;

    constructor(message) {
        if (message) {
            this.message = message;
        }
    }


    isValid(value) {
        return !!value;
    };

    validate(value) {
        return this.isValid(value) || this.message;
    }
}

export class IsNotEmptyString extends FormFieldValidator {
    MessageComponent (props) {
        // eslint-disable-next-line
        const {t} = useTranslation();
        return <>{t("validation.fieldNotEmpty")}</>;
    };

    constructor() {
        super()
        this.message = <this.MessageComponent/>;
    }

    
    validate(value) {
        if (value && value !== "") {
            return true;
        }
        return this.message;
    }
}

export class IsNotEmptyList extends FormFieldValidator {
    message = <this.MessageComponent/>;

    MessageComponent = (props) => {
        // eslint-disable-next-line
        const {t} = useTranslation();
        return <>{t("validation.choiceNotEmpty")}</>;
    };

    validate(value) {
        if (value && value.length > 0) {
            return true;
        }
        return this.message;
    }
}

export class IsInteger extends FormFieldValidator {
    message = <this.MessageComponent/>;

    MessageComponent = (props) => {
        // eslint-disable-next-line
        const {t} = useTranslation();
        return <>{t("validation.unsignedInt")}</>;
    };

    validate(value) {
        const number = Number.parseInt(value);
        if (Number.isInteger(number)) {
            return true;
        }

        return this.message;
    }
}

export class IsOptionalString extends FormFieldValidator {
    validate(value) {
        return true;
    }
}

export class IsEmail extends FormFieldValidator {
    isValid(value) {
        if (value) {
            return Validator.isEMail(value);
        }
        return false;
    }
}

export class CustomCallback extends FormFieldValidator {
    callback = null;

    constructor(message, callback) {
        super(message);
        this.callback = callback;
    }

    isValid(value) {
        if (this.callback) {
            return this.callback(value);
        }
        return false;
    }
}

export class CustomValidationMethod extends FormFieldValidator {
    callback = null;

    constructor(message, callback) {
        super(message);
        this.callback = callback;
    }

    validate(value) {
        if (this.callback) {
            return this.callback(value);
        }
        return false;
    }
}

export class IsPasswordStrong extends FormFieldValidator {
    isValid(value) {
        return isPasswordStrongEnough(value);
    }
}
