import styled from "styled-components"
import {Colors} from "../../Theme"

export const Paper = styled.div`
  width: 100%;

  background: white;
  padding: 2rem;
  box-shadow: 0px 14px 22px #00000029;
`
export const StyledPaper = styled(Paper)`
  max-width: 32rem;
  padding: 4rem;
  
  @media (max-width: 500px){
    padding: 2rem;
  }
`
export const Hr = styled.div`
  width: 100%;
  border: 1px solid ${Colors.grey.light};
`;
