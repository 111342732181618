import {rest} from "msw"


export const manualServiceHandlers = [
    rest.get(("/ui/manual/legal/de/terms.html"), (request, response, context) => {

        return response(
            context.status(200),
            context.text(html)
        )
    }),

    rest.get(("/ui/manual/legal/de/privacy.html"), (req, res, context) => {
        return res(
            context.status(200),
            context.text(`<h1>test</h1>`)
        )
    })


]


const html = `<style>
    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    ol > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

    ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    li ol > li {
        margin: 0;
    }

    li ol > li:before {
        content: counters(item, ".") " ";
    }
</style>


<h1>Allgemeine Nutzungsbedingungen für Greenbone Cloud Services</h1>


<ol>
    <li>Geltungsbereich
        <ol>
            <li>Die nachfolgenden Allgemeinen Nutzungsbedingungen (im Folgenden „Nutzungsbedingungen“) enthalten die
                grundlegenden Regeln für die Nutzung der Greenbone Cloud Services (im Folgenden „GCS“) der Greenbone
                Networks AG (im Folgenden „Greenbone“).
            </li>
            <li>Werden diese Nutzungsbedingungen einer Vereinbarung über die Nutzung GCS-basierter Leistungen zwischen
                dem Nutzer und einem von Greenbone verschiedenen Dritten zugrunde gelegt, gelten diese
                Nutzungsbedingungen als zwischen dem Nutzer und dem Dritten vereinbart. Als „Greenbone“ im Sinne dieser
                Nutzungsbedingungen gilt dann der betreffende Dritte, soweit sich aus dem Zusammenhang nicht ergibt, das
                die Greenbone AG als faktischer Betreiber des GCS gemeint ist.
            </li>
            <li>Von diesen Nutzungsbedingungen abweichende Geschäftsbedingungen des Nutzers finden keine Anwendung,
                sofern ihre Geltung von Greenbone nicht ausdrücklich bestätigt worden ist.
            </li>
            <li>Eine Nutzung der GCS ist ausschließlich Unternehmen i. S. d. § 14 BGB möglich.</li>
        </ol>
    </li>
    <li>
        Vertragsgegenstand, Begriffsbestimmungen
        <ol>
            <li>Greenbone ist ein Anbieter von IT-Lösungen für das Schwachstellen-Management von Computernetzen. Mit den
                „Greenbone Cloud Services“ verfügt Greenbone über eine cloudbasierte Softwarelösung, die unter Rückgriff
                auf das von Greenbone betriebene Greenbone Scan Cluster Computernetze sowohl intern als auch extern nach
                Schwachstellen scannt, erkannte Schwachstellen aufdeckt und Handlungsempfehlungen in Bezug auf die
                erkannten Schwachstellen zur Verbesserung der IT-Sicherheit gibt.
            </li>
            <li>„Plattform“ oder „GCS“ im Sinne dieser Nutzungsbedingungen ist die von Greenbone betriebene,
                cloudbasierte Softwareanwendung, die unter Rückgriff auf das von Greenbone betriebene Greenbone Scan
                Cluster Computernetze der Nutzer sowohl intern als auch extern nach Schwachstellen scannt und die über
                passwortgeschützte Nutzerbereiche verfügt.
            </li>
            <li>„GSC“ im Sinne dieser Nutzungsbedingungen ist das von Greenbone betriebene Greenbone Scan Cluster, mit
                dessen Hilfe die Scans der Computernetze der Nutzer durchgeführt werden.
            </li>
            <li>„GCS-basierte Leistungen“ im Sinne dieser Nutzungsbedingungen sind über die Plattform administrierbare,
                in der Regel kostenpflichtige Nutzungskontingente für die Durchführung von Scans von Computernetzwerken
                zum Zwecke der Aufdeckung von Schwachstellen oder zum Zwecke bestimmter Analysen der entsprechenden
                Computernetzwerke.
            </li>
            <li>„Nutzer“ im Sinne dieser Nutzungsbedingungen ist jede natürliche oder juristische Person oder
                Personenvereinigung, die ein Computernetzwerk betreibt und GCS-basierte Leistungen zum Zwecke der
                regelmäßigen Überprüfung ihres Computernetzwerkes erwirbt.
            </li>
            <li>„Schadensgeneigte Scan-Techniken“ im Sinne dieser Nutzungsbedingungen sind aggressive Formen von
                Netzwerk-Scans, insbesondere Penetrationstests, die in bestimmten Fällen zu einer temporären oder
                dauerhaften Beeinträchtigung oder Beschädigungen der Systeme des Nutzers oder zu einem Verlust von Daten
                führen können.
            </li>
            <li>„Tarif“ in Sinne dieser Vereinbarung ist jeweils ein konkretes Subscription-Modell, das ein bestimmtes
                Kontingent an GCS-basierten Leistungen pro Monat zu einem festgelegten Preis pro Monat umfasst.
            </li>
        </ol>
    </li>
    <li>
        Leistungen von Greenbone, Sicherheitshinweis
        <ol>
            <li>
                Die GCS-basierten Leistungen dienen als Managed Service einer Schwachstellenanalyse von
                IT-Infrastrukturen. Sie ermöglichen es dem Nutzer, ausgewählte IP-Adressen und IP-Adressbereiche auf
                Schwachstellen zu überprüfen. In Abhängigkeit von dem vereinbarten Leistungspaket umfassen die
                GCS-basierten Leistungen Folgendes:
                <ul>
                    <li>Scan von öffentlichen IP-Adressen des Nutzers,</li>
                    <li>Scan von internen IP-Adressen des Nutzers über ein Gateway,</li>
                    <li>Verwaltung der Konfiguration von Scans,</li>
                    <li>Auswertung der Scan-Ergebnisse.</li>
                </ul>
            </li>
            <li>
                Dem Nutzer ist bekannt, dass das Scannen von IT-Systemen unabhängig von der Scan-Tiefe zu Störungen der
                gescannten Systeme und darauf laufender Dienste bis hin zu einem Totalausfall führen kann. GCS-basierte
                Leistungen sollten daher insbesondere auf kritischen Systemen, deren Ausfall zu Gefahren für Leib und
                Leben oder für Vermögenwerte von erheblichem Umfang führen kann, nur nach vorheriger Risikoabschätzung
                und unter Aufsicht von geschultem Fachpersonal angewendet werden.
            </li>
        </ol>
    </li>
    <li>
        Registrierung, Nutzungskonto
        <ol>
            <li>Die GCS-basierten Leistungen werden jeweils in Form von Subscription-Modellen auf Basis von monatlichen
                Kontingenten an „lebenden IP-Adressen“ angeboten. Die Bereitstellung der Plattform durch Greenbone
                erfolgt durch die Gewährung von Zugriffsmöglichkeiten auf den von Greenbone genutzten Server mit der
                Plattform-Software.
            </li>
            <li>Die Nutzung der Plattform ist ausschließlich Nutzern gestattet, die bei Abschluss der
                Nutzungsvereinbarung in Ausübung einer gewerblichen oder selbständigen beruflichen Tätigkeit handeln.
            </li>
            <li>Die Nutzung GCS-basierter Leistungen setzt eine Registrierung des Nutzers bei Greenbone voraus. Hierzu
                muss sich der Nutzer auf der Plattform anmelden. Der Nutzer ist verpflichtet, die bei der Registrierung
                erhobenen Daten wahrheitsgemäß und vollständig anzugeben. Bei einer nachträglichen Änderung der
                erhobenen Daten hat der Nutzer die betreffenden Angaben in seinem Nutzerkonto unverzüglich zu
                aktualisieren oder - soweit dies nicht möglich ist - Greenbone unverzüglich die Änderungen mitzuteilen.
            </li>
            <li>Akzeptiert Greenbone die Registrierung, wird für den Nutzer ein Nutzerkonto auf der Plattform
                eingerichtet. Das Nutzerkonto ist mit dem Benutzernamen und einem individuellen Passwort des Nutzers (im
                Folgenden „Zugangsdaten“) verknüpft. Der Zugang des Nutzers zur Plattform ist nur mit Hilfe der
                Zugangsdaten möglich. Der Nutzer hat die Zugangsdaten geheim zu halten und vor dem Zugriff durch
                unbefugte Dritte geschützt aufzubewahren. Sind dem Nutzer die Zugangsdaten abhandengekommen oder stellt
                er fest oder hegt er den Verdacht, dass seine Zugangsdaten von einem Dritten genutzt werden, hat er dies
                Greenbone unverzüglich mitzuteilen.
            </li>
        </ol>
    </li>
    <li>
        Nutzungsvoraussetzungen für GCS-basierte Leistungen
        <ol>
            <li>Der Zugriff auf die Plattform und die Administrierung der GCS-basierten Leistungen setzt grundsätzlich
                einen Computer mit Internetzugang und eine gängige und funktionsfähige Browser-Software voraus.
            </li>
            <li>Die Nutzung der GCS-basierten Leistungen für Scans eigener Netzwerke des Nutzers setzt ggf. die
                Bereitstellung eines Gateway-Servers durch den Nutzer voraus, der eine Verbindung zwischen dem Netzwerk
                des Nutzers und dem Internet ermöglicht, auf dem bis zu 2 GB freier Speicherplatz zur Verfügung stehen
                und auf dem eine für die Durchführung des Scans erforderliche Software von Greenbone installiert werden
                kann.
            </li>
            <li>Die Nutzung der GCS-basierten Leistungen setzt ferner voraus, dass die für die Scans erforderliche
                Software von Greenbone mit Administratorrechten ausgeführt werden kann. Entsprechende,
                Administratorrechte vermittelnde Zugangsdaten zum Netz des Nutzers müssen im Nutzerkonto auf der
                Plattform hinterlegt werden.
            </li>
            <li>Sollen mit Hilfe GCS-basierter Leistungen unter externen IP-Adressen erreichbare Computer untersucht
                werden, setzt dies jeweils eine Host-Validierung für die jeweilige externe IP-Adresse voraus. Diese
                erfolgt entweder automatisiert über ein Auslesen der RIPE-Datenbank oder, wenn darüber eine Zuordnung zu
                dem Nutzer nicht möglich ist, manuell.
            </li>
            <li>Vor der Ausführung von schadensgeneigten Scan-Techniken muss der Nutzer ggf. eine gesonderte
                Einwilligung in die Anwendung entsprechender Techniken gegenüber Greenbone erklären und ggf. einem
                erweiterten Haftungsausschluss zustimmen.
            </li>
        </ol>
    </li>
    <li>
        Anpassung und Beendigung des Vertrages
        <ol>
            <li>Der Nutzer hat die Möglichkeit, jeweils mit Wirkung zum Ende eines Kalendermonats eine Änderung des
                gewählten Tarifs in Form eines Downgrades vorzunehmen. Ein Upgrade kann jederzeit mit sofortiger Wirkung
                vorgenommen werden. Für die gewünschte Änderung muss der Nutzer im Nutzerbereich der Plattform die
                gewünschte Tarifänderung in Form einer Verringerung oder Erhöhung der gewünschten Anzahl an IP-Adressen
                einstellen. Die von einem Nutzer auf der Plattform eingestellte Tarifänderung stellt ein Angebot
                gegenüber Greenbone auf Anpassung des betreffenden Einzelvertrages mit Wirkung ab dem folgenden
                Kalendermonat (Downgrade) bzw. mit sofortiger Wirkung (Upgrade) dar. Greenbone kann das Angebot auf
                Änderung des Einzelvertrages durch die Freischaltung der umfassten weiteren Leistungen bzw. die
                Beschränkung der verfügbaren Leistungen oder durch die Übermittlung einer Änderungsbestätigung gegenüber
                dem Nutzer annehmen. Mit Annahme des Angebotes wird der Vertrag zu den geänderten Bedingungen
                fortgeführt.
            </li>
            <li>Der Nutzer hat ggf. die Möglichkeit, den Vertrag über eine hierzu vorgesehene Funktion auf der Plattform
                („Terminate Button“) zu kündigen. Möchte der Nutzer diese Funktion nicht nutzen, bedarf die Kündigung
                der Textform. Die Kündigung ist nicht an eine Frist gebunden, wirkt jedoch nur zum Ende des jeweils
                laufenden Kalendermonats und frühesten zum Ende des ersten vollen Kalendermonats der Vertragslaufzeit.
                Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
            </li>
        </ol>
    </li>
    <li>
        Pflichten von Greenbone
        <ol>
            <li>Greenbone ist verpflichtet, dem Nutzer den vereinbarten Zugang zur Plattform bereitzustellen sowie die
                vereinbarten GCS-basierten Leistungen auszuführen. Der geschuldete Leistungsumfang ist dabei jeweils
                abhängig von der beauftragten Leistung und den Einstellungen durch den Nutzer.
            </li>
            <li>Im Falle der Inanspruchnahme einer GCS-basierten Leistung wird der beauftragte Scanvorgang innerhalb von
                24 Stunden nach erfolgter Beauftragung bzw. programmiertem Start begonnen. Es können nur Systeme
                gescannt werden, die zum Zeitpunkt des Scanvorgangs betriebsbereit und über das Internet und das hierzu
                eingerichtete Gateway erreichbar sind und die nicht so konfiguriert sind, dass die durch das GSC
                initiierten Scans abgeblockt werden.
            </li>
            <li>Im Rahmen der Ausführung der GCS-basierten Leistungen werden jeweils nur während des Scans tatsächlich
                als erreichbar identifizierte IP-Adressen auf das vereinbarte Leistungskontingent angerechnet.
            </li>
            <li>Greenbone ist verpflichtet, die von dem GSC bzw. bei der Inanspruchnahme von GCS-basierten Leistungen
                ausgelösten Nachrichten, z. B. Hinweise auf festgestellte Schwachstellen, auf der Plattform für den
                Nutzer zum Abruf bereitzustellen. Darüber hinaus ist Greenbone verpflichtet, den Nutzer gemäß der
                jeweils eingestellten Konfiguration ggf. per E-Mail darüber zu informieren, dass eine neue Nachricht für
                ihn bereitsteht.
            </li>
            <li>Greenbone wird sich darum bemühen, das GSC laufend fortzuentwickeln und aktuell zu halten, damit mit
                Hilfe der GCS-basierten Leistungen möglichst viele bekannte oder potentielle Schwachstellen der
                gescannten Computernetze aufgedeckt werden können. Es ist jedoch technisch nicht möglich, einen Scanner
                zu entwickeln und zu betreiben, der stets sämtliche bekannten und potentiellen Schwachstellen beliebiger
                Computernetzwerke identifizieren kann. Es besteht daher kein Anspruch darauf, dass mit Hilfe der
                GCS-basierten Leistungen sämtliche Schwachstellen erkannt werden. Eine fortlaufend aktualisierte
                Übersicht über die von dem GSC berücksichtigten Schwachstellen ist auf der Website von Greenbone
                einsehbar.
            </li>
            <li>Greenbone ist verpflichtet, die Plattform und das GSC während der Vertragslaufzeit in einem
                funktionsfähigen Zustand zu halten und hierzu auch fortzuentwickeln, soweit dies zur Herstellung oder
                Aufrechterhaltung der Funktionsfähigkeit erforderlich ist.
            </li>
        </ol>
    </li>
    <li>
        Verfügbarkeit, Wartung
        <ol>
            <li>Greenbone bemüht sich um eine durchgehende Verfügbarkeit und fehlerfreie Funktionalität der Plattform
                und des GSC. Der Nutzer erkennt jedoch an, dass bereits aus technischen Gründen und aufgrund der
                Abhängigkeit von äußeren Einflüssen z. B. im Rahmen der Fernmeldenetze eine ununterbrochene
                Verfügbarkeit nicht realisierbar ist. Greenbone sichert eine Verfügbarkeit der Plattform von 97 % im
                Monatsmittel, bezogen auf den jeweiligen Kalendermonat, zu. Bei der Berechnung etwaiger Ausfallzeiten
                werden angekündigte Wartungszeiten mit einer Gesamtdauer von max. 5 Stunden pro Monat nicht
                eingerechnet.
            </li>
            <li>Greenbone führt an den Systemen der Plattform und des GSC zur Sicherstellung des Betriebes und zum
                Zwecke der Erweiterung der Plattform gelegentlich Wartungsarbeiten durch, die zu einer vorübergehenden
                Beeinträchtigung der Nutzbarkeit führen können. Greenbone wird die Wartungsarbeiten, sofern möglich, in
                nutzungsarmen Zeiten durchführen. Planbare Arbeiten, die absehbar eine Unterbrechung des normalen
                Betriebs erfordern, werden dem Nutzer min. 48 Stunden im Voraus per E-Mail angekündigt. Dabei wird
                Greenbone den Nutzer, soweit absehbar, über Art, Ausmaß und Dauer der Beeinträchtigung unterrichten.
            </li>
            <li>estgestellte oder von dem Nutzer gemeldete Fehler sowie Störungen der Plattform oder des GSC
                (nachfolgend zusammen „Störungen“) wird Greenbone analysieren und in angemessener Frist beseitigen.
                Sofern nicht anders vereinbart, ist Greenbone jeweils werktags in der Zeit zwischen 09:00 Uhr und 17:00
                Uhr deutscher Zeit (nachfolgend „Servicezeit“) für die Entgegennahme von Störungsmeldungen per E-Mail
                (support@greenbonde.net) und Telefon erreichbar.
            </li>
            <li>
                Greenbone ist verpflichtet, mit der Analyse und Beseitigung von Störungen der Plattform oder des GSC
                innerhalb der folgenden Reaktionszeiten zu beginnen:
                <ul>
                    <li>Im Falle einer Störung, die den Einsatz der Plattform oder des GSC oder einer wesentlichen
                        Funktion der Plattform verhindert, (Störungsklasse 1) beträgt die Reaktionszeit drei Stunden,
                        nachdem die Störung innerhalb der Servicezeit gemeldet wurde. Greenbone setzt die Arbeiten bis
                        zur Beseitigung der Störung auch außerhalb der Servicezeit fort.
                    </li>
                    <li>Im Falle einer Störung, die den Einsatz der der Plattform oder des GSC oder einer wesentlichen
                        Funktion der Plattform nicht unwesentlich behindert, jedoch nicht verhindert, (Störungsklasse 2)
                        beträgt die Reaktionszeit sechs Stunden, nachdem die Störung innerhalb der Servicezeit gemeldet
                        wurde. Greenbone setzt die Arbeiten bis zur Beseitigung der Störung auch außerhalb der
                        Servicezeit fort.
                    </li>
                    <li>Bei allen übrigen Störungen (Störungsklasse 3) beginnt Greenbone mit deren Behebung spätestens
                        am nächsten Werktag und setzt die Arbeiten bis zur Beseitigung der Störung innerhalb der
                        Servicezeit fort.
                    </li>
                </ul>
                Die Reaktionszeit ist jeweils gewahrt, wenn Greenbone binnen der vorgesehenen Zeit mit den Arbeiten zur
                Analyse und Beseitigung der Störung begonnen hat.
            </li>
        </ol>
    </li>
    <li>Pflichten des Nutzers
        <ol>
            <li>Der Nutzer ist verpflichtet, alle Handlungen zu unterlassen, die über eine bestimmungsgemäße Nutzung der
                Plattform hinausgehen. Er ist insbesondere verpflichtet, es zu unterlassen, die Plattform in einer Art
                und Weise zu benutzen, durch die ein sicherer Betrieb der Systeme von Greenbone oder Dritter gefährdet
                werden könnte, z. B. indem er die Infrastruktur von Greenbone einer übermäßigen Belastung aussetzt oder
                auf andere Weise das Funktionieren der Plattform stört oder gefährdet.
            </li>
        </ol>
    </li>
    <li>
        Vergütung, Zahlungsbedingungen
        <ol>
            <li>Der Nutzer ist verpflichtet, an Greenbone die vereinbarte Vergütung zu zahlen, deren Höhe sich nach dem
                vereinbarten Leistungspaket richtet. Im Falle einer Anpassung des Vertrages gemäß Ziffer 5 ändert sich
                die Höhe der Greenbone zustehenden Vergütung entsprechend der Änderung des vereinbarten
                Leistungsumfangs.
            </li>
            <li>Ist die Zahlung einer Einrichtungsgebühr vereinbart, wird diese mit Abschluss des Vertrages zur Zahlung
                fällig. Die Abrechnung der Vergütung für GCS-basierte Leistungen erfolgt im Übrigen jeweils zu Beginn
                eines jeden Kalendermonats für den jeweils zurückliegenden Kalendermonat. Fällt der Vertragsbeginn nicht
                auf den Monatsanfang oder erfolgt während eines Monats ein Upgrade des Vertrages, wird jeweils nur die
                Anzahl der Tage berechnet, für die der Vertrag über das jeweilige Leistungskontingent bestand, wobei der
                Tag des Vertragsschlusses bzw. des Upgrades als vergütungspflichtig mitgezählt wird. Hat der Nutzer zum
                Ersten eines Monats z. B. einen Vertrag über ein Kontingent von 50 IP-Adressen und erhöht er dieses
                Kontingent am 10. des Monats und hat der Abrechnungsmonat 30 Tage, wird dem Nutzer für 9 Tage der
                anteilige Preis für das ursprüngliche Leistungskontingent und für 21 Tage der anteilige Preis für das
                erhöhte Leistungskontingent berechnet.
            </li>
            <li>Der Nutzer erklärt sich damit einverstanden, dass an ihn gerichtete Rechnungen nach Wahl von Greenbone
                in elektronischer Form auch per E-Mail an die von dem Nutzer angegebene E-Mail-Adresse oder durch
                Hinterlegung auf der Plattform übermittelt werden können.
            </li>
        </ol>
    </li>
    <li>
        Nutzungsrechte
        <ol>
            <li>Greenbone räumt dem Nutzer für die Dauer der Vereinbarung das Recht ein, die Plattform in dem
                vertraglich vereinbarten Umfang zu nutzen und dazu, soweit dies für die vereinbarte Nutzung erforderlich
                ist, insbesondere auf die entsprechende Software zuzugreifen, diese zu vervielfältigen, ablaufen zu
                lassen und sonst zu nutzen.
            </li>
        </ol>
    </li>
    <li>
        Gewährleistung
        <ol>
            <li>Greenbone gewährleistet, dass bei der Ausführung der GCS-basierten Leistungen jeweils ein Scan des
                ausgewählten Computernetzwerkes mit der jeweils aktuellsten Live-Version des GSC durchgeführt wird.
                Dabei können nur Systeme gescannt werden, die zum Zeitpunkt des Scanvorgangs betriebsbereit und über das
                Internet und das hierzu eingerichtete Gateway erreichbar sind. Greenbone gewährleistet ferner, dass der
                Nutzer jeweils gemäß den von ihm vorgenommenen Einstellungen über relevante Ergebnisse eines
                beauftragten Scanvorgangs durch Hinterlegung von Mitteilungen auf der Plattform informiert wird.
            </li>
            <li>Greenbone gewährleistet nicht, dass im Rahmen eines Scanvorgangs bestimmte bekannte oder potentielle
                Schwachstellen der gescannten Systeme verlässlich erkannt und an den Nutzern berichtet werden.
            </li>
        </ol>
    </li>
    <li>
        Haftung
        <ol>
            <li>Greenbone haftet für Schäden des Nutzers nach den gesetzlichen Bestimmungen, sofern die Schäden
                vorsätzlich oder grob fahrlässig verursacht wurden, sie die Folge des Nichtvorhandenseins einer
                garantierten Beschaffenheit der Leistung sind, sie auf einer schuldhaften Verletzung wesentlicher
                Vertragspflichten (siehe Ziffer 15.2) beruhen, sie die Folge einer schuldhaften Verletzung der
                Gesundheit, des Körpers oder des Lebens sind, oder für die eine Haftung nach dem Produkthaftungsgesetz
                vorgesehen ist. Im Falle einer lediglich fahrlässigen Verletzung einer wesentlichen Vertragspflicht
                (siehe Ziffer 15.2) ist die Haftung von Greenbone jedoch beschränkt auf solche Schäden, mit deren
                Entstehung im Rahmen der Erbringung der vereinbarten Leistungen typischerweise und vorhersehbar
                gerechnet werden muss. Diese Beschränkung gilt nicht, soweit Schäden die Folge einer Verletzung der
                Gesundheit, des Körpers oder des Lebens sind.
            </li>
            <li>Wesentliche Vertragspflichten sind solche vertragliche Pflichten, deren Erfüllung die ordnungsgemäße
                Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der Nutzer regelmäßig
                vertrauen darf, und deren Verletzung auf der anderen Seite die Erreichung des Vertragszwecks gefährdet.
            </li>
            <li>Im Übrigen ist die Haftung von Greenbone unabhängig vom Rechtsgrund ausgeschlossen.</li>
            <li>Haftet Greenbone unter Berücksichtigung der vorstehenden Regelungen für den Verlust von Daten des
                Nutzers, ist die Haftung auf den typischen Wiederherstellungsaufwand beschränkt, der auch bei
                regelmäßiger und gefahrentsprechender Anfertigung von Sicherungskopien durch den Nutzer eingetreten
                wäre.
            </li>
            <li>Eine schuldhafte Pflichtverletzung von Greenbone liegt nicht vor, wenn infolge der Nutzung GCS-basierter
                Leistungen durch einen Nutzer Störungen an oder Ausfälle von Systemen oder Diensten des Nutzers
                verursacht werden, soweit die Störung oder der Ausfall die Folge des Einsatzes einer Scan-Technik ist,
                die dem Stand der Technik für entsprechende Scans bzw. Schwachstellenanalysen entspricht.
            </li>
            <li>Hat ein Nutzer in die Ausführung von schadensgeneigten Scan-Techniken eingewilligt, haftet Greenbone
                nicht für Schäden an den auftragsgemäß gescannten Computersystemen oder für den Verlust von Daten,
                sofern es sich bei den betreffenden Schäden um typische Folgen einer entsprechenden schadensgeneigten
                Scan-Technik handelt.
            </li>
        </ol>
    </li>
    <li>
        Geheimhaltung
        <ol>
            <li>Die Parteien werden Informationen über die Angelegenheiten der jeweils anderen Partei, die sie bei
                Durchführung des Vertrags erlangen („vertrauliche Informationen“), vertraulich behandeln, nicht
                gegenüber Dritten offenbaren und nur für die Zwecke der Vertragsdurchführung verwenden. Die
                Verpflichtung zur vertraulichen Behandlung gilt nicht für Informationen, die

                <ul>
                    <li>der Öffentlichkeit bei Überlassung bereits bekannt sind,</li>
                    <li>die empfangende Partei nachweislich von Dritten rechtmäßig, insbesondere ohne Verstoß gegen
                        bestehende Geheimhaltungspflichten, erhalten hat,
                    </li>
                    <li>bei Abschluss des Vertrages bereits allgemein bekannt waren oder</li>
                    <li>nachträglich ohne Verstoß gegen die in dieser Vereinbarung enthaltenen Verpflichtungen allgemein
                        bekannt geworden sind
                    </li>
                </ul>
                (offenkundige Informationen). Die Pflicht zur Geheimhaltung gilt außerdem nicht für Informationen, die
                auf Grund zwingender gesetzlicher Bestimmungen, rechtskräftiger gerichtlicher Entscheidung oder
                behördlicher Anordnung bekannt gegeben werden müssen.
            </li>
            <li>Die Geheimhaltungsverpflichtung besteht über die Laufzeit des Vertrages hinaus in Bezug auf alle
                vertraulichen Informationen fort, soweit und solange diese nicht offenkundig sind oder werden.
            </li>
        </ol>
    </li>
    <li>
        Datenschutz, Datensicherheit

        <ol>
            <li>Greenbone erhebt, verarbeitet und nutzt personenbezogene Daten des Nutzers. Informationen zur
                Datenverarbeitung und zum Datenschutz enthält die gesonderte Datenschutzerklärung von Greenbone.
            </li>
            <li>Ist vereinbart, dass Greenbone Daten im Auftrag des Nutzers verarbeitet und können die zu verarbeitenden
                Daten auch personenbezogene Daten enthalten, verpflichten sich die Parteien, vor Beginn der
                entsprechenden Auftragsverarbeitung eine den gesetzlichen Anforderungen genügende
                Auftragsverarbeitungsvereinbarung abzuschließen.
            </li>
            <li>Die Ergebnisse der Scans der IT-Systeme des Nutzers werden zu jeder Zeit über verschlüsselte
                Verbindungen übertragen. Die Beziehungen zwischen Schwachstelle und jeweiligen IP-Adresse werden bei der
                Speicherung der Daten mit einem symmetrischen Verfahren verschlüsselt, um die für eine kurzfristige
                Reaktion bei erkannten Schwachstellen notwendige Auswertung der Reports vornehmen zu können.
            </li>
        </ol>
    </li>
    <li>
        Schlussbestimmungen
        <ol>
            <li>Soweit nach diesem Vertrag die Übermittlung von Erklärungen oder Informationen durch Greenbone an den
                Nutzer vorgesehen ist, erfolgt diese Übermittlung in aller Regel per E-Mail an die von dem Nutzer
                angegebene E-Mail-Adresse.
            </li>
            <li>Sollten einzelne Bestimmungen dieses Vertrages ungültig sein oder werden, bleibt die Gültigkeit der
                übrigen Bestimmungen unberührt.
            </li>
            <li>Auf diesen Vertrag sowie auf alle auf Grundlage dieses Vertrages zwischen den Parteien geschlossenen
                Vereinbarungen findet ausschließlich das Recht der Bundesrepublik Deutschland Anwendung. Die Anwendung
                des UN-Kaufrechts (CISG) ist ausgeschlossen.
            </li>
        </ol>
    </li>
</ol>`
