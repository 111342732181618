import React from "react"
import {Button, FlexColumn, GhostButton, Input, NotFoundError, Snackbar} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {ButtonSpinner} from "../../Components/ButtonSpinner"
import {ExternUserRestApiClient} from "../register/service/ExternUserRestApiClient"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {MailUrlProvider} from "./MailUrlProvider"
import {MailAnimationTemplate} from "../../Components/Illustrations/MailAnimationTemplate"
import {FormPaper} from "../../Components/FormPaper"
import HttpStatuscode from "http-status-codes"
import {withNavigate} from "../../../hocs/withNavigate";

export const MailValidateRequest = compose(withTranslation(), withNavigate)(
    class _MailValidateRequest extends React.Component {

        state = {
            email: "",
            _sending: false,
            error: null
        }

        constructor(props) {
            super(props)

            this.httpRestApiClient = new ExternUserRestApiClient()
        }


        handleChange = event => {
            this.setState({
                [event.target.name]: event.target.value
            })
        }

        handleFormSubmit = event => {
            event.preventDefault()
            const {t} = this.props

            this.setState({_sending: true})

            this.httpRestApiClient.requestConfirmMail(this.state.email)
                .then(response => {
                    this.setState({_sending: false})
                    this.props.navigate('success')
                })
                .catch(error => {
                    this.setState({_sending: false})

                    if (error.status === HttpStatuscode.BAD_REQUEST) {
                        this.setState({fieldErrors: error.fieldErrors})
                        return
                    }

                    if (error.type === NotFoundError) {
                        Snackbar.Error(t("mailRequestValidate.error.notFound"))
                    } else {
                        Snackbar.Error(error.toString())
                        this.setState({error: error})
                    }
                })
        }

        render() {

            const {t} = this.props

            return (
                <>
                    <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                        <Col xl={6} xs={12} md={8} lg={8}>
                            <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                                <FormPaper
                                    title={t("mailValidateRequest.subheadline")}
                                    text={t("mailValidateRequest.text")}
                                    actions={<Row>
                                        <Col>
                                            <a href="/ui">
                                                <GhostButton>
                                                    {t("back.to.login")}
                                                </GhostButton>
                                            </a>
                                        </Col>
                                        <Col>
                                            <form style={{display: "flex", justifyContent: "flex-end"}}
                                                  onSubmit={this.handleFormSubmit}>
                                                <Button disabled={this.state._sending}>
                                                    {t("request")}
                                                    <ButtonSpinner hidden={!this.state._sending}/>
                                                </Button>
                                            </form>
                                        </Col>
                                    </Row>}
                                >
                                    <Input label={"Email"} isValid={this.state.fieldErrors?.email}
                                           onChange={this.handleChange} name={"email"}/>
                                </FormPaper>

                            </FlexColumn>
                        </Col>
                        <Col>
                            <MailAnimationTemplate/>
                        </Col>
                    </Row>
                </>
            )
        }
    }
)

