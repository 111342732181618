import React from "react"
import {GhostButton, Snackbar, Spinner, useRequest} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {Paper} from "../../Components/Layout"
import {useTranslation} from "react-i18next"
import {LegalRestApiClient} from "../../LegalRestApiClient"
import {RichTextCss} from "../../Components/RichTextCss"


export function ImprintPage(props) {
    const {t} = useTranslation()

    const {i18n} = useTranslation()
    const apiClient = new LegalRestApiClient()

    const [data, loading, error] = useRequest(apiClient.getImprint(i18n.language).bind(this))

    if (error) {
        Snackbar.Error(error.toString())
    }

    const createMarkup = () => {
        return {__html: data?.value}
    }
    return <>
        <Row className={"justify-content-center"} style={{marginTop: "4rem", marginBottom: "4rem"}}>
            <Col xs={8}>
                <Paper>
                    <a href="/ui">
                        <GhostButton style={{marginTop: 0}}>{t("back.to.login")}</GhostButton>
                    </a>
                    <RichTextCss/>
                    {loading ? <div><Spinner/></div> :
                        <div className={"ql-editor"} dangerouslySetInnerHTML={createMarkup()}/>}

                </Paper>
            </Col>
        </Row>


    </>
}

