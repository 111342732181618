import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"


import EnglishResource from "./i18n/en"

import GermanResource from "./i18n/de"


i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: {
            order: ["cookie"],
            lookupCookie: "next-i18next",
            caches: ["cookie"]
        },
        fallbackLng: "en",
        debug: false,
        //backend: {
        //     loadPath: PUBLIC_FOLDER + "/locales/{{lng}}/english.json"
        //},
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function (value, format, lng) {
                if (format === "float" && typeof value === "number") {
                    if (i18n.language === "en") {
                        return value.toLocaleString("en-gb");
                    }
                    return value.toLocaleString("de-de");
                }

                try {
                    const {SIODateTime} = require("@greenbone/cloud-component-library")
                    if (value instanceof SIODateTime) {
                        return value.toFormat(format);
                    }
                    return value;
                } catch (e) {
                    return value;
                }

            }
        }
    });

i18n.addResourceBundle("en", "translation", EnglishResource, false, false);
i18n.addResourceBundle("de", "translation", GermanResource, false, false);

export {i18n};
