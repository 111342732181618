import styled, {css} from "styled-components"
import {GhostButton} from "@greenbone/cloud-component-library"

export const ActiveGhostButton = styled(GhostButton)`
  ${({active}) => active && css`
    font-weight: bold;
    border-width: 2px;

    &:hover {
      border-width: 2px;
    }
  `}
`