import React from "react";
import {useTranslation} from "react-i18next";
import {PLAN_ADVANCED_ID, PLAN_BASIC_ID, PLAN_FREE_ID, PLAN_PREMIUM_ID} from "../../../Constants";


const FreeTitle = (props) => {
    const {t} = useTranslation();

    return <>{t("plans.free.title")}</>;
};

export const PLANS = {
    BASIC: {
        name: "Basic",
        title: <>Basic</>,
        id: PLAN_BASIC_ID,
        ipCount: 10,
        price: 99,
        details: {
            openIpScan: true,
            selfServiceValidation: true,
            scanTargetAdministration: true,
            taskScheduleCredentialsHostCreation: true,
            continuousScans: true,
            trends: true,
            emailReports: true,
            allVulnerabilities: true,
            emailSupport: true,
            remoteSupport: false,
            trendActivities: false
        },
        color: "#86B2DE",
        days: 30
    },
    ADVANCED: {
        name: "Advanced",
        title: <>Advanced</>,
        id: PLAN_ADVANCED_ID,
        ipCount: 50,
        price: 199,
        details: {
            openIpScan: true,
            selfServiceValidation: true,
            scanTargetAdministration: true,
            taskScheduleCredentialsHostCreation: true,
            continuousScans: true,
            trends: true,
            emailReports: true,
            allVulnerabilities: true,
            emailSupport: true,
            remoteSupport: false,
            trendActivities: false
        },
        color: "#4588cd",
        days: 30
    },
    PREMIUM: {
        name: "Premium",
        title: <>Premium</>,
        id: PLAN_PREMIUM_ID,
        ipCount: 100,
        price: 269.00,
        details: {
            openIpScan: true,
            selfServiceValidation: true,
            scanTargetAdministration: true,
            taskScheduleCredentialsHostCreation: true,
            continuousScans: true,
            trends: true,
            emailReports: true,
            allVulnerabilities: true,
            emailSupport: true,
            remoteSupport: false,
            trendActivities: false
        },
        color: "#265C96",
        days: 30
    },
    FREE: {
        name: "Kostenlos",
        title: <FreeTitle/>,
        id: PLAN_FREE_ID,
        ipCount: 2,
        price: 0.00,
        details: {
            openIpScan: true,
            selfServiceValidation: true,
            scanTargetAdministration: true,
            taskScheduleCredentialsHostCreation: true,
            continuousScans: true,
            trends: true,
            emailReports: true,
            allVulnerabilities: true,
            emailSupport: true,
            remoteSupport: false,
            trendActivities: false
        },
        color: "#265C96",
        days: 14
    }
};

class ExternalSubscriptionRestApiClient {

    async getAvailableSubscriptionPlan() {

        return await {
            ...PLANS
        };

    }
}

export {ExternalSubscriptionRestApiClient};
