import { FlexColumn } from '@greenbone/cloud-component-library'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PasswordInput } from '../../../password/PasswordInput'
import { Checkbox } from '../../Checkbox'
import {Input} from "@greenbone/cloud-component-library"

const SignUp = (props, ref) => {
    const { t } = useTranslation()
    const [initiallyShowErrors, setInitiallyShowErrors] = useState(props.initiallyShowErrors)

    useImperativeHandle(ref, () => ({
        validate: () => {
            setInitiallyShowErrors(true)
            return props.validateLoginData()
        }
    })) 
    return (
        <FlexColumn fullWidth={'100%'}>
            <h3>{t("extern.registration.RegisterContainer.register")}</h3>

            <Input
                isValid={!initiallyShowErrors ? undefined : props.loginDataValid.email}
                value={props.loginData.email}
                label={t('extern.registration.address.companyEmail')}
                name={'email'}
                onChange={props.onChange}
            />
            <PasswordInput 
                onChange={props.onChange}
                name="password"
                value={props.loginData.password}
                label={t("common.password")}
                style={{color: "red"}}
                isValid={!initiallyShowErrors ? undefined : props.loginDataValid.password}
            />
            <Checkbox checked={props.loginData.securityNewsActive} label={t("extern.registration.RegisterContainer.securityNews")} name="securityNewsActive" onChange={props.onChange}/>
        </FlexColumn>
    )
}

export default forwardRef(SignUp)