import React from "react"
import styled from "styled-components"
import CheckIcon from "../../assets/icons/check-neutral.svg"
import {useTranslation} from "react-i18next"

const GreenCircle = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #66c430;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  color: white;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  
  svg {
      width: 100%;
      height: 100%
  }
`

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const SuccessIcon = ({successMessage}) => {
    const {t} = useTranslation()
    const defaultMessage = t("common.successfully.requested")

    return <Outer>
        <GreenCircle>
            <CheckIcon/>
        </GreenCircle>
        <div style={{color: "#66c430"}}>
            {successMessage ? successMessage : defaultMessage}
        </div>
    </Outer>
}
