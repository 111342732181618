export function getValidationResult(data, validators) {
    let isValid = true;
    let fieldValidity = {};

    for (let fieldName in validators) {
        try {
            if (!validators.hasOwnProperty(fieldName)) {
                console.warn(`Validation found invalid object property ${fieldName}`);
                continue;
            }

            const value = data[fieldName];

            const fieldIsValid = validators[fieldName].validate(value);
            fieldValidity[fieldName] = fieldIsValid;
            if (fieldIsValid !== true) {
                isValid = false;
            }
        } catch (exception) {
            console.warn(`The validator for ${fieldName} does not have a validate function or is null. Please make sure you inititalized the validator class correctly! `);
        }
    }

    return {isValid: isValid, fieldValidity: fieldValidity};
}

