export class MailUrlProvider {

    static validate() {
        return '/validate'
    }

    static validateSuccess() {
        return '/validate/success'
    }

    static requestConfirm() {
        return '/request_confirm'
    }

    static requestConfirmSuccess() {
        return '/request_confirm/success'
    }
}
