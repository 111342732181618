
export const sendingState = {
    NOTSENDING: "NotSending",
    PAYMENTPENDING: "PaymentPending",
    SENDING: "Sending"
};

export const PaymentMethods = {
    CARD: "CARD",
    INVOICE: "INVOICE",
    NONE: "NONE"
};

