export class ApplicationUrlProvider {
    static BASE_URL = `/`
    static privacyPolicyProviders = {
        greenbone: `${ApplicationUrlProvider.privacyPolicy()}/greenbone`,
        vmsp: `${ApplicationUrlProvider.privacyPolicy()}/vmsp`
    }
    static termsOfUseProviders = {
        greenbone: `${ApplicationUrlProvider.termsOfUse()}/greenbone`,
        vmsp: `${ApplicationUrlProvider.termsOfUse()}/vmsp`
    }

    static getBase() {
        return ApplicationUrlProvider.BASE_URL
    }

    static register() {
        return `${ApplicationUrlProvider.getBase()}register/*`
    }

    static password() {
        return `${ApplicationUrlProvider.getBase()}password/*`
    }

    static team() {
        return `${ApplicationUrlProvider.getBase()}team/*`
    }

    static msp() {
        return `${ApplicationUrlProvider.getBase()}msp/*`
    }

    static imprint() {
        return `${ApplicationUrlProvider.getBase()}imprint`
    }

    static privacyPolicy() {
        return `${ApplicationUrlProvider.getBase()}privacy`
    }

    static termsOfUse() {
        return `${ApplicationUrlProvider.getBase()}tos`
    }

    static mail() {
        return `${ApplicationUrlProvider.getBase()}mail/*`
    }

    static hostValidation() {
        return `${ApplicationUrlProvider.getBase()}hostvalidation/*`
    }


}
