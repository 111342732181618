import React from "react"
import styled, {createGlobalStyle, useTheme} from "styled-components"
import {FlexRow, ShadeBlendColor} from "@greenbone/cloud-component-library"
import {Col} from "reactstrap"


const Style = createGlobalStyle`
#Group_9524{
    animation-duration: 2s;
    animation-name: hover-hexagon-thumbs-up-keyframes ;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes hover-hexagon-thumbs-up-keyframes {
  0% {
    transform: translate(-1862px, -14016px);

  }

  50% {
    transform: translate(-1862px, -14036px);
  }

  100% {
    transform: translate(-1862px,-14016px);

  }
}

`

function SvgTemplate(color, animationBackground) {


    // const color2 = "#02447b"
    // const color3 = "#025b93"
    // const color1 = "#0476ac"
    // const color4 = "#36a6cd"
    // const color5 = "#5fcce6"
    // const color6 = "#036aa1"

    const color2 = ShadeBlendColor(color, 1)
    const color3 = ShadeBlendColor(color, 2)
    // const color1 = ShadeBlendColor(color, 3)
    const color4 = ShadeBlendColor(color, 4)
    const color5 = ShadeBlendColor(color, 5)
    const color6 = ShadeBlendColor(color, 6)

    const {animation} = useTheme()

    let background = "#FFFFFF1A"
    if (animation) {
        background = `${animation.background}1A`
    }

    return {__html: BaseSvg(color, animationBackground, color2, color3, color4, color5, color6, background)}
}

const BaseSvg = (color, color1, color2, color3, color4, color5, color6, background) => `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 1158 1080" style="enable-background:new 0 0 1158 1080;" xml:space="preserve">
<style type="text/css">
\t.st0{fill:none;}
\t.st1{opacity:0.86;}
\t.st2{fill:#FFFFFF;}
\t#Path_24315{fill:${color1};}
\t.st3{opacity:0.52;fill:${color1};enable-background:new    ;}
\t.st4{opacity:0.32;fill:${color1};enable-background:new    ;}
\t.st5{opacity:0.35;fill:${color1};enable-background:new    ;}
\t.st6{opacity:0.7;fill:${color1};enable-background:new    ;}
\t.st7{opacity:0.91;fill:${color1};enable-background:new    ;}
\t.st8{opacity:0.84;fill:${color1};enable-background:new    ;}
\t.st9{opacity:0.4;fill:${color1};enable-background:new    ;}
\t.st10{opacity:0.45;enable-background:new    ;}
\t.st11{fill:url(#Union_68_1_);}
\t.st12{fill:${color};}
\t.st13{fill:url(#Union_68-2_1_);}
\t.st14{fill:url(#Path_7305_1_);}
\t.st15{fill:url(#Intersection_6_1_);}
\t.st16{opacity:0.35;fill:url(#Union_391_1_);enable-background:new    ;}
\t.st17{fill:url(#Path_1479-2_1_);}
\t.st18{fill:url(#Path_1480-2_1_);}
\t.st19{fill:url(#Path_1481-2_1_);}
\t.st20{fill:url(#Path_1482-3_1_);}
\t.st21{fill:url(#Path_1479-2-2_1_);}
\t.st22{fill:url(#Path_1480-2-2_1_);}
\t.st23{fill:url(#Path_1481-2-2_1_);}
\t.st24{fill:url(#Path_1482-3-2_1_);}
</style>
<g id="Group_9555" transform="translate(-762)">
\t<rect id="Rectangle_1" x="762" y="0" class="st0" width="1158" height="1080"/>
\t<g id="Group_9478" transform="translate(887.305 382.713)" class="st1">
\t\t<path id="Path_24315" class="st2" d="M461.2,126.3c-12.8,0-25.7,2.8-35.5,8.4L216.1,255.4c-19.6,11.3-19.6,29.5,0,40.8
\t\t\tl209.6,120.6c9.8,5.6,22.6,8.4,35.5,8.4s25.7-2.8,35.5-8.4l209.6-120.6c19.6-11.3,19.6-29.5,0-40.8L496.6,134.8
\t\t\tC486.8,129.1,474,126.3,461.2,126.3z"/>
\t\t<path id="Path_24316" class="st3" d="M267.3,360.7c-8,0-16,1.8-22.1,5.3l-130.6,75.1c-12.2,7-12.2,18.4,0,25.4l130.6,75.1
\t\t\tc6.1,3.5,14.1,5.3,22.1,5.3s16-1.8,22.1-5.3L420,466.5c12.2-7,12.2-18.4,0-25.4L289.4,366C283.3,362.5,275.3,360.7,267.3,360.7z"
\t\t\t/>
\t\t<path id="Path_24317" class="st4" d="M616.1,381.9c-6.6,0-13.1,1.4-18.1,4.3l-107.2,61.7c-10,5.8-10,15.1,0,20.9L598,530.4
\t\t\tc5,2.9,11.6,4.3,18.1,4.3c6.6,0,13.1-1.4,18.1-4.3l107.2-61.7c10-5.8,10-15.1,0-20.9l-107.2-61.7
\t\t\tC629.3,383.3,622.7,381.9,616.1,381.9z"/>
\t\t<path id="Path_24318" class="st5" d="M763.7,155.9c-4.3-0.1-8.5,0.9-12.3,2.9l-72.8,41.9c-6.8,3.9-6.8,10.2,0,14.2l72.8,41.8
\t\t\tc3.8,2,8,3,12.3,2.9c4.3,0.1,8.5-0.9,12.3-2.9l72.8-41.8c6.8-3.9,6.8-10.3,0-14.2L776,158.9C772.2,156.9,768,155.9,763.7,155.9z"
\t\t\t/>
\t\t<path id="Path_24319" class="st6" d="M847.3,435.2c-2.1,0-4.3,0.5-6.1,1.5l-36.3,20.9c-3.4,1.9-3.4,5.1,0,7.1l36.3,20.9
\t\t\tc1.9,1,4,1.5,6.1,1.5c2.1,0,4.3-0.5,6.1-1.5l36.3-20.9c3.4-2,3.4-5.1,0-7.1l-36.3-20.9C851.6,435.6,849.5,435.1,847.3,435.2z"/>
\t\t<path id="Path_24321" class="st7" d="M70.8,473.7c-3.4-0.1-6.7,0.7-9.7,2.3L4,508.8c-5.3,3.1-5.3,8,0,11.1l57.2,32.9
\t\t\tc3,1.6,6.3,2.4,9.7,2.3c3.4,0.1,6.7-0.7,9.7-2.3l57.2-32.9c5.3-3.1,5.3-8.1,0-11.1L80.5,476C77.5,474.4,74.2,473.6,70.8,473.7z"/>
\t\t<path id="Path_24322" class="st8" d="M151.1,294.3c-4.1-0.1-8.2,0.9-11.8,2.8l-69.8,40.1c-6.5,3.7-6.5,9.8,0,13.6l69.8,40.1
\t\t\tc3.6,1.9,7.7,2.9,11.8,2.8c4.1,0.1,8.2-0.9,11.8-2.8l69.8-40.1c6.5-3.8,6.5-9.8,0-13.6l-69.8-40.1
\t\t\tC159.3,295.2,155.2,294.2,151.1,294.3z"/>
\t\t<path id="Path_24323" class="st5" d="M207.2,109.9c-2.7-0.1-5.3,0.6-7.6,1.8l-45,25.9c-4.2,2.4-4.2,6.3,0,8.8l45,25.9
\t\t\tc2.3,1.2,5,1.9,7.6,1.8c2.7,0.1,5.3-0.6,7.6-1.8l45-25.9c4.2-2.4,4.2-6.3,0-8.8l-45-25.9C212.5,110.5,209.9,109.9,207.2,109.9z"/>
\t\t<path id="Path_24324" class="st9" d="M198.6,0c-2.1,0-4.1,0.4-6,1.4l-35.3,20.3c-3.3,1.9-3.3,5,0,6.9l35.3,20.3
\t\t\tc1.8,1,3.9,1.5,6,1.4c2.1,0,4.1-0.4,6-1.4l35.3-20.3c3.3-1.9,3.3-5,0-6.9L204.6,1.4C202.8,0.4,200.7,0,198.6,0z"/>
\t</g>
\t<g id="Group_9476" transform="translate(1155.085 447.536)">
\t\t<g id="Path_24301" class="st10">
\t\t\t
\t\t\t\t<image style="overflow:visible;" width="436" height="240" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbQAAADwCAYAAABoipGIAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAMuBJREFUeNrsneFy8jywpMck93/F
X/D+2bP1Lgdpumd6ZBlGVamAMYSArcfdao0O69btnu244PnH4v/xXPQc5fO7dbttp9Ct26dCrPJv
ZaBxFu3bcOvWQOvWbfPj9Lhov1XqTL1fg61bA61bt42OTxWcVig6teI6xX+zwdatgdat24Ygq358
tUqrfrzh1q2B1q3bwuPxSOxzBF+3+rw5Fz/Wqq1bA61btxuqMWb7FVBjoXSSr9Ng69adSbduNwBZ
FlZXQ00BMwX0MpBqsHVroHXrY26BGjuA5x2J168CWhRcZ+L1G2zdGmjdul0MskN8P6Lc1FBTwEsB
t7YjuzXQuvUxJnxORI0diX1R6DGPs4rIA1oF3Bps3Rpo3brZuvGxDKgOAfwU/zOqlM4g0KKPReHb
YOvWQOvWakwEsgi41HCbPZYJfiC31c+pVG0Ntm4NtG4fDzJEBVWAqwJs0Y79FN9epdoabN0aaN0a
ZEI1FgEXAzgl1CIwY4CFPt5g69ZA69bHzcUgiygt7/cqqKlhdgafl1FwFWBruHVroHX7OJCxUDuI
x47Ec1cDLQIzBeQY6CEQatXWrYHW7atBxtiKq34j7zPaYSugpYYco+CqVFuDrVsDrdvyY6MCZOyY
17+/D9E+DHRH25H0YBRomX1R2DXYujXQurUac7ZFQMb89rYpbEn281MrMxRuFerNe6zB1q2B1u3r
QcYCjdkWeZ4KaiqYZW6joPNgxyi4Blu3Blq3jwIZAjUUaIzyQoFWDbUqmEUeQ7ah6u1qsDXcujXQ
+ru/BGRe0GMEDCXAmPse4FYAjQEWex9Va6vB1qqtWwOt2y1AFh0fQy3Ed/fRfVjF5sHNAIhFQYbA
C9mmABwDs7YjuzXQui39nncFWRRe3nbGmkSVGgO1iLWIbI+AEIWd97802Lo10LrdUo0pQYZADQGU
By9kf0StzT67w2KTqBm1xYCsSrkx6s17rMHWrYHWbVuQReaNITCLQIsFmgJqV8AsA7uRepvBzlOb
CMw6QNKtgdbtEpApEouoElOpsIcIahnbcQeYRYCHKjdGqXWApFsDrds2IFMkFmdBD7UCQ6GG2pAs
1GYdOAI15udZpOLMOkDSrYHW7UNBVhX0yMDsIQTacRHQPLB5wHoKYGfA9ohyYyHXYOvWQOvv7LYg
U1iJ6LaMavPANus0UaBF1Ri67QpLklFv3mMNtm4NtC9XYzO7UA0yZu4Y8hgKpex91IZkYDbrzCNA
eybvKyCHgO11m9k9AiQNtgZatxvbigy8MkosaiUyCoy9rYLau8/ydKCmhhlyGwVedqwNtSG7tFa3
Blp/N8tBVplYZCE2uv8At3mAY6HGWI5m+ORpT1l5txWwUwVIOhnZrYHW30kJyCLR+9WJxQepwmbb
GLjNADeCtPfZnxOFhqi0CLieArghgDPwsahyY2DWdmR3nt2+FGRs9H5FYpGF2P/c9mAWUXCRgMjr
53lO7MaRsnk6UGPglYFbxRSAiHJjIddg60602w0+/09NLCrsxIejyhgVh8DNU7BRyxEdL2N+7wA3
Syg3Rr1l7cgGWwOt20VqbCXIVInFSFLxAfz2tj2I1/Lem1ks6cgmHCMw87Yp4LYqQNKltbo10NpW
LAOZOrGYhZgHrizcFLZjFGhZmLG3I5D7hgBJg62B1m1jkCmi98rEIhL0yECsGmoKoL3eV8As8pgH
OhXczGJjbUqwtR3ZQOu2KchWFguuTCxGVVj2fiYgogZaBcyY+6h6y6QjzTTV/hts3RpoHwoydWLR
TF+WSqXG3t3P7jNLS+4KNARU6DbUkvSmDNwpGXmFHdlga6A1yOwzEouoKvNgNtrGbkftRzXQbNKB
I0DzoKbYHhlzu1MyklFwDbYGWn+GG4OMsRc9oCGpwJGdF4XZbDuznxJokXlo0fEzVJk9yX1YW3Km
2lRwm8GftSE7QNKdcbeL1VgVyKprLKrUmAJcyP6qYMi774apEoICjbEXkR9Uza0OkHRprW4NtC8B
2W6JRWQS9AhqrLWY+YkERBigHYC1hdZuZIGmAhwKTTb2H4GbWZfW6tZAuyXIvjGxWAWuyPgaUjnE
SKCZ4XPQZkBDx85UP9WqbQazp+HVVMy6tFZ30t22Bdm3JRYrgfYDvHa1QqsC2gqoRcbbFGW2etHR
BlsD7YNAdscaiytCHv+CqkK57WQ5vt5WAO2vUL0xk7UrYv8WtCRRW5JRcA22BtrtPo9PSyxGF8+M
VvBQqjD1eBo6F+31M3/3PaKhEHYOGgo1BmLIvif4WFa1dWmthlsDbWM1VgWyXRKLbLUOJbyiUMtG
9800E6uR5WJYlYbASaXYFCW2Pqm0VicjG2hfDbJvSCwyUPspeCwS3UcX+1QDbWTNIWENFFJ/BaBD
rcgurdVga6DdDGQrE4tRkN0hsfhTADV2HtoD+OxH3zszhoYs4KkIg/wVqDqFFflJpbUabA20jwMZ
m1j0lJiZPnqvGiOrtBVZqI0e95RjdAwtq9DMUSFKoDEwqwqRfHpprU5GNtA+FmSViUUGZsr1x1SJ
RWZMLAI1VcIRXeRTodBQlaZMOf6ZVsVlCiTvXFqrFx1toN3if7tj9N57TJlY3MVWVEItu4SMp7RR
qFUGQ6LjZZVqrUtrdYCkgbZYjc3sQjXIZpZixE6sLBasAloFwH7s2jqOKxf4VIVD/i4GXHQ+m7K0
FpuMZAHHwKztyC8H2qcmFq0IYrtNhP4pUGXo2FnEbjTTLx/D2o6K8bQ/q1Frf6Yvr6VMRo62m3WA
pIHWIHNtp5WJRSbskYFZtTKrtBoz6cbI+Bmi0KLjaOpJ1lertex8ti6t9aVgOxpkofEx1k40W59Y
ROaP3SGxqLQaM+psptDM+FqOUaBVFyr+2wxw2flsVbF/BGyv21BbUjnO9lVgOxpklxYLXp1YXLl0
yw4Aq5pIzY6feQt8MuNo6sU+I4pNAbO7l9b6hkVHzwba54Ns92LB0TEyZlVnBig/xVCrVGZs/UbE
cowotBUx/juoNdV8tk5GfijcjgZZGciiiUUzP3JfkVhUjZP9FEKtQpUhIEPnnkVDIWa5cIhiTK06
KKIG3KrSWr3o6I3AdnzQe/uGYsGVicXDrh0biwDsx7QLeCrU2UF2DGyh4khIpKo8VpUd6T2esSJX
jbF1MrKBtiXIdisWzCYWs7ZitLTUDuNl3lhZZtwsqs5GHRKi1JhlZbygyGn6osU72JGnxaqQdGmt
DwDb8aUgW1ksWJ1YZNRYZVWPH9sz8OEpMlaVPYDvFR0/YxSakQqtKixylR25srQWY0NWLDo6U26o
LakcZ7st2I4vBpk6sWgWD3lkwh4rqnr82L6BD4XF+AC+CyPV2QGe+GjakYnyZ6L9p8garFZrfwkr
MjrG1ouObg6248tB9qmJxYitGIXLDoEPJvTBKjOFOjvAq+lKqCksyDvbkerSWt+06Oh5F6gdDbKU
EvOAZcYXCr5bYrEq8JGBGaPImDEzJgySVWjseBobFlkV89/FjlxdWusOi45+HNiOBtllicVKNbY6
sbiTtchC7CECWXQMLaPQkKr8FTZkdvL0TqW1PnHR0a8F27Hh39kdZMrEIlKeqmodMgYuOwU+EGsR
KfmFzPWzgDqLphwVYHuScKuwIj/BjrzLoqPqyP/twXZs9vrHh4Isu6Dm6sTiTmNjinGyBwiwVerM
61RY+zFrQVZbkTvbkVkr8qpFR9WR/1VgO+8KtOMGIFsZvd8lsbjCRlyZWlSEPpDv2gyfe5a1HFGw
RdRaZSLy0+zI0/YsraWO/CvAtoVaOzYFmddJKEF2RfR+VWKRVU27Fg+OqDGlKosqswM4kRnr0YBO
72maNGSFFXk3O1IxSXv1oqNsMvJKsJ27A61Cla0AWQZm3tV/ZukW9Tpkd5gMXW0tPoxTZBXqDO1A
TvNLY3mqbXcr8gq1lqn0z07Srl509O5gO3cF2iEGmQeqapCtjt5XjJNdOTYWqYDPJhYjhYVZRXaQ
xx+r0DIqjbEgWbXmjRsxnX4EKnev9L9y0dGrwbaNWjsWv8aRAJnXqVwdu69auuUwbTWPirGxygr4
lRCLwgwto8acwMy4B2tHPU03MbvKivymSv9Xx/49FwAd491OrR2Lnl9hL6KpxSvmj101EXr3sTGl
rZiFWHS8jFFoKtuRHVdT2ZAIzFgr8rR8iasr7cjdFx2NgK260v8yqB0XwSxjL0bi9zsv3XJcBLAV
1iIaaImqMXStOHSMlLEZD9G5pFBqZvHJ2IoxNgRqn15aq3LR0Wqw7WZDnquBpoRZ1F7M1Fi8Inqv
it/vXii4OrHIRvCVwQ+1OrNAh4EGRpTja2zcv8KKrFBryir/0Ur/2RJbCrBlxte2gtpR9JyMxbjj
+NhuE6HvMhkaqbEYtRTVMXwGYofoXFKpNGVoJDuPLTKfbfR4ZaX/qxYdjSYj77I+Gwq2Eqgdi2CW
VWWrbEVFfUX1JOgdJkNHVoeO2orKsEcm8MHG81WWo3pczYyb3/S09ZO0V9uRd0xG3tmOXAa1301g
FlVlbFWPh60vFBwF2lXWYqWtuApiapgxNiObcjzebM+ObZ+T2x7Qjjed5THZ55j8fvzf13j801E/
BFbkYwKfx5vtD+f5f+A2tR357nP593N797k+J7czUDsAeIy+59fH3r3eu9dH/ubBQO0QAi0LM2Ss
LFuWSjkJmrEUj8S4lGq5lp0Ti5Gwhxlff1E9VsZc5EWuRr2lZpAxNVV4JDvGFpmkvaK0lkqtqe3I
inE2ZtJ2Rq2hK2nLldrvZjCrthbZSvc7VPNYvQbZT8JWZMbIMolFNujBjJlFIHYkz6F3au107s+u
Zg/nivkYXJ17P6/qbXSfVW3REAmj1NRq7Q94L1HF+e5zOgjFxq7FFlFF3vdszrFXotSOxTBTBD5m
HdhqS3HlQppXW4tMweDqxCJykZMJfSD24pE4Z9jK5dEU5Eyt7Rwguaq01k51I3eZ12bgfe94VCg1
F2i/AuAZGfiIKLLKAsG7lqTazVrMJBavqOwRsRUjEFMlhUcqLfM3Tuexk1RrNugcZ8otqtpmqoct
rfUAVVxErXmKMDs2eAzG1/79/J4vv0dqzSYXJOnxK0e9KZSa+36OJNDQ5JcHM7Ui86zFHVaCVqkw
lbV4EEC7KrHITtuoGieLzsPMVEtAxyWic9cQtWbE2AxbfWRFaa0V89gyY23RwsjRyv8R1WbmL2Xj
jauVKbXfC2GGlqq6sorHFRC70lpUr0OGjpNVJRYztiITAMmWgDsn+52DcMkxuTKejbkxV84HCLRj
0EmOlOBIrT0mY0iK8SpUqaHKDFVv6LjfSJm9bn9VajO19u73v9/TA1RrUWUWUWrhdggfO0QwQ4Gm
WHdMbSeqIHaVtaio6sGosYdpE4usCsuosarFcZnqCsyEbGZ8zWzvZGR2PtuKeWx/FrMf/+yapKRy
srZCqYVU2q/oZEXHHrIxfPTqf3Vh4AjIFFBjrcXDYvF7T+Fm1iJT1FnMjo8dRRCLWI7HYH9GtWXm
siFq7SA6OjYZ+arMRnPYMvPZquexIfdn7230+KhPfH3s/Of2O4VmgzHRR0KljeakzZRadAyYCoVk
rEZ2nhlrLVZNfK4EmRJqilqLSlsxA7FVicVVIMvscwbhxoDtdP6/07Em2QAJCzfPkkQmaXtW5ElY
ge8AN9qG3n99/l8Aagjozpf7rzB7Z0GaCGyM/ZixHt/u/0u+SXasgEkx7gqy6DpjKMh2reqxInqv
Tiwqx8cO0bnAnlce3BCwzQCGgM1LoKFqzSaWFgK3kVobQQ21Ih/GJSNR9ebBiwEbArU/ULHNYDay
BSNgG11QzZQZW12EUmgKq7EaZjNrUQGyn0XwUquzq6p67BC9V84lWwmyCNyOpB0ZCZCwluQIaEh4
hIn9I6qNsSOZ0MgThNc7mKFgOwCIodbkSK29A9orzCJg80piKazH//VYppYjU9cuYzGyVTwUS7Iw
91W3lRbjYZoxshXRe3ViscJWrIaY93fPAjvS64g85eZZkoiCOwi4eWptloyMRv1RG5KxHD2w/U1U
2t8biP0Fzr/ny3f7HIQ0MmCbXfQorcehQjuEJ+As3TjrUCIwU0brWfB4+yvVGaPQrioW/HAuWhiQ
qROLO6qxFaqtIkDClNZCQyTv1BtTWmtmS0Ynac/KWyEq7RVK7O1XlTaCWRRsI6U2UmnPwHmBKrOo
9fj/PRat5YiMT3j7RO1Gdr4YG9z4SYBrtUKrshU/PbFYqcbYavsVqq0yQIIALpuMnCm1qGqL2JEP
YIzNU23M7T8HYocDsxHYLHhMns7na+RrZ6zH0Bia6kRWWo0V42IotJjHVii0DMwUxYKzicXIONkd
QKYof3VeBLYZwM7B5+p1RNnSWs8B3LKqTRn391KQo3G1mSp7OPvNAPYvyP6Ii8x31uOrSnu9SDCb
l2/zjsfRMZRWab+BEz26BMxqmDEQ86AUAZtKoUVhdkXYg4WZ2fWJxWMhxDK2omqcTR0gUSYjzfLh
kVmVetaOROA2Um0j69FTZcdEmR0DsL3+9s7Dd27ZSHF545TeRdHMDZBXC/k1fRutNo2MmaGpRhRm
iGr6IQD1k4DZDiC7Q7HgVYnFYwOIRSB1xTibgerNLJeMNMuHR9CxtVc78hCDzRsfe4XTTKX9u+8f
+RstvD1r7wojR16TCYqEVNpvsTozcEwFHTtjVJkKVBmgRVTaFfZiRbFgJLHIWIyoGrszyFaqtuoA
STQZ6Y21MXBD7EhknA0FGwK1EcC8Gox/9r+j9zOYIRd5WbDNgiLIsScdP6tQaOyYmQEW12OyLQIz
9nbk8Wp1thvIdk8s7mIrXqXadgiQVCw6OoMbUlbrAG6roHYAKo0F2Qxio21IOx2R8XTO6RMYY0OV
GAW93wSsUHWWsRtHEDsIu9EDV/QxVOUpgRZNL15V9f6OINsJYruBzeusrkxGRspqeXPYImA7BvcR
lfYOaDOQmXPbCID9e/uRsB/R4yBaeX9qO/4KT2xGnRlgPTKdO6PI3t1H9lFYkJFofqZUFQqyh3X0
fneQXQk2ZpxtdTJyBjdEtY0WzXwFGwK314nPr/e9UIenwl6BNYIZY8+bYxWe5pfXev2OUZtcWvYq
ajlGKoa8U2eoLcmCbqSKZjCLgg4Fm8JqZBKMq0F258Ti3UCmBNu756kqkKiTkRVgewJgG8FtNlHZ
mzeGWowIyN7BDFVis98ztfY0P0WJKDXVStY00JjBRcWKw5kJ1jOQzWDmAQ8Bm1KdzZRZJoYfBVmk
6n2DbD3YdhlnM8KWvApsI8C9Au0cKBSkaLA3b+wvATIEZt46eq/p0scLzE6gXz5f+oOTuNCKKLHh
c34XdxCZFamZ0lcjcDA/LNhQqEVLWGXKVe0Asii0GmT3GmdDkpHZ1bSVYPNSkSO1hii1EdiQdKIH
MkaZmf3v6RFm88U5H28AhwiVkwCbZzOmq+1n7Ea082HVGVrPMQswFGis7RiN6UcKCVfVWqwEWUOs
wcaOsSjBNlJpI7WGwOwYqC2z9+lEZixMdVy/fiaPN7cfL3bsGVBpM+XOJBshuP0GO4NInUYLqLOH
aSdcs+WyUNip1VkUZtEEY4OswfaNYLMXyHnjWyMLkoHan3MesWNjp/DnHdQi/Qi7QrXMdvwVnzDI
PtE6jyOlVgUtFmaKQAgby8/E8Q286GiQNdg+FWxm49Jb76Bmb24j55l3riiP1yzUjokyQ1TaCGpR
25Fqv8LOwCtxZQmlhqo3JhHprTP2DmRotF8530y5vItZPIrPnpQNsv3BxsANSUbuBjabAO75okhG
Y0mv7/l1fphNziubXLwzx+u7/2kEpJ8isDE/s1JWM9vx3Xe2ZGL1rIPyrlDQYpnvOmCkYsgrGNio
/w8AudH+lepMaTOa4ZOjESV2BI6TBtnnqbYqsJ2J4+Ic/G8PB3AjtTb6v5/BPnMGsRncPHA9BhBn
BMMr2MywZWkitiOiztzX+V3UeSBXLNn5aV5lEeVYW3RBTmYCtaoyPvIZW+B3g6zBtgpsCOAQ62qk
1F4BN1rc8jH4v57Bz3ykCk/CQnwA6vP5AiYWbmw/fZK2Y9pqRIGmWNE3Ml/NTBPxH8X+H8aX1Kr8
QcfOoupsdkCi6iyyyGuDrMEWAdu7sRgLQA7pMB8TuD1f1Mn//P4Xbv/eRtWWN5n5BGH2dJTmK8ie
bxQYCzfPYfPglJo47T3vd9HJEYUgq9481fKYgI9RcygIPaV4mL+0C/K/VFmMlaqsQfaZYFOER2a3
szbkrD3egO319mMAtWMAttdqG+cbpTdSW48JyI6JChuBDJ1Dh7o4Zlw4ZHQeqwAnARraYR3gczJp
SCNgdoCgQ1Qguv4YAzIvnr8ilh9VaGpV1iC7D9giqUhkPtIMZiNriy3F9O54e7dEyjtw2AAWrxOT
31Xi+BdMx5v7o/Gtd3UmPXCxUDOg36iyEOlgyG+w41CsUxUJkCjX6mJrHj4sHsZ4GD8lQTG2aIOD
r7KQsFKVNcg+F2xRtTZKzqFQQ46rY+IYzcCGhiwU+73728h7sUC/4vUHEZVW0h4FB/yR7JiiczQO
y4/RZeCZ+UEsQA9Wo7hwNDLMjo8qYXZYw2x3sKkdHPaYO4Dto/PEO3eYDv+qPiPyXrw+ATkfK+bO
yS5iH+I/ciT3OQIdHNpps1XjPUh4B5AJ90NOztlndTgdCHoxcgg7r1Zl3wO16OsciX7jCJ4PqN2W
uYhW7ef1YWjfZ+Dfyn6/5ef246IDOfOBoBMYmatB5OBHrDz0hPKufqKvZeR7q7ow6dZQq9yXUWlR
5Y9eBBrYF7FKyLt4ZSqRVF1sbtcHPDY68A/xB8UcjFnFoZTjaml/BD6TVmfddgFjpl84FrzP6DnF
uiPRczT6usrP9Vh1vKwA2hnY7yx+L+jfOsHts3kl2fd4Cj//E/xMIt/Jmfz+K7/3bvu2s7BfOBe8
z+g5FTnPz8T5jfRftz9eHhceIBV/LwtFtGYac6CfADTPxPurgMy54bHQ7V4gqtwXOeeqzjX0ovgU
nHdev3MmwZbpJ7fkxkP8hrIdIdLxnw5kTgcqbLkZ773MoIeUsDHnPbLvz3ttVg22Suu2qzo7iX4D
hVX0vDyBfsGMq/5/Bs7tE4Qv8hl4F+8RQG8BNObgPEWdFKu2TgBWswMocgDOIHZafG0mdKmLyFXc
GTwYIxZIBmoNtn0hVOUSKNUZclF3AudUpt+wZF/BvhfkdYzoPzxg7uDKTYEWVWJn8CBmlwVHFRr6
8xzcZre9PvYE9z8D+zHA9A7K0/y6ct7FBQu1Btv3gCwKM2Q5F+Z4ZfsHE/Qbz0BfMXqdSB9xBvsI
xPnyYMyqaFn7FR3oB7F99DjS6VrBF4oU+3w3C/71sdEqt//WhkOqfY8KomaukmY19pDlGmbliMze
V2Dw1kCKLKXRKcj9bEFmfzagcDoKjLEBzWIXuqr9n28g9u73EwBbBfAY5wsZq7xErf0uOBm8oqPe
B5g5MJEq1LOK1EhVag9q9gZsryp5BrZ3y1fMiqFGATdb9mH2PZrFFmuMQKrBti/IsvbiSdxGFFvU
1Xg6t58TmMx+EKg9wdca/e0Vyg5V6566Ow1X/bIFPlmVhSqxw7TjSUgFau/+qLDov9vN+LJVaAf8
sP+9Au7oNwu22ffE1lg7zK+5x6q1BttnggyxqBGwseqMhdoTsAQ9EF35w4AtAreIqEAvZJBjAj7u
fheeJMzSD94V13MCxXdr/njrAo0Ker7CzGxetDPb3lX5Hv32qnzPKqqMPn9Ppc3AprAhG2yfCzLk
qn0EsgjEIk5ORJ1FAPQ3+P16uwp0J/g8RJ15Y27ZOb9U+zGucr5Xe3DUSTNFd3cq6GkAyNA6ah78
FBHXI9AJMWNz0eVlzHoF60+BGGstVoPMg5gZN/bFggyBGgKs130iYPsjt2csUlahKaxF9zUyQDMQ
DN5zR89DllFRQdGA1xpB2gxfLifSYaAVBY6FHRoDqQZbqzEPbKzNqBovOwfKBQ1ujCDwB0Lt3e93
YPtzYOUBEAXaObjPJLctqOgkfdOPcfXBWCh5K56yyx54qigLrkznzY6hHcFOIXIAeGtZnYHXarA1
yCLjIVUgi1iMCpvxJCAygtoIbB7gEFB5+5yEOvOAZubPd8scLxKgzTppte3IgCn7fHUHoYjZn4m/
wXbq0cHZ82KwNdwaZEylHs8i8yAWgdk7kLAK7W9yn/mJwoxRZ2b+pPElx+dPoBNS2o5G2IkmhuLV
i0hG60IacMKzB0vkKimr4qJAbLDdH2SKKL4HMmSsjAl2MAGQP/A+qtJQoClCJFGr0SvSYOZXJEkf
yz/Gj/+obEcESDOFh7wHVQeXudpkaqcxfxut+4heJcnlv/HL7zTYGmSREnYzkD0Nm4zsqbORhfgH
WIRPB1wjdcaADYEeY0ciE74jpbuQPilcXeTH4mt+RUCCLo/uKTxErUXgFj1p0d8IwKJ//wqwoQfc
AdxusO0FsSzIohd9WZA9CZBlldbseTOQeVDyQPYkgYakJ2cWa6aWLXMMpqP7EaBlLD/vtbLPN0fl
VYEscsVpxpWjYSegZsEWTalFQNJg21uNXQkyJHo/m0PlgcyD2x+oylAAIWNoKMhY0EWCIoqKIpEV
UkJOkRpoRqo0BI7R94WmDdk5MgzIIv6/AfDJWJ7sMjnMVVV2akGDbW9bMXpxNwNYFGRs7UQ2Yj9K
F3rR+WyQA3k91JYcgXQ0qRsFvaI0FtP3ShRaFB7sZGVWqRn4GkjndApuR4qlGnFFYw6Q0I4lspRG
pqQRCrZORu4PshUFhBF7EYUYMmeMAVkVvFCwIZBi92UqjSAhG/b7k9mMFUBjVRqj1Cz5Oowyi8Ii
a6mg9SsjYGNVG3ugMWDrZOQ6iN0RZBGYeaWe0BJUSJhj9POfs+3d7f9EkHsab2syFUxmY5LenDRW
TESXq/p/j/0EOgDW4kPj8mgkP/oevQ8W/VCRtcQqyvRUgo2xCVioKSL/32pH7hb0iFzcMdY7CrMZ
xDK1EyOq6z9wm/f8/wj190z8joZdlFX7S9TZ/yg0FdAi6ooBpfe6TId2LvwduSLNgI1ZtJNVbWqw
scfDLmCrhNsqNcaAbEVi0TvmPYgxtqKXPFSoMkSlMYB7BqCmAhlay5FxmeTqDAVatfWI2o9sZ8IW
BUZPTmafFWDLHDSoasuU4FKuYLsj2BRwO4ueWxH0UB3/SE3FCMQ8dfY0rJwUCjEPZFn78T/SdlRA
bGbfIlBjbMYShVat0tjkogpqGQ+XXQI+enXK2C4M4EbvKaIojThw756MVBxbRwJCq0BWMT6GOAas
CmNrJ3qJPmWQIwsyxfjabCwwsxyNVxYsYkFWqTMaaFmVZm9sQwZyh6ijQaGGqitWia1QaCzgInCz
oC+eCZBUJCMrVdsVduSnBD0iSoyxFZ9CiFWALTrPbVVx4qxrpFZq5zugKVTaCGbRah0s1KLjPqxy
Qp5ngdeqsB/ZAyuTilQmI5HHdk5G3hFk2aBHFmTIUiZMTUR0XhcLMOUYGvocBGoe4L05eWhNR6au
I+rISOak/ZAnbtR6zHYMEZidgU6DAUoEeBG70QT2Y1T6R+GWhdquyciVcFNAbPY5rypNpYrdj5TI
yGJkSk2hKkmpzpD7GYgp1j6LwszIIQ/ZefKTvFJly1GtUmvZDwwFiLdttUqzoL8dAdw3JyOr4BZd
1Tdq5VYHPZhSVZnVmTNpxdXqDAUZE+OPLhuDhD6eCZChy13JKob8BE7SDNSUas074ZGOUVGXTKnm
rlBpmTE2s/pk5NU1I5lj9VgIsaitaKaZfsJG7b2wBxP0YMbFstYiAxzWPvwv8F4yyswDWBRkzPCG
Yt3I4f4/gROTLT/1CjMF1Bj7UUH/DPQylqMl/ha79HwnIzWqbYViu2vQgy1PhQQ+mKVT/jOtMsta
kJkJ2SzgZsvBZECWuThWXOylgcZc6WbH1c5gZ2TgB3kGwKUAW7SDiILMjB/QVSUjEcBdlYxcodqq
IKYaH2PGyVQWY0aNqa3FrDKL7pMBWTTJiMbvn5ZPUEcrMKEwOxmgVVuPSGeD0DkCM+YDvQpud7Ai
I2NsI+XLdvTowe5dEB0kyCqSkMr5ZNVBD7N80CMTv89aixF1pIKaGmBRZZZRYqtgFj5nfpJjAkqo
rbBuroRbJvxhlg+NMJ0RsiTEHZKRinG2K+xHBcgyS7coVFg0dl81f0wJsNVW4ghufxZTZJkYftbF
Qc7b8LmsBloEamwHER03iawubcYtyFkBuOg+TMdkxq1zhNaKZDpMNdjO5LG90n6M2oxVUzC8ix00
6BGJ3atCHtWBD0Vh4gjIIvaiwlZUBMnCViL6+E/gpFZCje0MTlHHwRbyrQZbReFiRfms6vls0XRk
ZDVtdm22iGrz9qn636IAi1iKT8ODHpnY/dP4RTCjY1VXAwyZaxaF2uw7y/ZHloTZKe7fIaBVQY0d
V1NJU3QcAYUcc+KrpwOo1Fq0I2MuDGYWmGolbcbOiM5VVKu07NycbMHsytJU0aBHRZ3FVQD7LwDi
UYUTFGRogrFijKy0NiP7HBXQ0KtY5biaqkK5ygeutCEr1FqV0lQoB++zzViSijG1irG0jOqsHB9D
04rR2L3CWqwKe1Sosqdxi3ciIMuUp4rYisyQA3o+nor+/id4kldBTTGGwrxGpACxCTuJXcIk2f8D
9dYZhZwNkESAsGpMLTK/TG0vqoMeV6qxCNiyoFthKTIgG5UOq4aYIo4vgRkLtGqoRdWaqoajmeaq
JGvjmRBwCrWm+D/Yk2JFaS1mPlv0OM+qMlaNRWHGVPQ4ibGcq+aQZZVWNrGIjv/NVFkEZKq0ImJj
qyxG6bpoP4HnVECtAmZqsLFXKOyChtV2pNm1dqSig47AjbHyyk+4IlXGXBBULN+SqXqPQE1RNFhd
fioCNcRWZKGGqjK2j2DVmFqVhc6/n+BJmCncylqQKOBUA5CRL/sOduSqeWzo34iOB0XgpkpFnslz
Igq06DjkpwU97pJU9P5/BmCj8crZgqjRcnwo0KotxvDFZBRozAmssCArrngZsKntSEWisHpumwJy
jP2hhNsJ2oesvae0urPqLLpiw1VBj93nj0XB9iRgplBjHsQyIEMv1DMiogxmWaBloZYZW4t0GIrC
xFmJjhx00ZgtCiSznFpTKE6z2mQk8x1LasgFL5iQ4zUzRva0+qDH03JzyP6ze6uyJwGzqBpDIKYE
GWMvbgMzBdAqoMbYkBGYKexIRYik2o60BduqIKew26pU0uwq1oC/7z0nMo+MratYGfSoWoNs1/lj
szHDp2ktRkVZquyE6HNHkCmBxionlVpTgk0V+2cPHMWSL9UTtSvsSNaKVKjfCqWEQGr2PrzxV+UP
WvUe6YivWoNst/ljmcSiAmIVIIvYi1vATAm01WpNaT+qwBaJvmbsox3VWrYzVli6zBhbdh22EwAb
otKyF0DPJMB2nkO2S9jjGYDZXxHEVs4fq7YXZTCrAFqVWqsGm6qmmKJkzM4Tta8eb8uMM2Wi/pmp
BchS9QqVHq14f8Ucsp3DHkwlD6aqhwpiKydCR4dqLoFZFdAikLkSbFG1htpJKyZq36luZESFehcB
KivyJK5II/CMjBuy37diQU3VOmSqYsGrq3pUqLEZxBjA2cUg206VrQCaUq1542tZsEVtSLTDQsbb
Mh2ysvjoXar8MxcDjBWJKjUEaJb8DJBq6U+LWYurgx5XVfVQAE0FM7QqSzQ4dReQlcFsBdCUam1H
sDEqTqHa7lY3clU1EtaCNPJ1WaszM/fQsxTRslUziEUAVlks+CqorQ56KCzG6LjyR4NsJdDuBDZ1
9QgF3MxiIRJGvSnsSBXIGBWaUbyeOjPnu4ioNLbunhe391aCjiykuXvQY9UcMkaNnaIxMgOciB1A
tiXMVgPt7mBTru2TTfZFk287VvmvUpwKO/cEwKYI9CBrWkV+vHGyO1f1QB9XFApm545VJ3zZaSUf
D7IrgRa1A68EW1XkH4Ubq9q+pW4kWuU/q3SZiD2rnJ8Ty7ECYtmCwVesCn3VHLKIxVi5iKay4v1H
gSw7znQXsM1gxi5h8+/v0bZ395HHHs62d7ff/Z7dfgDbRz8/xPafwD7e9td9vPd/DO7Pfo++h9n3
OPsddQEU4ZGZ4mMnWCPwRB/LbMv8nIYtjPmcXFh4AR1lWTrUXmQvuj8WZLsA7e5gswGsjASaB7gZ
0I5Jxz2CFwu0aphlAMcATQU1c+A2Og6Z+W2sOkUgdhqfjIzCSwEwRQgD+Tw8pYxA7Y5lqT4GZLsB
7ZvAhkLNg1tUtamAVgmzn4BaRFXpCG4M2BCgHYHx2UjQB+mYFWpMBa9KVXaCMIuqMdUahmZ7Bj1u
C7JdgbYb2FBr6QABF4XaQXS+niJRKzcluDIgq7AgXz9TA8GWBRqbkKyyGKssRRRgf+T7Z6zFEwCa
uuSc2WcEPbYD2e5A2wFsqFKLqrUo1FhLkoHaKitylVpDwF2p0l7vs3PZsmNmu6gylaVYYS2qIfbJ
QY9tQXYXoF0FNsaCzNiQGahFLclIiGT0+M9mgFOptdnn6qk05Hhly5wxNqNioc5PDXqgY2Qe1KwA
ZDsHPbYH2d2AtjPYIuNrKNTM8kpNkYxUWZFX2pGMUnuYn0IdgW12LM1CIYqo/+7pRRZuqL0YVWMZ
ZWZ2bWmqBtkHAC37vj85QJKB26oQyU5q7TDegozajjYBGWo3Vo2ZKeD17UGPiBLbOehxO5DdHWi7
jLOpwZaFmnKMDbUij4RyWj22FlVpDNQiliMDtCp1VpVeXGktdtDjS0H2SUDbDWwIyFgbMjPWlhlj
q5rP9lMAOFaxoXPVoioN7TgUdiMSlvhLgK3KUowGPRA1pg56ZFQZo8yyIMtULPpKCDTY7pmMjI6x
rZrPtoP1yI6lrbAcI1aj2mZcEfRgrcXIGBlT6LqDHg20rwYbY0FmxtfMuBDJw/KWJDuf7Wo70ns8
E+VnymExQHvtGL15Zwqr8U+0XRH0GP0vHtRU9qIRMGPHyRpkDbRtweaBTDnOxiYjEQX3MM6SjMxn
u9KOVCUfI2lHT71H6jdGwyBXQK1ajVUFPcyuLU3V42MNtGX/aycjr01Grqj/yNR6XKHQZlaZEmZX
hDx2C3rMYDaDWoOsgfa1dqQ6QPLJyciM+lLH+DPjaAqFxs49GwEjWmaqoqIHay1eHfQYbfOApgTZ
Vwc9Gmj3ABuq1KI2ZARq2YLIKyr9V8BModJUCg2FmjoMsqKaR1XQA1kb74qgxxUgO7tD79altfIF
kXeq9K+G2pVAY9SZqsRVBz066NFAa7CVgA2xJT9h0VEWdj+JfZGFT9kxtExxYjThuGoytXpcDFVj
nx70aJA10D4CbKwduduioyjcrl50tCrlyBYqHn3nzBjaDGpVVfVHoENeW6HGrgp6VIOsgx4NtFt/
Pt+06Gg0GRmxIiPV/iuBFlkTDV3UkwGaEmYVQQ8mdt9BjwZZA+0D7chPSUYiYYuVyi2z+OcuCi2j
zrLwuzLoYVazBlkHPRpo/ZldADZUqZntk4zMjrExQIvAz6vpuApor/cVQMvAa3XQA1FnRsKMtRcZ
BdYga6A12BJgYyzIjA2ZgRob+8+ESFh4oWEUBGiRUIi3FloUaJm0I7PmWFSNfVLQo0HWQOvP8AKw
IbZkdNHRHZKRmaQkWsexspajOR10tFKIB7IMvDrowUGnQdZAa7Al7MjdkpHR0loVqg0Zm9ulODEz
Fy1SNSS67W5BDxRoSpB10KOB1p+pQLWtXnQ0m4ysKK3FQku1wOeVQGMmWWfuK4Me/z6G/O9mn7kG
WUOsgdZ2ZBBsCMgiNiQLNWXsnwUcqsgUk6qvBBozV+0kbq8OeljSXmQtxgZZA63bDcCGKrWoDRmF
mjr2jwALsTQj6mzFemgKqEVUWFVZqg56dGugNdgosDEWJGNDRpKR5gCi0pJEx+cQmD0ctZoBmgEK
JwO1iArbaekWJcg66NEdbLcPBhtiS0aTkR7w2EVHUShFH8su7qkEWhRqKNBQeO2wdAsKNCXIOujR
QOtW/Pn3oqPcytqR35kq+wfR2aFVMBDQZOC1Iq3YQY9uDbT+HpaCjQWap9xWldby4BTZB/3xYHbY
fGL1zHrzghboIqAozDro0SBroHW7rR35SaW1GFA9DE9cIjaq91ma0zmjSg2BzzP4WAc9GmQNtG4f
ATbGgszYkBmoKcbcWIg9QJAdxHfnQQ0NiDBqTR2376BHtwZat48AW8SGVIRIMgnJCMxQqxFVaKzt
6FmPjF2oVmEd9OjWQOu2DdhYO3JlMpJRcYiqYu9nYIZ+TycINQ9oKJwYgD2Nq3T/yUGPBlkDrduG
39mnLzrKAC4DsujCnqjlaEHrkV1brGJsrIMe3Rpo3W5jR95h0dEouB7GWaArgOapIgZO6nljHfTo
1kDr9nFgQ5WamT4Z6QEvo8BQVVYFNAZm1T8zmFWDrIMe3Rpo/X0uARui4CqTkSoFx679hoKbBdoI
ZivgZnbvoEeDrFsDrb/XZWDzgMYqN7aGpAJmZn4QhA2FVELNrNZS7KBHtwZat9uCDYHb6gBJRr2Z
5S3GA/xcvM5aAbXM4xEl1kGPbg20brf5nu+y6GgWcsj20fvwoIZ0sNmQiFncQswosQ56dGugdfso
1VaRjERhhqo4dJtZPgSSsRxZoLH3USXGAqyDHt0aaN2+FmyIwsmOs7EqDoHXlUBTQG70GAI1FGgN
sm4NtG5fAzYUBgo7EgUcCjNPVUaBxqqhKODM4uNjjDLLgqyDHt0aaN2+HmxRwCHPV8JMDTUGdB64
OujRrYHWrcGWgFsEbAzMlPBiYMakHCNQyygu1lZskHVroHX7umNjVYBEqd5YgDFR/YhCy4JtlQrr
8bFuDbRuX6/aFAESFjps9B6dPB2Bmtf5s0GMCNSYbQ2ybg20bn282DXjbLNtDMyiQJttP4HtLNwi
Co5VY1mQddCjWwOtW4ONUHDRqQBRmFUBTQ01Fl49Ptatgdatjx/Bc6rsSPXjEZhVQY0BVlSBNci6
NdC69XF0gWqrvp2BmRpqytveYwh0GmTdGmjdWrUVgE0Bq0g8X9Ghn8R9NbgaZN0aaN26LQYbC59D
8Lzo+2Q68zNx/xS9TuR9Nsi6NdC69TFGPodVbVUqTH3+nMFtaohVgKwh1q28/fRH0O3DwJZVWFd1
0gxYqpRX24rdGmjdui1wBbJ2ZBXcFB02q4YikIuqsQZZtwZat24XgC0LN2abskWAklVdFRBrkHW7
ZafSrdvVx+GReDwLvYpzSAW1iOJrkHVroHXrdnPVlnms4vzJwKYh1q07kP4Iun0R2BSQWnXOnMWP
N8i6dcfRrdtNjs9j4T7sc6pCFpUQa5B1a6B163YTuLF/o+rcOTfYt0HWrYHWrdsNjtnjxufHWbx/
Q6zbrdv/EWAA/cwKgjAgz0wAAAAASUVORK5CYII=" transform="matrix(1 0 0 1 -36.085 168.464)">
\t\t\t</image>
\t\t</g>
\t\t<g id="Group_9460" transform="translate(32.335 0)">
\t\t\t<g id="Group_9459" transform="translate(0 86.139)">
\t\t\t\t
\t\t\t\t\t<linearGradient id="Union_68_1_" gradientUnits="userSpaceOnUse" x1="-145.3275" y1="426.288" x2="-144.3275" y2="426.278" gradientTransform="matrix(309.4959 0 0 -207.7371 44978.3906 88664.5313)">
\t\t\t\t\t<stop  offset="0" style="stop-color:#AFAFAF"/>
\t\t\t\t\t<stop  offset="0.5" style="stop-color:#D6D6D6"/>
\t\t\t\t\t<stop  offset="1" style="stop-color:#AFAFAF"/>
\t\t\t\t</linearGradient>
\t\t\t\t<path id="Union_68" class="st11" d="M153.2,213.6c-7.6-0.1-15.3-1.9-21-5.4l-123.6-74c-4.3-2.6-7-5.8-8.1-9H0V95.3h0
\t\t\t\t\tc-0.2-4.5,2.7-9.1,8.7-12.6l124.9-71.8c5.8-3.4,13.5-5,21.1-5s15.3,1.7,21.1,5l124.9,71.8c6.3,3.6,9.2,8.5,8.7,13.2v30.3
\t\t\t\t\tc0.4,4.7-2.6,9.5-8.9,13l-126.1,69.6c-5.7,3.1-13,4.7-20.3,4.7C153.8,213.6,153.5,213.6,153.2,213.6z"/>
\t\t\t\t<path id="Path_7296" class="st2" d="M154.8,0c7.6,0,15.3,1.7,21.1,5l124.9,71.8c11.7,6.7,11.7,17.6,0,24.3L175.9,173
\t\t\t\t\tc-5.8,3.4-13.5,5-21.1,5s-15.3-1.7-21.1-5L8.8,101.2c-11.7-6.7-11.7-17.6,0-24.3L133.7,5C139.5,1.7,147.2,0,154.8,0z"/>
\t\t\t</g>
\t\t\t<path id="Union_125" class="st12" d="M154.8,241.9c-6.3-0.1-12.6-1.6-17.3-4.5L35.8,175.8c-5-3-7.3-6.9-7-10.8h0v-43.6h0
\t\t\t\tc-0.2-3.8,2.2-7.6,7.2-10.5l102.7-59.8c4.8-2.8,11.1-4.2,17.4-4.2c6.3,0,12.6,1.4,17.4,4.2l102.7,59.8c5.2,3,7.6,7.1,7.1,11v47.2
\t\t\t\tc0.3,3.9-2.1,7.9-7.3,10.8l-103.7,58c-5.2,2.7-10.9,4-16.7,3.9C155.3,241.9,155,241.9,154.8,241.9z"/>
\t\t\t<g id="Group_4120" transform="translate(0 0)">
\t\t\t\t
\t\t\t\t\t<linearGradient id="Union_68-2_1_" gradientUnits="userSpaceOnUse" x1="-145.3279" y1="512.422" x2="-144.3279" y2="512.422" gradientTransform="matrix(309.4959 0 0 -207.7371 44978.3906 106558.7969)">
\t\t\t\t\t<stop  offset="0" style="stop-color:#AFAFAF"/>
\t\t\t\t\t<stop  offset="0.421" style="stop-color:#D6D6D6"/>
\t\t\t\t\t<stop  offset="1" style="stop-color:#AFAFAF"/>
\t\t\t\t</linearGradient>
\t\t\t\t<path id="Union_68-2" class="st13" d="M153.2,213.6c-7.6-0.1-15.3-1.9-21-5.4l-123.6-74c-4.3-2.6-7-5.8-8.1-9H0V95.3h0
\t\t\t\t\tc-0.2-4.5,2.7-9.1,8.7-12.6l124.9-71.8c5.8-3.4,13.5-5,21.1-5s15.3,1.7,21.1,5l124.9,71.8c6.3,3.6,9.2,8.5,8.7,13.2v30.3
\t\t\t\t\tc0.4,4.7-2.6,9.5-8.9,13l-126.1,69.6c-5.7,3.1-13,4.7-20.3,4.7C153.8,213.6,153.5,213.6,153.2,213.6z"/>
\t\t\t\t<path id="Path_7296-2" class="st2" d="M154.8,0c7.6,0,15.3,1.7,21.1,5l124.9,71.8c11.7,6.7,11.7,17.6,0,24.3L175.9,173
\t\t\t\t\tc-5.8,3.4-13.5,5-21.1,5s-15.3-1.7-21.1-5L8.8,101.2c-11.7-6.7-11.7-17.6,0-24.3L133.7,5C139.5,1.7,147.2,0,154.8,0z"/>
\t\t\t</g>
\t\t</g>
\t\t<g id="Group_9451" transform="translate(76.762 26.302)">
\t\t\t
\t\t\t\t<linearGradient id="Path_7305_1_" gradientUnits="userSpaceOnUse" x1="-189.6474" y1="486.1155" x2="-189.6474" y2="486.8235" gradientTransform="matrix(217.5572 3.6985 2.1274 -125.1429 40334.1641 61642.4063)">
\t\t\t\t<stop  offset="0" style="stop-color:${color};stop-opacity:0.502"/>
\t\t\t\t<stop  offset="1" style="stop-color:${color}"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_7305" class="st14" d="M111,1.8c5.2,0,10.3,1.3,14.8,3.8l86.9,52c8.1,4.9,8,12.5-0.3,17.1l-88.6,49
\t\t\t\tc-4.6,2.3-9.7,3.5-14.9,3.3c-5.2,0-10.3-1.3-14.8-3.8l-86.9-52c-8.1-4.9-8-12.5,0.3-17.1l88.6-49C100.7,2.8,105.8,1.7,111,1.8z"
\t\t\t\t/>
\t\t\t
\t\t\t\t<linearGradient id="Intersection_6_1_" gradientUnits="userSpaceOnUse" x1="-190.2626" y1="486.7976" x2="-190.2626" y2="487.5056" gradientTransform="matrix(146.686 0 0 -74.3706 28018.7188 36318.6289)">
\t\t\t\t<stop  offset="0" style="stop-color:${color};stop-opacity:0.502"/>
\t\t\t\t<stop  offset="1" style="stop-color:${color}"/>
\t\t\t</linearGradient>
\t\t\t<path id="Intersection_6" class="st15" d="M108.8,127c-5.2,0-10.3-1.3-14.8-3.8L36.5,88.8L96,55.9c4.6-2.3,9.7-3.5,14.9-3.3
\t\t\t\tc5.2,0,10.3,1.3,14.8,3.8l57.5,34.4l-59.5,32.9c-4.4,2.2-9.3,3.4-14.3,3.3C109.2,127,109,127,108.8,127z"/>
\t\t</g>
\t</g>
\t
\t\t<linearGradient id="Union_391_1_" gradientUnits="userSpaceOnUse" x1="1042.2081" y1="959.2842" x2="1042.2081" y2="959.9922" gradientTransform="matrix(217.5244 0 0 -501.6844 -225362.875 481778.0625)">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#FFFFFF;stop-opacity:0"/>
\t</linearGradient>
\t<path id="Union_391" class="st16" d="M1341.7,599.8c-5.2,0-10.3-1.3-14.8-3.8l-86.9-52c-3.1-1.9-5-4.2-5.7-6.5h-0.1V537
\t\tc-0.2-1-0.2-2,0-3V161h0.1c-1.1-3.8,0.9-7.8,6-10.6l88.6-49c4.6-2.3,9.7-3.5,14.9-3.3c5.2,0,10.3,1.3,14.8,3.8l86.9,52
\t\tc4.9,2.9,6.8,6.9,5.7,10.5v372.5c1.1,3.8-0.9,7.8-6,10.6l-88.6,48.9c-4.4,2.2-9.3,3.4-14.3,3.3
\t\tC1342.1,599.8,1341.9,599.8,1341.7,599.8z"/>
\t<g id="Group_9524" transform="translate(-1862 -14036)">
\t\t<g id="Group_135-2" transform="translate(3145.81 14329.016)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1479-2_1_" gradientUnits="userSpaceOnUse" x1="-243.9681" y1="675.3552" x2="-243.9611" y2="675.0222" gradientTransform="matrix(76.862 0 0 -13.29 18790.1426 8980.7461)">
\t\t\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t\t\t<stop  offset="1" style="stop-color:#D6D6D6"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1479-2" class="st17" d="M0,13.3l9.9-5.7l67-7.6l-9.1,5.5L0,13.3z"/>
\t\t</g>
\t\t<g id="Group_136-2" transform="translate(3281.772 14425.896)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1480-2_1_" gradientUnits="userSpaceOnUse" x1="-408.6639" y1="571.0118" x2="-408.6639" y2="570.0218" gradientTransform="matrix(8.675 0 0 -110.9789 3549.4922 63375.2734)">
\t\t\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t\t\t<stop  offset="1" style="stop-color:#D6D6D6"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1480-2" class="st18" d="M0,5.8L8.7,0v106.2L0.5,111L0,5.8z"/>
\t\t</g>
\t\t<g id="Group_137-2" transform="translate(3213.486 14329)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1481-2_1_" gradientUnits="userSpaceOnUse" x1="-312.1375" y1="667.528" x2="-311.1375" y2="667.528" gradientTransform="matrix(76.956 0 0 -103.691 24020.8535 69268.5156)">
\t\t\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t\t\t<stop  offset="1" style="stop-color:#D6D6D6"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1481-2" class="st19" d="M0,5.4L9.1,0L77,96.9l-10.2,6.8L0,5.4z"/>
\t\t</g>
\t\t<g id="Group_138-2" transform="translate(3145.81 14334.466)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1482-3_1_" gradientUnits="userSpaceOnUse" x1="-242.37" y1="667.3725" x2="-242.37" y2="667.3775" gradientTransform="matrix(136.414 0 0 -210.2139 33130.8633 139158.5781)">
\t\t\t\t<stop  offset="0" style="stop-color:#5FCCE6"/>
\t\t\t\t<stop  offset="1" style="stop-color:#36A6CD"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1482-3" class="st20" d="M67.8,0L136,97.3l0.5,105.1l-67.8,7.8L0.4,113L0,7.8L67.8,0z"/>
\t\t</g>
\t\t<path id="Path_24326" class="st12" d="M3214.4,14333.8L3214.4,14333.8L3214.4,14333.8L3214.4,14333.8L3214.4,14333.8l-67,7.6
\t\t\tl-9.9,5.7l67.8-7.8l68.2,97.2l0.5,105.2v0l8.2-4.7v-106.2L3214.4,14333.8z"/>
\t\t<g id="Group_135-2-2" transform="translate(3130 14338.126)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1479-2-2_1_" gradientUnits="userSpaceOnUse" x1="-228.1604" y1="666.3351" x2="-228.1525" y2="665.9971" gradientTransform="matrix(76.862 0 0 -13.29 17574.9492 8859.71)">
\t\t\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t\t\t<stop  offset="1" style="stop-color:#D6D6D6"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1479-2-2" class="st21" d="M0,13.3l9.9-5.7l67-7.6l-9.1,5.5L0,13.3z"/>
\t\t</g>
\t\t<g id="Group_136-2-2" transform="translate(3265.962 14435.005)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1480-2-2_1_" gradientUnits="userSpaceOnUse" x1="-392.8539" y1="561.9104" x2="-392.8539" y2="560.9224" gradientTransform="matrix(8.675 0 0 -110.977 3412.3401 62363.3398)">
\t\t\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t\t\t<stop  offset="1" style="stop-color:#D6D6D6"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1480-2-2" class="st22" d="M0,5.8L8.7,0v106.2L0.5,111L0,5.8z"/>
\t\t</g>
\t\t<g id="Group_137-2-2" transform="translate(3197.676 14338.11)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1481-2-2_1_" gradientUnits="userSpaceOnUse" x1="-296.2001" y1="658.8577" x2="-295.4451" y2="657.9807" gradientTransform="matrix(76.961 0 0 -103.685 22805.6387 68319.8828)">
\t\t\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t\t\t<stop  offset="1" style="stop-color:#D6D6D6"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1481-2-2" class="st23" d="M0,5.4L9.1,0L77,96.9l-10.2,6.8L0,5.4z"/>
\t\t</g>
\t\t<g id="Group_138-2-2" transform="translate(3130 14343.576)">
\t\t\t
\t\t\t\t<linearGradient id="Path_1482-3-2_1_" gradientUnits="userSpaceOnUse" x1="-227.1816" y1="653.1999" x2="-226.1716" y2="651.8599" gradientTransform="matrix(136.413 0 0 -210.214 30973.9316 137243.4844)">
\t\t\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t\t\t<stop  offset="1" style="stop-color:#AFAFAF"/>
\t\t\t</linearGradient>
\t\t\t<path id="Path_1482-3-2" class="st24" d="M67.8,0L136,97.3l0.5,105.1l-67.8,7.8L0.4,112.9L0,7.8L67.8,0z"/>
\t\t</g>
\t\t<g id="Group_142-2" transform="translate(3142.541 14360.228)">
\t\t\t<path id="Path_1482-4" class="st2" d="M55.3,0L111,81.9l0.4,88.5L56,176.9L0.4,95.1L0,6.6L55.3,0z"/>
\t\t</g>
\t\t<g id="Group_160-2" transform="translate(3160.675 14400.105)">
\t\t\t<path id="Path_1496-2" class="st12" d="M14.5,23.5L0.1,15.2L0,64.6l14.3,8.3L14.5,23.5"/>
\t\t\t<path id="Path_1497-2" class="st12" d="M45.7,1.1c-4.3-2.5-7.8-0.5-7.8,4.5l0,6.2c0,14-8.5,20.8-19.8,17.6L18,70l11.7,10.6
\t\t\t\tc1.7,1.6,3.7,3,5.7,4.2l16.9,9.8c10.7,6.2,19.6,2,20.7-9.6l1.1-19.7c0.3-7.3-1.5-20-14.6-27.5c-2-1.1-4-2.1-6.1-2.8l0.1-20.5
\t\t\t\tC53.2,9.1,50.3,4.1,45.7,1.1"/>
\t\t</g>
\t</g>
</g>
</svg>`

const Image = styled.div`
  width: 100%;
`

export function ThumbUpAnimationTemplate(props) {

    const {login, animation} = useTheme()


    return <>
        <Style/>
        <Col>
            <FlexRow justifyContent={"center"} alignItems={"center"} full>
                <Image
                    dangerouslySetInnerHTML={SvgTemplate(login.illustrationContrastColor, animation?.background || "#FFFFFF")}>

                </Image>
            </FlexRow>
        </Col>
    </>
}
