import React from 'react'
import {FlexColumn} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"
import {ApplicationUrlProvider} from "../../../Layout/ApplicationUrlProvider"
import {FooterContainer} from "./StyledComponents"
import greenbonePowered from "../../../../assets/greenbone-power.svg?url"
import {Link} from "react-router-dom"

export const Footer = () => {
    const {t} = useTranslation()
    return <FlexColumn
        justifyContent={"space-evenly"}
        alignItems="center"
        fullWidth
    >
        <FooterContainer>
            <Link style={{width: "110px"}} to={ApplicationUrlProvider.imprint()}>{t("footer.imprint")}</Link>
            <Link to={ApplicationUrlProvider.privacyPolicy()}>
                {t("footer.pp")}
            </Link>
            <Link to={ApplicationUrlProvider.termsOfUse()}>
                {t("footer.termsOfService")}
            </Link>
        </FooterContainer>
        <img
            src={greenbonePowered}
            alt="powered by greenbone"
            width={"100px"}
            style={{margin: "20px 0"}}
        />
    </FlexColumn>
}
