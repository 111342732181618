import React from "react"
import {Button, FlexColumn, FlexRow, GhostButton} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {ThumbUpAnimationTemplate} from "../../Components/Illustrations/ThumbsUpAnimationTemplate"
import {SuccessIcon} from "../../Components/SuccessIcon"
import {withTranslation} from "react-i18next"
import {FormPaper} from "../../Components/FormPaper"


export class _PasswordResetSuccess extends React.Component {

    render() {
        const {t} = this.props

        return (
            <>
                <Row className={"justify-content-xl-center"} style={{minHeight: "calc(100vh - 3rem)"}}>
                    <Col xl={6} xs={12} md={8} lg={8}>
                        <FlexColumn full justifyContent={"center"} alignItems={"flex-end"}>
                            <FormPaper
                                title={t("passwordResetSuccess.headline")}
                                text={t("passwordResetSuccess.text")}
                                actions={<Row>
                                    <Col>
                                        <a href="/ui">
                                            <GhostButton noMargin>
                                                {t("back.to.login")}
                                            </GhostButton>
                                        </a>
                                    </Col>
                                    <Col>
                                        <FlexRow justifyContent={"flex-end"}>
                                            <a href="/ui">
                                                <Button>{t("to.login")}</Button>
                                            </a>
                                        </FlexRow>
                                    </Col>
                                </Row>}
                            >
                                <SuccessIcon/>
                            </FormPaper>
                        </FlexColumn>
                    </Col>
                    <ThumbUpAnimationTemplate/>
                </Row>
            </>
        )
    }
}

export const PasswordResetSuccess = withTranslation()(_PasswordResetSuccess)


