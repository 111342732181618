import {rest} from "msw"
import Chance from "chance"
import {buildUrl} from "../buildUrl"

const chance = new Chance(Math.random)

export const vmsspAdminServiceHandlers = [
    rest.get(buildUrl("/usermanagement/group/join/:token/details"), (request, response, context) => {

        return response(
            context.status(200),
            context.json({
                "email": "main@mail.com",
                "companyName": "CompanyCompany"
            })
        )
    }),

    rest.post(buildUrl("/usermanagement/group/join/:token "), (req, res, context) => {

        return res(
            context.delay(3000),
            context.status(200),
            // context.status(404),
            context.json({
                "groupId": chance.guid()
            })
        )
    })
]
