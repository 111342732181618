import React from "react"
import {Paragraph, Subheadline} from "@greenbone/cloud-component-library"
import {StyledPaper} from "./Layout"
import {Logo} from "./Logo"

export function FormPaper({title, text, children, actions, ...props}) {
    return <StyledPaper {...props}>
        <Logo/>

        <Subheadline style={{marginBottom: "2rem"}}>
            {title}
        </Subheadline>

        <Paragraph style={{marginBottom: "3rem"}}>
            {text}
        </Paragraph>

        <div style={{marginBottom: "5rem"}}>
            {children}
        </div>

        {actions}
    </StyledPaper>

}
