import "regenerator-runtime/runtime"
import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/browser"
import App from "./app/App"
import * as serviceWorker from "./serviceWorker"
import "./i18n"
import {IS_API_MOCKED, IS_DEVELOP, IS_LOCAL, SENTRY_KEY, VERSION} from "./app/Constants" // import i18n (needs to be bundled)
import "@greenbone/cloud-component-library/build/Library.css"
import {createGlobalStyle} from "styled-components"
import "./app/App.css"


const Global = createGlobalStyle`
  button:focus {
    outline: none;
  }

`

function main() {

    if (IS_API_MOCKED) {
        if (window.location.pathname === "/ui/extern") {
            window.location.pathname = "/ui/extern/"
            return
        }

        const {worker} = require("./mocks/browser")
        worker.start({
            onUnhandledRequest: "warn",
            serviceWorker: {
                url: "/ui/extern/mockServiceWorker.js"
            }
        })
    }

    Sentry.init({
        dsn: SENTRY_KEY,
        attachStacktrace: true,
        enabled: !IS_LOCAL,
        release: VERSION,
        environment: IS_DEVELOP ? "DEV" : "PROD"
    })

    ReactDOM.render(<> <Global/> <App/></>, document.getElementById("root"))

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    if (!IS_API_MOCKED) {
        serviceWorker.unregister()
    }

}

main()
