import { FlexColumn, FlexRow, SearchableSelect } from '@greenbone/cloud-component-library'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {Input as InputMui} from "@greenbone/cloud-component-library"

const Input = ({style = {}, children, ...props}) => <div style={style}><InputMui {...props}>{children}</InputMui></div>

const CompanyInfos = (props, ref) => {
    const { t } = useTranslation()
    const [initiallyShowErrors, setInitiallyShowErrors] = useState(props.initiallyShowErrors)

    const {
        companyName,
        title,
        firstName,
        lastName,
        streetAddress,
        houseNumber,
        postalCode,
        city,
        countryId,
    } = props.billingAddress

    useImperativeHandle(ref, () => ({
        validate: () => {
            setInitiallyShowErrors(true)
            return props.validateBillingAddress()
        },
    }))

    const handleSelect = (e) => {
        if (e.target.value !== 'DEFAULT') {
            props.onChange(e)
        }
    }
    
    return (
        <FlexColumn fullWidth={'100%'}>
            <h3>{t("extern.registration.address")}</h3>
            <Input
                value={companyName}
                isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.companyName}
                name={'companyName'}
                onChange={props.onChange}
                label={t('extern.registration.address.companyName')}
            />
            <Input
                isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.title}
                value={title}
                style={{width: "20%"}}
                name={'title'}
                onChange={props.onChange}
                label={t('extern.registration.address.title')}
                />
            <FlexRow justifyContent={'space-between'}>
                <Input
                    style={{width: "45%"}}
                    isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.firstName}
                    value={firstName}
                    name={'firstName'}
                    onChange={props.onChange}
                    label={t('extern.registration.address.firstName')}
                />
                <Input
                    style={{width: "45%"}}
                    value={lastName}
                    name={'lastName'}
                    isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.lastName}
                    onChange={props.onChange}
                    label={t('extern.registration.address.lastName')}
                    />
            </FlexRow>
            <FlexRow justifyContent={'space-between'} style={{ width: '80%' }}>
                <Input
                    style={{width: "56%"}}
                    value={streetAddress}
                    name={'streetAddress'}
                    isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.streetAddress}
                    onChange={props.onChange}
                    label={t('extern.registration.address.streetAddress')}
                    />
                <Input
                    style={{width: "31%"}}
                    value={houseNumber}
                    name={'houseNumber'}
                    isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.houseNumber}
                    onChange={props.onChange}
                    label={t('extern.registration.address.houseNumber')}
                    />
            </FlexRow>
            <FlexRow justifyContent={'space-between'} style={{ width: '45%' }}>
                <Input
                    style={{width: "45%"}}
                    value={postalCode}
                    name={'postalCode'}
                    isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.postalCode}
                    onChange={props.onChange}
                    label={t('extern.registration.address.postalCode')}
                    />
                <Input
                    style={{width: "45%"}}
                    value={city}
                    name={'city'}
                    isValid={!initiallyShowErrors ? undefined : props.billingAddressValid.city}
                    onChange={props.onChange}
                    label={t('extern.registration.address.city')}
                />
            </FlexRow>
            <SearchableSelect label={t('extern.registration.address.country')} options={{
                        data: props.countries,
                        labelFieldName: "name",
                        valueFieldName: "id"
                    }} onChange={handleSelect} value={countryId} isValid={props.billingAddressValid.countryId}
                    name={"countryId"}/>
        </FlexColumn>
    )
}

export default forwardRef(CompanyInfos)
