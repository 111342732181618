import React from "react"
import {Route, Routes} from "react-router-dom"
import {ErrorBoundary} from "../../Components/ErrorBoundary"
import {MSPUrlProvider} from "./MSPUrlProvider"
import {CreateMSP} from "./CreateMSP"
import {CreateMSPSuccess} from "./CreateMSPSuccess"

export function MSPLayout(props) {

    return <>
        <ErrorBoundary>
            <Routes>
                <Route path="/success" Component={CreateMSPSuccess}/>
                <Route path="/join" Component={CreateMSP}/>
                <Route path="*" element={<div>Not Found</div>} />
            </Routes>
        </ErrorBoundary>
    </>
}
