import React from "react"
import {useTranslation} from "react-i18next"
import {createGlobalStyle} from "styled-components"
import {Col, Row} from "reactstrap"
import {BigButton, SuperHeadline, SuperParagraph} from "./Components"
import {RegisterDisabledIllustrationTemplate} from "./assets/SvgRegisterDisabledTemplate"


const Global = createGlobalStyle`

`


export function RegisterDisabled(props) {
    const {t} = useTranslation()

    return (
        <>
            <Global/>
            <Row>
                <Col lg={8}>
                    <SuperHeadline>
                        {t("registerDisabled.headline")}
                    </SuperHeadline>
                    <SuperParagraph>
                        {t("registerDisabled.text")}
                    </SuperParagraph>
                    <a href="/ui">
                        <BigButton>
                            {t("back.to.login")}
                        </BigButton>
                    </a>
                </Col>
            </Row>
            <RegisterDisabledIllustrationTemplate/>
        </>
    )
}


