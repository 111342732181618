import React, {useEffect, useState} from "react"
import {LegalRestApiClient} from "../LegalRestApiClient"
import * as Sentry from "@sentry/browser"

export const LegalContext = React.createContext({})

//privacyPolicyEnabled,termsOfUseEnabled
export const LegalProvider = ({children}) => {

    const [isLoading, setLoading] = useState(true)
    const [legalSettings, setLegalSettings] = useState({
        privacyPolicyEnabled: false,
        termsOfUseEnabled: false
    })

    useEffect(() => {
        function f() {
            const client = new LegalRestApiClient()
            client.settings()
                .then(response => {
                    if (response) {
                        setLegalSettings(response)
                    }
                })
                .catch(e => {
                    //Swallow error silently
                    Sentry.captureException(e)
                    console.error(e)
                })
                .finally(() => setLoading(false))
        }

        f()
    }, [])

    return <LegalContext.Provider value={{
        ...legalSettings,
        isLegalLoading: isLoading
    }}>
        {children}
    </LegalContext.Provider>
}
