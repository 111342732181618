import React, {Suspense} from "react"
import {FetchClient, PublicStyleRestApiClient, StyleProvider} from "@greenbone/cloud-component-library"
import {ApplicationRouter} from "./Layout/ApplicationRouter"
import {BrowserRouter} from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import {BASEURL} from "./Constants"
import {LegalProvider} from "./Context/LegalContext"


export class App extends React.Component {
    state = {
        _loading: true,
        theme: null
    }

    componentDidMount() {
        PublicStyleRestApiClient.getTheme(new FetchClient(null, BASEURL, false).getUnauthenticatedInstance())
            .then(theme => {
                this.setState({_loading: false, theme})
            })


    }

    render() {

        if (this.state._loading) {
            return <div>...loading</div>
        }
        return (
            <Suspense fallback={<div>...loading language files</div>}>
                <StyleProvider theme={this.state.theme}>
                    <LegalProvider>
                        <BrowserRouter basename={"/ui/extern"}>
                            <ApplicationRouter/>
                        </BrowserRouter>
                    </LegalProvider>
                </StyleProvider>
            </Suspense>

        )
    }


}

export default App
